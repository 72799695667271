import { motion } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { ImCancelCircle } from "react-icons/im";

import CongratsImage from "../../../images/web/congrats.svg";
import Colors from "../../../styles/Colors";
import Overlay from "../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../checkEligibilty/components/modals/variant";
import { mediaObj } from "../../../styles/Media";

interface Props {
  onClose: () => void;
}

const AccountVerifyModal: FC<Props> = ({ onClose }) => {
  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel onClick={onClose}>
          <ImCancelCircle />
        </Cancel>
        <MainIcon>
          <img src={CongratsImage} alt="" />
        </MainIcon>
        <ContentWrapper>
          <TextBold>Your account is not yet verified!</TextBold>
          <Text>
            If you just signed up, we've sent a verification mail to your email
            address.
          </Text>
          <Text>
            If you did not se the mail in your primary folder, please check your
            <TextBold>spam</TextBold> folder.
          </Text>
          <Text>
            If this mail is delayed after 30 minutes, please contact support.
          </Text>
        </ContentWrapper>
      </Wrapper>
    </Overlay>,
    document.body,
  );
};

export default AccountVerifyModal;

const Wrapper = styled(motion.div)`
  width: 802px;
  max-height: 80vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 76px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 70vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;

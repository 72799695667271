import React, { FC } from "react";
import InputField from "../../../components/formik fields/InputField";
import SelectField from "../../../components/formik fields/SelectField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import useStatesByCountry from "../../../components/useStatesByCountry";
import useCountries from "../../../components/useCountries";
import { FormikProps } from "formik";
import { WETPreviousFormData } from "./Previous";
import { saveDS160WETPreviousObj } from "../../../../../api/ds160/types";
import { NGDatePlaceholderFormat } from "../../../components/data";
// import { PrimaryOccupationData } from "../data";

interface Props {
  data?: saveDS160WETPreviousObj;
  index: number;
  formik: FormikProps<WETPreviousFormData>;
}

const PrevEducation: FC<Props> = ({ data, index, formik }) => {
  const savedCountry = data?.country.split(",")[index] || "";
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(savedCountry);

  return (
    <>
      <InputField
        label="Name of Institution"
        placeholder=""
        name={`EduArray[${index}].nameOfInstitute`}
      />
      <InputField
        label="Street Address Line 1"
        placeholder=""
        name={`EduArray[${index}].instituteStreetAddressLine1`}
      />
      <InputField
        label="Street Address Line 2"
        coloredLabel="(optional)"
        placeholder=""
        name={`EduArray[${index}].instituteStreetAddressLine2`}
      />
      <InputField
        label="City"
        placeholder=""
        name={`EduArray[${index}].instituteCity`}
      />
      <SelectField
        label="Country"
        placeholder="-Select-"
        name={`EduArray[${index}].country`}
        options={formattedCountryData}
        isLoading={countryLoading}
        onChange={(value) => {
          setCountry(value.label);
          formik.setFieldValue(
            `EduArray[${index}].instituteStateOrProvince`,
            null
          );
        }}
      />
      <SelectField
        label="State or Province"
        placeholder=""
        name={`EduArray[${index}].instituteStateOrProvince`}
        options={formattedStateData}
        isLoading={stateLoading}
        isDisabled={!selectedCountry}
      />
      <InputField
        label="Postal Code/ Zip Code"
        placeholder=""
        name={`EduArray[${index}].instituteZipCode`}
      />
      <InputField
        label="Course of Study"
        placeholder=""
        name={`EduArray[${index}].courseOfStudy`}
        // options={PrimaryOccupationData}
      />
      <DatePickerField
        label="Date of Attendance from"
        placeholder={NGDatePlaceholderFormat}
        disableFuture={true}
        name={`EduArray[${index}].dateOfAttendanceFrom`}
      />
      <DatePickerField
        label="Date of Attendance to"
        placeholder={NGDatePlaceholderFormat}
        name={`EduArray[${index}].dateOfAttendanceTo`}
      />
    </>
  );
};

export default PrevEducation;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Constaint from "./Constaint";
import { ReactComponent as Logo } from "../../../../images/icons/logo.svg";
import { ReactComponent as Close } from "../../../../images/icons/close.svg";
import { ReactComponent as Open } from "../../../../images/icons/menu.svg";
import { NavItems } from "./data";
import NavItem from "./NavItem";
import { useLocation, useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import Colors from "../../../../styles/Colors";
import AccountBox from "../../profile/components/AccountBox";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import { useGetUserData } from "../../../../hooks/utils/useUtils";
import { userDataStore } from "../../../../store/userData";

interface SCProps {
  menu: boolean;
}

const Header = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const { pathname } = useLocation();
  // const location = useLocation();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    //
    handleResize();
    //
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setMenu(false);
  }, [pathname]);

  const onCloseMenu = () => {
    setMenu(!menu);
  };
  const { data } = useGetUserData();
  userDataStore.userData = isLoggedIn ? data : undefined;

  return (
    <Wrapper menu={menu}>
      <Constaint>
        <Container>
          <LogoWrapper menu={menu}>
            <Logo onClick={() => navigate(WebPaths.Root)} />
          </LogoWrapper>
          <MenuWrapper onClick={onCloseMenu}>
            {!menu ? <Open /> : <Close />}
          </MenuWrapper>
          {isMobile ? (
            <MobileNavWrapper menu={menu}>
              {!isLoggedIn && (
                <ItemWrapper menu={menu}>
                  {NavItems.map((ev, i) => (
                    <NavItem
                      key={i}
                      label={ev.label}
                      url={ev.url}
                      children={ev.children}
                      onCloseMenu={onCloseMenu}
                    />
                  ))}
                </ItemWrapper>
              )}
              {!isLoggedIn ? (
                <BtnWrapper menu={menu}>
                  <LoginBtn onClick={() => navigate(WebPaths.Auth)}>
                    Login
                  </LoginBtn>
                  <SignBtn onClick={() => navigate(WebPaths.Join)}>
                    Sign Up
                  </SignBtn>
                </BtnWrapper>
              ) : pathname === WebPaths.Profile ? (
                <AccountBox />
              ) : (
                <Link onClick={() => navigate(WebPaths.Profile)}>
                  my profile
                </Link>
              )}
            </MobileNavWrapper>
          ) : (
            <>
              <ItemWrapper menu={menu}>
                {NavItems.map((ev, i) => (
                  <NavItem
                    key={i}
                    label={ev.label}
                    url={ev.url}
                    children={ev.children}
                    onCloseMenu={onCloseMenu}
                  />
                ))}
              </ItemWrapper>

              {!isLoggedIn ? (
                <BtnWrapper menu={menu}>
                  <LoginBtn onClick={() => navigate(WebPaths.Auth)}>
                    Login
                  </LoginBtn>
                  <SignBtn onClick={() => navigate(WebPaths.Join)}>
                    Sign Up
                  </SignBtn>
                </BtnWrapper>
              ) : pathname === WebPaths.Profile ? (
                <AccountBox />
              ) : (
                <Link onClick={() => navigate(WebPaths.Profile)}>
                  my profile
                </Link>
              )}
            </>
          )}
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div<SCProps>`
  transition: 0.3s all ease;
  position: relative;

  z-index: 100;

  @media only screen and (min-width: 768px) {
    box-shadow: 0px 2px 8px 1px rgba(49, 57, 60, 0.06);
    height: 98px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  gap: 2rem;

  height: 100%;

  @media only screen and (min-width: 768px) {
    padding: 27px 0;
  }
`;

// const DesktopNavWrapper = styled.div`
//   display: none;

//   @media only screen and (min-width: 768px) {
//     flex: 0.7;

//     display: flex;
//     /* justify-content: flex-end; */
//     justify-content: space-between;
//     transform: translateX(0);
//   }

//   flex: 1;

//   transition: 0.3s all ease;
// `;

const MobileNavWrapper = styled.div<SCProps>`
  width: 100vw;
  margin: auto;
  height: 90vh;

  position: absolute;
  top: 100%;
  left: 0;

  background-color: #fff;

  transform: ${({ menu }) => (menu ? "translateX(0)" : "translateX(-110%)")};

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 2rem;

  transition: 0.3s all ease;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const ItemWrapper = styled.div<SCProps>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  & > div {
    margin: 1rem auto;
  }

  transition: 0.3s all ease;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 55px;
  }
`;

const BtnWrapper = styled.div<SCProps>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: end;
  align-items: start;
  gap: 1rem;

  transition: 0.3s all ease;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }
`;

const LoginBtn = styled.button`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0em;
  color: #31393c;
  max-width: 200px;

  &:hover {
    color: #0076e9;
    transition: 0.3s ease-in-out;
  }
`;

const SignBtn = styled.button`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #0076e9;
  border: 1px solid #0076e9;
  border-radius: 8px;
  padding: 12px 35.5px;
  max-width: 200px;

  &:hover {
    color: #f9fcff;
    background-color: #0076e9;
    transition: 0.3s ease-in-out;
  }
`;

const LogoWrapper = styled.div<SCProps>`
  svg {
    cursor: pointer;
  }

  padding: 1rem 0;

  @media only screen and (min-width: 768px) {
    flex: 0.3;
  }
`;

const MenuWrapper = styled.button`
  height: 24px;
  width: 24px;

  /* position: absolute; */
  top: 28px;
  right: 1rem;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const Link = styled.button`
  line-height: 1.375rem;

  text-transform: capitalize;
  font-weight: medium;
  border: 1px solid ${Colors.Blue00};
  border-radius: 8px;

  padding: 12px 35.5px;
  transition: ease-in-out 0.3s;

  &:hover {
    background-color: ${Colors.Blue00};
    color: white;
  }
`;

import styled from "styled-components";
import Hero1 from "../../../../images/web/hero1.png";
import Hero2 from "../../../../images/web/hero7.png";
import Hero3 from "../../../../images/web/hero5.png";
import Hero4 from "../../../../images/web/hero6.png";
import { motion } from "framer-motion";
import Constaint from "../../layout/components/Constaint";
import {
  HeroImagesVariant1,
  HeroImagesVariant2,
  LeftHeroVariant,
  RightHeroVariant,
} from "./variants";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Section>
            <LeftSection
              variants={LeftHeroVariant}
              animate={"animate"}
              initial="initial"
            >
              <H1>The Trusted Partner in Your Immigration Journey</H1>
              <Para>
                Simplify your immigration process with our attorney-reviewed
                software.
              </Para>
              <CTA onClick={() => navigate(WebPaths.Services)}>Get Started</CTA>
            </LeftSection>
            <RightSection
              variants={RightHeroVariant}
              animate={"animate"}
              initial="initial"
            >
              <Flexer>
                <MiniFlex>
                  <ImageBox
                    src={Hero1}
                    height={"380px"}
                    variants={HeroImagesVariant1}
                    animate={"animate"}
                  />
                  <ImageBox
                    src={Hero4}
                    height={"170px"}
                    variants={HeroImagesVariant2}
                    animate={"animate"}
                  />
                </MiniFlex>
                <MiniFlex>
                  <ImageBox
                    src={Hero3}
                    height={"170px"}
                    variants={HeroImagesVariant2}
                    animate={"animate"}
                  />
                  <ImageBox
                    src={Hero2}
                    height={"380px"}
                    variants={HeroImagesVariant1}
                    animate={"animate"}
                  />
                </MiniFlex>
              </Flexer>
            </RightSection>
          </Section>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.div`
  background-color: #c6d9ec73;
`;

const Container = styled.div`
  padding-top: 32px;
  padding-bottom: 98px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 89px;
  overflow-x: hidden;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
const LeftSection = styled(motion.div)`
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 45%;
    text-align: left;
  }
`;

const H1 = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.8125rem;
  letter-spacing: 0em;
  background: linear-gradient(
    82.05deg,
    #0076e9 4.03%,
    #477caf 32.89%,
    #a882db 92.08%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media only screen and (min-width: 768px) {
    font-size: 4rem;
    line-height: 4.8125rem;
  }
`;

const Para = styled.p`
  margin: 40px auto;

  @media only screen and (min-width: 768px) {
    margin: 40px 0;
  }

  width: 90%;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4375rem;
  letter-spacing: 0em;
`;

const CTA = styled.button`
  width: 222px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 59px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  background: #0076e9;
  border-radius: 8px;
  color: #f9fcff;

  &:hover {
    background: #022e58;
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }

  margin: auto;

  @media only screen and (min-width: 768px) {
    margin: 40px 0;
  }
`;

const RightSection = styled(motion.div)`
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 51%;
  }
`;

interface ImageBoxProps {
  height: string;
}

const ImageBox = styled(motion.img)<ImageBoxProps>`
  height: ${({ height }) => height};
  border-radius: 16px;
  object-fit: cover;
`;

const Flexer = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
`;

const MiniFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
`;

import axios from "axios";
import { useState } from "react";
import { ttsAudioConfig, ttsVoiceConfig } from "../utils";

interface UseGoogleTTSHookResult {
  isLoading: boolean;
  error: Error | null;
  audioUrl: string | null;
  synthesize: (text: string) => Promise<void>;
}

const ttsUrl: string = `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyBCUG-g0HxNEXyh5979GHOZQxTs88T_-dI`;

const useGoogleTTS = (): UseGoogleTTSHookResult => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const synthesize = async (text: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(ttsUrl, {
        audioConfig: ttsAudioConfig,
        input: {
          text,
        },
        voice: ttsVoiceConfig,
      });

      const url = `data:audio/mp3;base64,${response.data.audioContent}`;
      setAudioUrl(url);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, audioUrl, synthesize };
};

export default useGoogleTTS;

export enum ds160QueryKeys {
  getDS160GetStarted = "getDS160GetStarted",
  getDS160PersonalInfo = "getDS160PersonalInfo",
  getDS160PersonalInfo2 = "getDS160PersonalInfo2",
  getDS160Travel = "getDS160Travel",
  getDS160TravelCompanions = "getDS160TravelCompanions",
  getDS160PreviousUSTravel = "getDS160PreviousUSTravel",
  getDS160AddressAndPhone = "getDS160AddressAndPhone",
  getDS160Passport = "getDS160Passport",
  getDS160USContact = "getDS160USContact",
  getDS160Family = "getDS160Family",
  getDS160WETPresent = "getDS160WETPresent",
  getDS160WETPrevious = "getDS160WETPrevious",
  getDS160WETAdditional = "getDS160WETAdditional",
  getDS160SEVAdditionalContact = "getDS160SEVAdditionalContact",
  getDS160Sives = "getDS160Sives",
  getSecurityBackgroundP1 = "getSecurityBackgroundP1",
  getSecurityBackgroundP2 = "getSecurityBackgroundP2",
  getSecurityBackgroundP3 = "getSecurityBackgroundP3",
  getSecurityBackgroundP4 = "getSecurityBackgroundP4",
  getSecurityBackgroundP5 = "getSecurityBackgroundP5",
  getDs160Info = "get-ds160-info",
  getDS160Eligibility = "get-ds160-eligibility ",
  getDS160Form = "get-ds-160-application",
  getDS160PaySuccess = "get-ds-160-pay-success",
  getDS160PayFail = "get-ds-160-pay-fail",
  getDS160Files = "get-ds-160-files",
  getDS160Trigger = "get-ds-160-trigger",
  getDS160MaritalStatus = "get-DS160-Marital-Status",
}

import { useMutation, useQuery } from "@tanstack/react-query";
import {
  changePassword,
  forgotPassword,
  getChangePasswordCode,
  signin,
  signup,
  updateProfile,
} from "../../api/auth";
import { parseError } from "../../utils";
import { authQueryKeys } from "./types";

export const useChangePassword = () => {
  return useMutation(changePassword, {
    onError: (error) => parseError(error),
  });
};

export const useUpdateProfile = () => {
  return useMutation(updateProfile, {
    onError: (error) => parseError(error),
  });
};

export const useSignup = () => {
  return useMutation(signup, {
    onError: (error) => parseError(error),
  });
};

export const useSignin = () => {
  return useMutation(signin, {
    onError: (error) => parseError(error),
  });
};

export const useForgotPassword = () => {
  return useMutation(forgotPassword, {
    onError: (error) => parseError(error),
  });
};

export const useGetChangePasswordCode = () => {
  return useQuery(
    [authQueryKeys.getChangePasswordCode],
    getChangePasswordCode,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

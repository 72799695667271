import React, { FC } from "react";
import { saveDS160TravelObj } from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160TravelObj;
}

const ReviewTravel: FC<Props> = ({ title, data, isDownload }) => {
  const section: ReviewData[] = [
    {
      label: "Purpose of Trip to the U.S",
      value: data?.purposeOfTrip,
    },
    {
      label: "Specific",
      value: data?.specific,
    },
    {
      label: "Have you made specific travel plans?",
      value: data?.haveYouMadeSpecificTravelPlans,
    },
    {
      label: "Date of Arrival in U.S",
      value: data?.dateOfArrival,
    },
    {
      label: "Arrival Flight (If known)",
      value: data?.arrivalFlight,
    },
    {
      label: "Arrival City",
      value: data?.arrivalCity,
    },
    {
      label: "Date of Departure from U.S",
      value: data?.dateOfDeparture,
    },
    {
      label: "Departure Flight (If known)",
      value: data?.departureFlight,
    },
    {
      label: "Departure City",
      value: data?.departureCity,
    },
    {
      label: "Location",
      value: data?.location,
    },
    {
      label: "Intended Date of Arrival",
      value: data?.intendedDateOfArrival,
    },
    {
      label: "Intended Length of Stay in U.S",
      value: `${data?.intendedLengthOfStayDuration} ${data?.intendedLengthOfStayDropDown}`,
    },
    {
      section: {
        title: "Address where you will stay in the U.S",
        children: [
          {
            label: "Street Address (Line 1)",
            value: data?.streetAddressLine1,
          },
          {
            label: "Street Address (Line 2)",
            value: data?.streetAddressLine2,
          },
          {
            label: "City",
            value: data?.city,
          },
          {
            label: "State",
            value: data?.state,
          },
          {
            label: "Zip Code (If known)",
            value: data?.zipCode,
          },
          {
            label: "Person Or Entity Paying For Your Trip",
            value: data?.personPaying,
          },
        ],
      },
    },
    {
      section: {
        title: "Person Paying information",
        children: [
          {
            label: "Surnames of Person Paying for Trip",
            value: data?.personPayingSurnames,
          },
          {
            label: "Given Names of Person Paying for Trip",
            value: data?.personPayingGivenNames,
          },
          {
            label: "Email Address",
            value: data?.personPayingEmail,
          },
          {
            label: "Relationship to You",
            value: data?.personPayingRelationship,
          },
          {
            label:
              "Is the address of the party paying for your trip the same as your Home or Mailing Address?",
            value: data?.personPayingIsSameAddress,
          },
          {
            label: "Street Address (Line 1)",
            value: data?.personPayingStreetAddressLine1,
          },
          {
            label: "Street Address (Line 2)",
            value: data?.personPayingStreetAddressLine2,
          },
          {
            label: "City",
            value: data?.personPayingCity,
          },
          {
            label: "Country",
            value: data?.personPayingCountry,
          },
          {
            label: "State",
            value: data?.personPayingState,
          },
          {
            label: "Postal Zone/ZIP Code",
            value: data?.personPayingZipCode,
          },
        ],
      },
    },

    {
      section: {
        title: "Other Company/Organization Paying information",
        children: [
          {
            label: "Name of Company/Organization Paying for Trip",
            value: data?.companyPayingName,
          },
          {
            label: "Telephone Number",
            value: data?.companyPayingTelephone,
          },
          {
            label: "Relationship to You",
            value: data?.companyPayingRelationship,
          },
          {
            label: "Street Address (Line 1)",
            value: data?.companyPayingStreetAddressLine1,
          },
          {
            label: "Street Address (Line 2)",
            value: data?.companyPayingStreetAddressLine2,
          },
          {
            label: "City",
            value: data?.companyPayingCity,
          },
          {
            label: "Country",
            value: data?.companyPayingCountry,
          },
          {
            label: "State",
            value: data?.companyPayingState,
          },
          {
            label: "Postal Zone/ZIP Code",
            value: data?.companyPayingZipCode,
          },
        ],
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.travel,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewTravel;

import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import SelectField from "../../../components/formik fields/SelectField";
import InputField, {
  Label,
} from "../../../components/formik fields/InputField";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import FormBtn from "../../../components/FormBtn";
import {
  Info,
  InfoBold,
  TermsWrapper,
  VerticalFormWrapper,
  Wrapper,
} from "../styles";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initCheck,
  initInput,
  initSelect,
} from "../../../components/data";
import { Modify, SingleOption } from "../../../../../types/types";
import { saveDS160PersonalInfo2Obj } from "../../../../../api/ds160/types";
import {
  useGetDS160PersonalInfo2,
  useSaveDS160PersonalInfo2,
} from "../../../../../hooks/ds160/usDS160";
import { PIPersonal2Validation } from "../validation";
import { resetDynamicFields } from "../../../components/data";
import useCountries from "../../../components/useCountries";
import useToNextSection from "../../../../../hooks/useToNextSection";

export interface PIPersonal2FormData
  extends Modify<
    Omit<saveDS160PersonalInfo2Obj, "SSN">,
    {
      countryOrRegion: SingleOption | null;
      otherCountryOrRegion: SingleOption | null;
      SSN1: string;
      SSN2: string;
      SSN3: string;
    }
  > {}

const PIPersonal2 = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetDS160PersonalInfo2();
  const { mutate, isLoading: isSaving } = useSaveDS160PersonalInfo2();
  const { formattedCountryData, countryLoading } = useCountries();

  const initialValues: PIPersonal2FormData = {
    countryOrRegion: initSelect(formattedCountryData, data?.countryOrRegion),
    heldAnotherNationality: initInput(data?.heldAnotherNationality),
    prOfOtherCountry: initInput(data?.prOfOtherCountry),
    otherCountryOrRegion: initSelect(
      formattedCountryData,
      data?.otherCountryOrRegion
    ),
    nin: initInput(data?.nin),
    doesNotApplyNin: initCheck(data?.doesNotApplyNin),
    SSN1: initInput(data?.SSN.substring(0, 2)),
    SSN2: initInput(data?.SSN.substring(2, 4)),
    SSN3: initInput(data?.SSN.substring(4)),
    doesNotApplySSN: initCheck(data?.doesNotApplySSN),
    idNumber: initInput(data?.idNumber),
    doesNotApplyIdNumber: initCheck(data?.doesNotApplyIdNumber),
  };

  const onSubmit = (values: PIPersonal2FormData) => {
    const { countryOrRegion, otherCountryOrRegion, SSN1, SSN2, SSN3, ...rest } =
      values;

    if (!countryOrRegion) return;

    const payload = {
      ...rest,
      countryOrRegion: countryOrRegion.value,
      otherCountryOrRegion: otherCountryOrRegion?.value || "",
      SSN: `${SSN1}${SSN2}${SSN3}`,
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Data on this page must match information as written on your
            passport.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={PIPersonal2Validation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <SelectField
                  name="countryOrRegion"
                  label="Country/Region of Origin (Nationality)"
                  placeholder="-Select-"
                  options={formattedCountryData}
                  isLoading={countryLoading}
                />
                <RadioComp
                  title="Do you hold or have you held another nationality other than
                the one indicated above on nationality?"
                  name="heldAnotherNationality"
                  options={GenericYesNo}
                />
                <RadioComp
                  title="Are you a permanent resident of a country/region other than
                your country/region of origin (nationality) indicated above?"
                  name="prOfOtherCountry"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "otherCountryOrRegion", value: null },
                    ]);
                  }}
                />
                {formik.values.prOfOtherCountry ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <SelectField
                      name="otherCountryOrRegion"
                      label="Country/Region of Origin (Nationality)"
                      placeholder="-Select-"
                      options={formattedCountryData}
                      isLoading={countryLoading}
                    />
                  </>
                )}

                <div>
                  <InputField
                    label="National Identification Number"
                    placeholder=""
                    name="nin"
                    isDisabled={!!formik.values.doesNotApplyNin}
                  />
                  <TermsWrapper>
                    <CheckBoxField
                      label={`Does not apply`}
                      name="doesNotApplyNin"
                      onChange={() => {
                        resetDynamicFields(formik, [
                          { name: "nin", value: "" },
                        ]);
                      }}
                    />
                  </TermsWrapper>
                </div>

                <div>
                  <Label>U.S Social Security Number</Label>
                  <SocialWrapper>
                    <SocialFlex>
                      <InputField
                        label=""
                        placeholder=""
                        name="SSN1"
                        isDisabled={!!formik.values.doesNotApplySSN}
                      />
                      <Dash>-</Dash>
                      <InputField
                        label=""
                        placeholder=""
                        name="SSN2"
                        isDisabled={!!formik.values.doesNotApplySSN}
                      />
                      <Dash>-</Dash>
                      <InputField
                        label=""
                        placeholder=""
                        name="SSN3"
                        isDisabled={!!formik.values.doesNotApplySSN}
                      />
                    </SocialFlex>
                    <TermsWrapper>
                      <CheckBoxField
                        label={`Does not apply`}
                        name="doesNotApplySSN"
                        onChange={() => {
                          resetDynamicFields(formik, [
                            { name: "SSN1", value: "" },
                            { name: "SSN2", value: "" },
                            { name: "SSN3", value: "" },
                          ]);
                        }}
                      />
                    </TermsWrapper>
                  </SocialWrapper>
                </div>

                <div>
                  <InputField
                    label="U.S Taxpayer ID Number"
                    placeholder=""
                    name="idNumber"
                    isDisabled={!!formik.values.doesNotApplyIdNumber}
                  />
                  <TermsWrapper>
                    <CheckBoxField
                      label={`Does not apply`}
                      name="doesNotApplyIdNumber"
                      onChange={() => {
                        resetDynamicFields(formik, [
                          { name: "idNumber", value: "" },
                        ]);
                      }}
                    />
                  </TermsWrapper>
                </div>
              </FormWrapper>
              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default PIPersonal2;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    width: 50%;
  }
`;

const SocialFlex = styled.div`
  width: 30%;
  display: flex;
  align-items: flex-start;
  gap: 9px;

  & > div {
    width: 50%;
  }
`;

const Dash = styled.div`
  margin-top: 0.5rem;
`;

const SocialWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */
  margin-top: 0.375rem;
`;

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { userDataStore } from "../store/userData";
import { useSnapshot } from "valtio";
import { FormCategories } from "../routes/type";
import { EnumX } from "../types/types";
import { FormN565Progress } from "../views/web/form 565/components/types";
import { FormN400Progress } from "../views/web/form 400/components/types";
import { FormDS160Progress } from "../views/web/form ds 160/components/types";
import { getEnumIndex } from "../views/web/components/data";
import useBasedOnFormPaid from "./useBasedOnFormPaid";
import { FormN600Progress } from "../views/web/form 600/components/types";

const useToNextSection = () => {
  const navigate = useNavigate();
  const { basedOnFormPaid } = useBasedOnFormPaid();
  const { formCategory: urlFormCategory } = useParams();
  const { userData } = useSnapshot(userDataStore);
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("type") as any;
  const lastSavedProgress = userData?.currentFormInfo.formProgress;

  const getNextSection = (formProgressEnum: any) => {
    const nextSection = EnumX.of(formProgressEnum).next(getType); //possible value is undefined and a form progress value
    if (!userDataStore.userData || !urlFormCategory) return;

    if (nextSection) {
      const nextProgress = getEnumIndex(formProgressEnum, nextSection);
      const savedProgress = getEnumIndex(formProgressEnum, lastSavedProgress);
      navigate(`?type=${nextSection}`);
      if (savedProgress > nextProgress) return;
      if (basedOnFormPaid) return;
      //update store
      if (!userData?.currentFormInfo.isFormCompleted) {
        userDataStore.userData.currentFormInfo.formCategory = urlFormCategory;
        userDataStore.userData.currentFormInfo.formProgress = nextSection;

        if (
          nextSection === `${userData?.currentFormInfo.formCategory}-review`
        ) {
          userDataStore.userData.currentFormInfo.isFormCompleted = true;
        }
      }
    }
  };

  const getPrevSection = (formProgressEnum: any) => {
    const nextSection = EnumX.of(formProgressEnum).prev(getType); //possible value is undefined and a form progress value

    if (!userDataStore.userData || !urlFormCategory) return;

    if (nextSection) {
      navigate(`?type=${nextSection}`);
    }
  };

  const toSection = (params: (formProgressEnum: any) => void) => {
    if (urlFormCategory === FormCategories.n565) {
      params(FormN565Progress);
    }

    if (urlFormCategory === FormCategories.n400) {
      params(FormN400Progress);
    }

    if (urlFormCategory === FormCategories.ds160) {
      params(FormDS160Progress);
    }

    if (urlFormCategory === FormCategories.n600) {
      params(FormN600Progress);
    }
  };

  const toNextSection = () => {
    toSection(getNextSection);
  };

  const toPrevSection = () => {
    toSection(getPrevSection);
  };

  return { toNextSection, toPrevSection };
};

export default useToNextSection;

import React, { FC } from "react";
import { saveDS160AddressAndPhoneObj } from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { reviewDoesNotApply } from "../../../../components/data";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160AddressAndPhoneObj;
}

const ReviewAddressAndPhone: FC<Props> = ({ title, data, isDownload }) => {
  const section: ReviewData[] = [
    {
      label: "Home Address",
      value: data?.homeAddress,
    },
    {
      label: "Street Address Line 1",
      value: data?.streetAddressLine1,
    },
    {
      label: "Street Address Line 2 (Optional)",
      value: data?.streetAddressLine2,
    },
    {
      label: "City",
      value: data?.city,
    },
    {
      label: "Sate/Province",
      value: data?.stateOrProvince,
    },
    {
      label: "Country/Region",
      value: data?.countryOrRegion,
    },
    {
      label: "Postal Code/Zip Code",
      value: data?.zipCode,
    },
    {
      section: {
        title: "MAILING ADDRESS",
        children: [
          {
            label: "Is your mailing address the same as your home address?",
            value: data?.mailAddressSameAsHomeAddress,
          },
          {
            label: "Home Address",
            value: data?.mailHomeAddress,
          },
          {
            label: "Street Address Line 1",
            value: data?.mailStreetAddressLine1,
          },
          {
            label: "Street Address Line 2 (Optional)",
            value: data?.mailStreetAddressLine2,
          },
          {
            label: "City",
            value: data?.mailCity,
          },
          {
            label: "Sate/Province",
            value: data?.mailStateOrProvince,
          },
          {
            label: "Country Region",
            value: data?.mailCountryOrRegion,
          },
          {
            label: "Postal Code/Zip Code",
            value: data?.mailZipCode,
          },
          {
            label: "Primary Phone Number",
            value: data?.primaryPhoneNumber,
          },
          {
            label: "Secondary Phone Number",
            value: reviewDoesNotApply(
              data?.secondaryPhoneNumber,
              data?.doesNotApplySecPN
            ),
          },
          {
            label: "Work Phone Number",
            value: reviewDoesNotApply(
              data?.workPhoneNumber,
              data?.doesNotApplyWorkPN
            ),
          },
        ],
      },
    },
    {
      label: "Have you used any other phone number in the last five (5) years?",
      value: data?.anyOtherPhoneNumber,
    },
    {
      label: "Additional Phone Number",
      value: data?.additionalPhoneNumber,
    },
    {
      label: "Email Address",
      value: data?.email,
    },
    {
      label:
        "Have you used any other email address in the last five (5) years?",
      value: data?.anyOtherEmail,
    },
    {
      label: `Additional Email Address`,
      value: data?.additionalEmail,
    },
    {
      label: "Social Media",
      value: data?.socialMedia,
    },
    {
      label: "Social Media Identifier",
      value: data?.socialMediaIdentifier,
    },
    {
      label:
        "Do you wish to provide information about your presence and any other websites or applications you have used within the last 5 years to create or share content (photos videos updates)",
      value: data?.presenceOnWebOrApp,
    },
    {
      label: "Additional social media platform",
      value: data?.additionalSocialMedia,
    },
    {
      label: "Additional social media handle",
      value: data?.additionalSocialMediaHandle,
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.addressAndPhone,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewAddressAndPhone;

import React, { FC } from "react";
import {
  saveDS160SecurityBackgroundP1Obj,
  saveDS160SecurityBackgroundP2Obj,
  saveDS160SecurityBackgroundP3Obj,
  saveDS160SecurityBackgroundP4Obj,
  saveDS160SecurityBackgroundP5Obj,
} from "../../../../../../api/ds160/types";
import ReviewBox, {
  ReviewData,
  ReviewSectionChildrenData,
} from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: {
    partOne: saveDS160SecurityBackgroundP1Obj;
    partTwo: saveDS160SecurityBackgroundP2Obj;
    partThree: saveDS160SecurityBackgroundP3Obj;
    partFour: saveDS160SecurityBackgroundP4Obj;
    partFive: saveDS160SecurityBackgroundP5Obj;
  };
}

const ReviewSecurity: FC<Props> = ({ title, data, isDownload }) => {
  const sectionA: ReviewSectionChildrenData[] = [
    {
      label:
        "Do you have a communicable disease of public health significance communicable diseases of public significance include chancroid, gonorrhea, granuloma inguinale, infectious leprosy, lymphogranuloma venereum, infectious stage syphilis, active tuberculosis, and other diseases as determined by the  Department of health and human services?",
      value: data?.partOne?.partOneI,
    },
    {
      label: "Other Status? explain.",
      value: data?.partOne?.explanationPartOneI,
    },
    {
      label:
        "Do you have a mental or physical disorder that processes or is likely to pose a threat to the safety or welfare of yourself or others?",
      value: data?.partOne?.partOneII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partOne?.explanationPartOneII,
    },
    {
      label: "Are you or have you ever been a drug abuser or addict?",
      value: data?.partOne?.partOneIII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partOne?.explanationPartOneIII,
    },
  ];

  const sectionB: ReviewSectionChildrenData[] = [
    {
      label:
        "Have you ever been arrested or convicted for any offence or crime even though subject of a pardon amnesty or other similar actions?",
      value: data?.partTwo?.partTwoI,
    },
    {
      label: "Other Status? explain.",
      value: data?.partTwo?.explanationPartTwoI,
    },
    {
      label:
        "Have you ever been related or engaged in a conspiracy to violate any law relating to controlled substances?",
      value: data?.partTwo?.partTwoII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partTwo?.explanationPartTwoII,
    },
    {
      label:
        "Are you coming to the United States to engage in prostitution or unlawful commercialized vice or have you been engaged in  prostitution or procuring prostitutes within the past 10 years?",
      value: data?.partTwo?.partTwoIII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partTwo?.explanationPartTwoIII,
    },
    {
      label:
        "Have you ever been involved in or do you seek to engage in money laundering?",
      value: data?.partTwo?.partTwoIV,
    },
    {
      label: "Other Status? explain.",
      value: data?.partTwo?.explanationPartTwoIV,
    },
    {
      label:
        "Have you ever committed or conspire to commit a human trafficking offence in the United States or outside United States?",
      value: data?.partTwo?.partTwoV,
    },
    {
      label: "Other Status? explain.",
      value: data?.partTwo?.explanationPartTwoV,
    },
    {
      label:
        "Have you ever loo in the aided abetted, assisted or colluded with an individual who has committed or conspire to commit a severe human trafficking offence in the United States or outside the United States?",
      value: data?.partTwo?.partTwoVI,
    },
    {
      label: "Other Status? explain.",
      value: data?.partTwo?.explanationPartTwoVI,
    },
    {
      label:
        "Are you the spouse son or daughter of an individual who has committed of conspired to commit human trafficking offence and United States or after United States and have you wouldn't last 5 years mainly benefited from the trafficking activities?",
      value: data?.partTwo?.partTwoVII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partTwo?.explanationPartTwoVII,
    },
  ];

  const sectionC: ReviewSectionChildrenData[] = [
    {
      label:
        "Do you seek to engage in espionage sabotage, exports control violations or any other illegal activity while in the United States?",
      value: data?.partThree?.partThreeI,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeI,
    },
    {
      label:
        "Do you seek to engage in terrorist activities while in the United States or have you ever engage in terrorist activities?",
      value: data?.partThree?.partThreeII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeII,
    },
    {
      label:
        "Have you ever or do you intend to provide financial assistance or other support to terrorists or terrorist organisations?",
      value: data?.partThree?.partThreeIII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeIII,
    },
    {
      label: "Are you a member or representative of a terrorist organization?",
      value: data?.partThree?.partThreeIV,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeIV,
    },
    {
      label:
        "Are you the spouse son or daughter of an individual who has engaged in terrorist activity including providing financial assistance all the support 2 terrorist or terrorist organizations, in the last 5 years?",
      value: data?.partThree?.partThreeV,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeV,
    },
    {
      label:
        "Have you ever ordered incited committed assisted or otherwise participated in genocide?",
      value: data?.partThree?.partThreeVI,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeVI,
    },
    {
      label:
        "Have you ever committed, ordered, incited, assisted, or otherwise participated in torture?",
      value: data?.partThree?.partThreeVII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeVII,
    },
    {
      label:
        "Have you ever committed, ordered, incited, assisted, or otherwise participated in extrajudicial killings, political killings, or other acts of violence?",
      value: data?.partThree?.partThreeVIII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeVIII,
    },
    {
      label:
        "Have you ever engaged in the recruitment or the use of child soldiers?",
      value: data?.partThree?.partThreeIX,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeIX,
    },
    {
      label:
        "Have you ever been directly involved in the establishment or enforcement of population controls forcing a woman to undergo an abortion against her free choice or a man or a woman to undergo sterilization against her free will?",
      value: data?.partThree?.partThreeX,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeX,
    },
    {
      label:
        "Have you ever been directly involved in the coercive transplantation of human organs or bodily tissue?",
      value: data?.partThree?.partThreeXI,
    },
    {
      label: "Other Status? explain.",
      value: data?.partThree?.explanationPartThreeXI,
    },
  ];

  const sectionD: ReviewSectionChildrenData[] = [
    {
      label:
        "Have you ever sought to obtain or assist others to obtain a visa, entry, into the United States, or any other united states immigration benefit by fraud or willful misrepresentation or other unlawful means?",
      value: data?.partFour?.partFourI,
    },
    {
      label: "Other Status? explain.",
      value: data?.partFour?.explanationPartFourI,
    },
    {
      label: "Have you ever been removed or deported from any country?",
      value: data?.partFour?.partFourII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partFour?.explanationPartFourII,
    },
  ];

  const sectionE: ReviewSectionChildrenData[] = [
    {
      label:
        "Have you ever withheld custody of a US citizen child outside the United States from a person granted legal custody by a U.S court?",
      value: data?.partFive?.partFiveI,
    },
    {
      label: "Other Status? explain.",
      value: data?.partFive?.explanationPartFiveI,
    },
    {
      label:
        "Have you voted in the united states in violation of any law or regulation?",
      value: data?.partFive?.partFiveII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partFive?.explanationPartFiveII,
    },
    {
      label:
        "Have you ever renounced United States citizenship for the purposes of avoiding taxation?",
      value: data?.partFive?.partFiveIII,
    },
    {
      label: "Other Status? explain.",
      value: data?.partFive?.explanationPartFiveIII,
    },
  ];
  const section: ReviewData[] = [
    {
      section: {
        title: "Part 1",
        children: sectionA,
      },
    },
    {
      section: {
        title: "Part 2",
        children: sectionB,
      },
    },
    {
      section: {
        title: "Part 3",
        children: sectionC,
      },
    },
    {
      section: {
        title: "Part 4",
        children: sectionD,
      },
    },
    {
      section: {
        title: "Part 5",
        children: sectionE,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.securityBackgroundP1,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewSecurity;

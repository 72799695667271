import React, { FC } from "react";
import styled from "styled-components";
// import { useNavigate } from "react-router-dom";
import Colors from "../../../../styles/Colors";
import { ProfileNavProps } from "../../layout/components/data";

interface Props {
  data: ProfileNavProps["children"];
  onClose: () => void;
}

const ExtraNavItem: FC<Props> = ({ data, onClose }) => {
  // const navigate = useNavigate();

  return (
    <Container className="child-nav">
      <Wrapper>
        <MainFlexer>
          {data?.map((ev, i) => (
            <MainBox key={i}>
              <button
                onClick={() => {
                  // navigate(ev.url);
                  ev.onClick && ev.onClick();
                  onClose();
                }}
              >
                {ev.name}
              </button>
            </MainBox>
          ))}
        </MainFlexer>
      </Wrapper>
    </Container>
  );
};

export default ExtraNavItem;

const Container = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    /* left: -40px; */
    top: 100%;
  }
`;

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 6px;
  /* display: flex; */
  /* flex-direction: column; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 12px;
  margin-top: 10px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const MainFlexer = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  gap: 12px;

  @media only screen and (min-width: 768px) {
    /* padding-right: 18px; */
  }
`;

// interface MainBoxProps {
//   isActive: boolean;
// }
// interface MainBoxTextsProps {
//   isActive: boolean;
// }

const MainBox = styled.div`
  padding: 10px 12px;
  margin-bottom: 0.2rem;

  cursor: pointer;
  border-radius: 8px;

  color: ${Colors.GreyA7};

  &:hover {
    background-color: "#e0e2e3";
    color: ${Colors.Blue00};
  }

  @media only screen and (min-width: 768px) {
    width: 177px;
  }

  &:hover {
    background-color: #e0e2e3;
    transition: 0.3s ease-in-out;
  }
`;

// const MainBoxText = styled.div`
//   font-size: 0.875rem;
//   font-weight: 500;
//   line-height: 1.0625rem;
//   letter-spacing: 0em;
//   text-align: center;
//   /* color: #000000; */
// `;

// const RightSection = styled.div`
//   background-color: #f9fcff;
//   padding: 34px 21px;
//   /* width: 407px; */
// `;

// const HR = styled.div`
//   height: 0.5px;
//   background-color: #b3bac1;
//   width: 90%;
//   margin-bottom: 18px;
// `;

// const ChildrenFlexer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 24px;
// `;

// const ChildrenBox = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 12px;
//   font-size: 0.875rem;
//   font-weight: 400;
//   line-height: 1.0625rem;
//   letter-spacing: 0em;
//   color: #6b6d70;
//   cursor: pointer;

//   &:hover {
//     color: #000000;
//     transition: 0.2s ease-out;
//   }
// `;

// const PolyGon = styled.div`
//   width: 12px;
//   height: 12px;
//   background: #0076e9;
//   transform: rotate(45deg);
// `;

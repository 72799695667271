import * as Yup from "yup";
import { SchemaObject, ValidateOptionsExtended } from "../../components/type";
import { PIPersonal1FormData } from "./Personal info/PIPersonal1";
import {
  GenericYesNoOptionsValue,
  riskyCheckBoxName,
} from "../../components/data";
import { PIPersonal2FormData } from "./Personal info/PIPersonal2";
import { TravelSectionFormData } from "./TravelSection";
import { TravelCompSectionFormData } from "./TravelCompSection";
import { PrevUSTravelSectionFormData } from "./PrevUSTravelSection";
import { AddressAndPhoneData } from "./AddressAndPhone";
import { PassportData } from "./Passport";
import { USContactData } from "./USContact";
import { FamilyData } from "./FamilyRelatives/FamilyRelatives";
import { WETPresentFormData } from "./Work edu training/WPresent";
import {
  EduArrayPropsPayload,
  PrevEmpArrayPropsPayload,
  WETPreviousFormData,
} from "./Work edu training/Previous";
import {
  MilitaryArrayPropsPayload,
  WETAdditionalFormData,
} from "./Work edu training/Additional";
import {
  DS160EligibilityPayload,
  saveDS160SecurityBackgroundP1Obj,
  saveDS160SecurityBackgroundP2Obj,
  saveDS160SecurityBackgroundP3Obj,
  saveDS160SecurityBackgroundP4Obj,
  saveDS160SecurityBackgroundP5Obj,
} from "../../../../api/ds160/types";
import { StudentExchangeVisaSEVISFormData } from "./Student exchange visa/Sevis";
import { StudentExchangeVisaAdditionalData } from "./Student exchange visa/AdditionalContact";
import { digitsOnly, optionalDigitsOnly } from "../../../../utils";
import dayjs from "dayjs";
import {
  DS160MaritalStatusValues,
  PersonAddressOptionsValues,
  PersonPayingOptionsValues,
  WETATravelledLastFiveYearsCondition,
} from "./data";
import { MaritalStatusFormData } from "./FamilyRelatives/MaritalStatus";

export const MaxFileSize = 240000; // in bytes

const fileSizeVal = (value?: any) => {
  if (value) {
    const file = value;
    if (file.size > MaxFileSize) {
      return false;
    } else return true;
  } else return false;
};

const fileSizeValText = "File size exceeds 240kb limit.";

export const GetStartedValidation = () =>
  Yup.object({
    ds160GetStarted: Yup.mixed()
      .nullable()
      .required("Required")
      .test("File Size", fileSizeValText, fileSizeVal),
  });

const dependsOnAnyOtherNames = () => {
  return Yup.string().when("anyOtherNames", ([anyOtherNames], schema) => {
    if (anyOtherNames === GenericYesNoOptionsValue.yes)
      return Yup.string().required("Required").trim();
    return schema;
  });
};

const dependsOnTeleCode = () => {
  return Yup.string().when("teleCode", ([teleCode], schema) => {
    if (teleCode === GenericYesNoOptionsValue.yes)
      return Yup.string().required("Required").trim();
    return schema;
  });
};

export const PIPersonal1Validation = Yup.object().shape<
  SchemaObject<PIPersonal1FormData>
>({
  lastName: Yup.string().required("Required").trim(),
  givenNames: Yup.string().required("Required").trim(),
  fullName: Yup.string().when("doesNotApply", ([doesNotApply], schema) => {
    if (!doesNotApply) return Yup.string().required("Required").trim();
    return schema;
  }),
  doesNotApply: Yup.bool(),
  anyOtherNames: Yup.string().required("Required").trim(),
  otherFirstName: dependsOnAnyOtherNames(),
  otherMiddleName: Yup.string().trim(),
  otherLastName: dependsOnAnyOtherNames(),
  teleCode: Yup.string().required("Required").trim(),
  teleCodeSurname: dependsOnTeleCode(),
  teleCodeGivenNames: dependsOnTeleCode(),
  sex: Yup.string().required("Required").trim(),
  // maritalStatus: Yup.object().nullable().required("Required"),
  stateAndProvince: Yup.object().nullable().required("Required"),
  dateOfBirth: Yup.string().required("Required").trim(),
  city: Yup.string().required("Required").trim(),
  countryOrRegion: Yup.object().nullable().required("Required"),
  location: Yup.object().nullable().required("Required"),
});

const dependsOnDoesNotApplySSN = (index: number) => {
  //The Social Security number is a nine-digit number in the format "AAA-GG-SSSS".
  const getIndex = index === 1 ? 3 : index === 2 ? 2 : 4;
  return Yup.string().when("doesNotApplySSN", ([doesNotApplySSN], schema) => {
    if (!doesNotApplySSN)
      return Yup.string()
        .required("Required")
        .trim()
        .label(" ")
        .length(getIndex)
        .test("Digits only", "Must be a number", digitsOnly);
    return schema;
  });
};

export const PIPersonal2Validation = Yup.object().shape<
  SchemaObject<PIPersonal2FormData>
>({
  countryOrRegion: Yup.object().nullable().required("Required"),
  heldAnotherNationality: Yup.string().required("Required").trim(),
  prOfOtherCountry: Yup.string().required("Required").trim(),
  otherCountryOrRegion: Yup.object()
    .nullable()
    .when("prOfOtherCountry", ([prOfOtherCountry], schema) => {
      if (prOfOtherCountry === GenericYesNoOptionsValue.yes)
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  nin: Yup.string().when("doesNotApplyNin", ([doesNotApplyNin], schema) => {
    if (!doesNotApplyNin) return Yup.string().required("Required").trim();
    return schema;
  }),
  doesNotApplyNin: Yup.bool(),
  SSN1: dependsOnDoesNotApplySSN(1),
  SSN2: dependsOnDoesNotApplySSN(2),
  SSN3: dependsOnDoesNotApplySSN(3),
  doesNotApplySSN: Yup.bool(),
  idNumber: Yup.string().when(
    "doesNotApplyIdNumber",
    ([doesNotApplyIdNumber], schema) => {
      if (!doesNotApplyIdNumber)
        return Yup.string()
          .required("Required")
          .trim()
          .label("U.S Taxpayer ID Number")
          .length(9)
          .test("Digits only", "Must be a number", digitsOnly);
      return schema;
    }
  ),
  doesNotApplyIdNumber: Yup.bool(),
});

const dependsOnHaveYouMadeSpecificTravelPlansYes = (isDate?: boolean) => {
  if (isDate) {
    return Yup.string()
      .nullable()
      .when(
        "haveYouMadeSpecificTravelPlans",
        ([haveYouMadeSpecificTravelPlans], schema) => {
          if (haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.yes)
            return Yup.string().nullable().required("Required").trim();
          return schema;
        }
      );
  } else
    return Yup.string().when(
      "haveYouMadeSpecificTravelPlans",
      ([haveYouMadeSpecificTravelPlans], schema) => {
        if (haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.yes)
          return Yup.string().required("Required").trim();
        return schema;
      }
    );
};

const dependsOnHaveYouMadeSpecificTravelPlansNo = (
  type?: "isDate" | "isDropDown"
) => {
  if (type === "isDate") {
    return Yup.string()
      .nullable()
      .when(
        "haveYouMadeSpecificTravelPlans",
        ([haveYouMadeSpecificTravelPlans], schema) => {
          if (haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.no) {
            return Yup.string().nullable().required("Required").trim();
          }
          return schema;
        }
      );
  } else if (type === "isDropDown") {
    return Yup.object()
      .nullable()
      .when(
        "haveYouMadeSpecificTravelPlans",
        ([haveYouMadeSpecificTravelPlans], schema) => {
          if (haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.no) {
            return Yup.object().nullable().required("Required");
          }
          return schema;
        }
      );
  } else
    return Yup.string().when(
      "haveYouMadeSpecificTravelPlans",
      ([haveYouMadeSpecificTravelPlans], schema) => {
        if (haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.no) {
          return Yup.string().required("Required").trim();
        }
        return schema;
      }
    );
};

const dependsOnHaveYouMadeSpecificTravelPlansYesNo = (isObj?: boolean) => {
  if (isObj) {
    return Yup.object()
      .nullable()
      .when(
        "haveYouMadeSpecificTravelPlans",
        ([haveYouMadeSpecificTravelPlans], schema) => {
          if (
            haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.yes ||
            haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.no
          )
            return Yup.object().nullable().required("Required");
          return schema;
        }
      );
  } else
    return Yup.string().when(
      "haveYouMadeSpecificTravelPlans",
      ([haveYouMadeSpecificTravelPlans], schema) => {
        if (
          haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.yes ||
          haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.no
        )
          return Yup.string().required("Required").trim();
        return schema;
      }
    );
};

const dependsOnPersonPaying = (isObj?: boolean, checkName?: string) => {
  if (isObj) {
    return Yup.object()
      .nullable()
      .when(
        checkName ? ["personPaying", checkName] : ["personPaying"],
        ([personPaying, checkName], schema) => {
          if (
            personPaying?.value === PersonPayingOptionsValues["Other Person"] &&
            !checkName
          )
            return Yup.object().nullable().required("Required");
          return schema;
        }
      );
  } else {
    return Yup.string().when(
      checkName ? ["personPaying", checkName] : ["personPaying"],
      ([personPaying, checkName], schema) => {
        if (
          personPaying?.value === PersonPayingOptionsValues["Other Person"] &&
          !checkName
        )
          return Yup.string().required("Required").trim();
        return schema;
      }
    );
  }
};

const dependsOnPersonPayingAddress = (isObj?: boolean, checkName?: string) => {
  if (isObj) {
    return Yup.object()
      .nullable()
      .when(
        checkName
          ? ["personPayingIsSameAddress", checkName]
          : ["personPayingIsSameAddress"],
        ([personPayingIsSameAddress, checkName], schema) => {
          if (
            personPayingIsSameAddress === GenericYesNoOptionsValue.no &&
            !checkName
          )
            return Yup.object().nullable().required("Required");
          return schema;
        }
      );
  } else {
    return Yup.string().when(
      checkName
        ? ["personPayingIsSameAddress", checkName]
        : ["personPayingIsSameAddress"],
      ([personPayingIsSameAddress, checkName], schema) => {
        if (
          personPayingIsSameAddress === GenericYesNoOptionsValue.no &&
          !checkName
        ) {
          return Yup.string().required("Required").trim();
        }
        return schema;
      }
    );
  }
};

const dependsOnCompanyPaying = (isObj?: boolean, checkName?: string) => {
  if (isObj) {
    return Yup.object()
      .nullable()
      .when(
        checkName ? ["personPaying", checkName] : ["personPaying"],
        ([personPaying], schema) => {
          if (
            personPaying?.value ===
              PersonPayingOptionsValues["Other Company/Organization"] &&
            !checkName
          )
            return Yup.object().nullable().required("Required");
          return schema;
        }
      );
  } else {
    return Yup.string().when(
      checkName ? ["personPaying", checkName] : ["personPaying"],
      ([personPaying], schema) => {
        if (
          personPaying?.value ===
            PersonPayingOptionsValues["Other Company/Organization"] &&
          !checkName
        )
          return Yup.string().required("Required").trim();
        return schema;
      }
    );
  }
};

export const TravelSectionValidation = Yup.object().shape<
  SchemaObject<TravelSectionFormData>
>({
  purposeOfTrip: Yup.object().nullable().required("Required"),
  specific: Yup.object().nullable().required("Required"),
  haveYouMadeSpecificTravelPlans: Yup.string().required("Required").trim(),
  dateOfArrival: dependsOnHaveYouMadeSpecificTravelPlansYes(true),
  arrivalFlight: Yup.string().trim(),
  arrivalCity: dependsOnHaveYouMadeSpecificTravelPlansYes(),
  dateOfDeparture: dependsOnHaveYouMadeSpecificTravelPlansYes(true),
  departureFlight: Yup.string().trim(),
  departureCity: dependsOnHaveYouMadeSpecificTravelPlansYes(),
  intendedDateOfArrival: dependsOnHaveYouMadeSpecificTravelPlansNo("isDate"),
  intendedLengthOfStayDuration: dependsOnHaveYouMadeSpecificTravelPlansNo(),
  intendedLengthOfStayDropDown:
    dependsOnHaveYouMadeSpecificTravelPlansNo("isDropDown"),
  locationArray: Yup.array().when(
    "haveYouMadeSpecificTravelPlans",
    ([haveYouMadeSpecificTravelPlans], schema) => {
      if (haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            location: Yup.string().required("Required").trim(),
          })
        );
      return schema;
    }
  ),
  streetAddressLine1: dependsOnHaveYouMadeSpecificTravelPlansYesNo(),
  streetAddressLine2: Yup.string().when(
    "haveYouMadeSpecificTravelPlans",
    ([haveYouMadeSpecificTravelPlans], schema) => {
      if (
        haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.yes ||
        haveYouMadeSpecificTravelPlans === GenericYesNoOptionsValue.no
      )
        return Yup.string().trim();
      return schema;
    }
  ),
  city: dependsOnHaveYouMadeSpecificTravelPlansYesNo(),
  state: dependsOnHaveYouMadeSpecificTravelPlansYesNo(true),
  zipCode: Yup.string()
    .trim()
    .length(5, "Maximum of 5 characters allowed")
    .test("Digits only", "Must be a number", optionalDigitsOnly),
  personPaying: dependsOnHaveYouMadeSpecificTravelPlansYesNo(true),
  personPayingSurnames: dependsOnPersonPaying(),
  personPayingGivenNames: dependsOnPersonPaying(),
  personPayingTelephone: dependsOnPersonPaying(),
  personPayingEmail: Yup.string()
    .email()
    .when(
      ["personPaying", "doesNotApplyPersonPayingEmail"],
      ([personPaying, doesNotApplyPersonPayingEmail], schema) => {
        if (
          personPaying?.value === PersonPayingOptionsValues["Other Person"] &&
          !doesNotApplyPersonPayingEmail
        )
          return Yup.string().email().required("Required").trim();
        return schema;
      }
    ),
  doesNotApplyPersonPayingEmail: Yup.bool(),
  personPayingRelationship: dependsOnPersonPaying(true),
  personPayingIsSameAddress: dependsOnPersonPaying(),
  personPayingStreetAddressLine1: dependsOnPersonPayingAddress(),
  personPayingStreetAddressLine2: Yup.string().trim(),
  personPayingCity: dependsOnPersonPayingAddress(),
  personPayingState: dependsOnPersonPayingAddress(
    true,
    "doesNotApplyPersonPayingState"
  ),
  personPayingZipCode: dependsOnPersonPayingAddress(
    false,
    "doesNotApplyPersonPayingZipCode"
  ),
  personPayingCountry: dependsOnPersonPayingAddress(true),
  doesNotApplyPersonPayingState: Yup.bool(),
  doesNotApplyPersonPayingZipCode: Yup.bool(),
  //
  companyPayingName: dependsOnCompanyPaying(),
  companyPayingTelephone: dependsOnCompanyPaying(),
  companyPayingRelationship: dependsOnCompanyPaying(),
  companyPayingStreetAddressLine1: dependsOnCompanyPaying(),
  companyPayingStreetAddressLine2: dependsOnCompanyPaying(),
  companyPayingCity: dependsOnCompanyPaying(),
  companyPayingCountry: dependsOnCompanyPaying(true),
  companyPayingState: dependsOnCompanyPaying(
    true,
    "doesNotApplyCompanyPayingState"
  ),
  doesNotApplyCompanyPayingState: Yup.bool(),
  companyPayingZipCode: dependsOnCompanyPaying(
    false,
    "doesNotApplyCompanyPayingZipCode"
  ),
  doesNotApplyCompanyPayingZipCode: Yup.bool(),
});

export const TravelCompSectionValidation = Yup.object().shape<
  SchemaObject<TravelCompSectionFormData>
>({
  otherPersonsTravelling: Yup.string().required("Required").trim(),
  travellingAsPartOfGroup: Yup.string().when(
    "otherPersonsTravelling",
    ([otherPersonsTravelling], schema) => {
      if (otherPersonsTravelling === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  groupName: Yup.string().when(
    "travellingAsPartOfGroup",
    ([travellingAsPartOfGroup], schema) => {
      if (travellingAsPartOfGroup === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  notGroupArray: Yup.array().when(
    "travellingAsPartOfGroup",
    ([travellingAsPartOfGroup], schema) => {
      if (travellingAsPartOfGroup === GenericYesNoOptionsValue.no)
        return Yup.array().of(
          Yup.object().shape({
            surname: Yup.string().required("Required").trim(),
            givenName: Yup.string().required("Required").trim(),
            relationshipwithPerson: Yup.object()
              .nullable()
              .required("Required"),
          })
        );
      return schema;
    }
  ),
});

const dependsOnHaveDriverLicense = () => {
  return Yup.string().when(
    "haveDriverLicense",
    ([haveDriverLicense], schema) => {
      if (haveDriverLicense === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  );
};

const dependsOnEverIssuedUsVisa = (isDate?: boolean) => {
  if (isDate) {
    return Yup.string()
      .nullable()
      .when("everIssuedUsVisa", ([everIssuedUsVisa], schema) => {
        if (everIssuedUsVisa === GenericYesNoOptionsValue.yes)
          return Yup.string().nullable().required("Required");
        return schema;
      });
  } else
    return Yup.string().when(
      "everIssuedUsVisa",
      ([everIssuedUsVisa], schema) => {
        if (everIssuedUsVisa === GenericYesNoOptionsValue.yes)
          return Yup.string().required("Required").trim();
        return schema;
      }
    );
};

const dependsOnVisaEverStolen = (isDate?: boolean) => {
  if (isDate) {
    return Yup.string()
      .nullable()
      .when("visaEverStolen", ([visaEverStolen], schema) => {
        if (visaEverStolen === GenericYesNoOptionsValue.yes)
          return Yup.string().nullable().required("Required").trim();
        return schema;
      });
  } else
    return Yup.string().when("visaEverStolen", ([visaEverStolen], schema) => {
      if (visaEverStolen === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    });
};

const dependsOnVisaEverRevoked = (isDate?: boolean) => {
  if (isDate) {
    return Yup.string()
      .nullable()
      .when("visaEverRevoked", ([visaEverRevoked], schema) => {
        if (visaEverRevoked === GenericYesNoOptionsValue.yes)
          return Yup.string().nullable().required("Required").trim();
        return schema;
      });
  } else
    return Yup.string().when("visaEverRevoked", ([visaEverRevoked], schema) => {
      if (visaEverRevoked === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    });
};

const dependsOnFieldExplanation = (params: string) => {
  return Yup.string().when([params], ([params], schema) => {
    if (params === GenericYesNoOptionsValue.yes)
      return Yup.string().required("Required").trim();

    return schema;
  });
};

export const PrevUSTravelSectionValidation = Yup.object().shape<
  SchemaObject<PrevUSTravelSectionFormData>
>({
  everBeenToTheUs: Yup.string().required("Required").trim(),
  everIssuedUsVisa: Yup.string().required("Required").trim(),
  BeenToTheUsArray: Yup.array().when(
    "everBeenToTheUs",
    ([everBeenToTheUs], schema) => {
      if (everBeenToTheUs === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            dateArrived: Yup.string().nullable().required("Required"),
            lengthOfStayDuration: Yup.string()
              .required("Required")
              .trim()
              .test("Digits only", "Must be a number", digitsOnly),
            lengthOfStayDropdown: Yup.object().nullable().required("Required"),
          })
        );
      return schema;
    }
  ),
  haveDriverLicense: Yup.string().when(
    "everBeenToTheUs",
    ([everBeenToTheUs], schema) => {
      if (everBeenToTheUs === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  driverLicenseNumber: dependsOnHaveDriverLicense(),
  stateOfDriverLicense: dependsOnHaveDriverLicense(),
  lastVisaDate: dependsOnEverIssuedUsVisa(true),
  visaNumber: Yup.string().when(
    ["doNotKnowVisaNumber", "everIssuedUsVisa"],
    ([doNotKnowVisaNumber, everIssuedUsVisa], schema) => {
      if (
        !doNotKnowVisaNumber &&
        everIssuedUsVisa === GenericYesNoOptionsValue.yes
      )
        return Yup.string()
          .required("Required")
          .trim()
          .label("Visa Number")
          .length(8);
      return schema;
    }
  ),
  doNotKnowVisaNumber: Yup.bool(),
  applyingForSameTypeOfVisa: dependsOnEverIssuedUsVisa(),
  applyingInSameLocation: dependsOnEverIssuedUsVisa(),
  visaEverStolen: dependsOnEverIssuedUsVisa(),
  visaStolenYear: dependsOnVisaEverStolen(true),
  visaStolenExplanation: dependsOnVisaEverStolen(),
  visaEverRevoked: dependsOnEverIssuedUsVisa(),
  visaRevokeYear: dependsOnVisaEverRevoked(true),
  visaRevokeExplanation: dependsOnVisaEverRevoked(),
  everBeenRefusedUsVisa: Yup.string().required("Required").trim(),
  anyoneFiledPetition: Yup.string().required("Required").trim(),
  //
  beenTenPrinted: dependsOnEverIssuedUsVisa(),
  beenLPR: Yup.string().required("Required").trim(),
  everBeenRefusedUsVisaExplanation: dependsOnFieldExplanation(
    "everBeenRefusedUsVisa"
  ),
  beenLPRExplanation: dependsOnFieldExplanation("beenLPR"),
  anyoneFiledPetitionExplanation: dependsOnFieldExplanation(
    "anyoneFiledPetition"
  ),
});

// todo: THE DYNAMIC VALIDATIONS FOR ADDRESS AND PHONE
/* if mailing address is same as home address  is equal NO */
const dependsOnMailAddressSameAsHomeAddressNo = (isObj?: boolean) => {
  if (isObj) {
    return Yup.object()
      .nullable()
      .when(
        "mailAddressSameAsHomeAddress",
        ([mailAddressSameAsHomeAddress], schema) => {
          if (mailAddressSameAsHomeAddress === GenericYesNoOptionsValue.no)
            return Yup.object().nullable().required("Required");
          return schema;
        }
      );
  } else
    return Yup.string().when(
      "mailAddressSameAsHomeAddress",
      ([mailAddressSameAsHomeAddress], schema) => {
        if (mailAddressSameAsHomeAddress === GenericYesNoOptionsValue.no)
          return Yup.string().required("Required").trim();
        return schema;
      }
    );
};

export const AddressAndPhoneValidation = Yup.object().shape<
  SchemaObject<AddressAndPhoneData>
>({
  homeAddress: Yup.string().required("Required").trim(),
  streetAddressLine1: Yup.string().required("Required").trim(),
  streetAddressLine2: Yup.string().trim(),
  city: Yup.string().required("Required").trim(),
  stateOrProvince: Yup.object().nullable().required("Required"),
  zipCode: Yup.string().required("Required").trim(),
  countryOrRegion: Yup.object().nullable().required("Required"),
  mailAddressSameAsHomeAddress: Yup.string().required("Required").trim(),
  mailHomeAddress: dependsOnMailAddressSameAsHomeAddressNo(),
  mailStreetAddressLine1: dependsOnMailAddressSameAsHomeAddressNo(),
  mailStreetAddressLine2: Yup.string().trim(),
  mailCity: dependsOnMailAddressSameAsHomeAddressNo(),
  mailStateOrProvince: dependsOnMailAddressSameAsHomeAddressNo(true),
  mailZipCode: dependsOnMailAddressSameAsHomeAddressNo(),
  mailCountryOrRegion: dependsOnMailAddressSameAsHomeAddressNo(true),
  primaryPhoneNumber: Yup.string()
    // .matches(/^\d{10}$/, "Must be a 10-digit number")
    .when(
      "mailAddressSameAsHomeAddress",
      ([mailAddressSameAsHomeAddress], schema) => {
        if (mailAddressSameAsHomeAddress === GenericYesNoOptionsValue.yes)
          return (
            Yup.string()
              // .matches(/^\d{10}$/, "Must be a 10-digit number")
              .required("Required")
              .trim()
          );
        return schema;
      }
    ),
  secondaryPhoneNumber: Yup.string().when(
    "mailAddressSameAsHomeAddress",
    ([mailAddressSameAsHomeAddress], schema) => {
      if (mailAddressSameAsHomeAddress === GenericYesNoOptionsValue.yes)
        return Yup.string().when(
          "doesNotApplySecPN",
          ([doesNotApplySecPN], schema) => {
            if (!doesNotApplySecPN)
              return Yup.string()
                .matches(/^\d{10}$/, "Must be a 10-digit number")
                .required("Required")
                .trim();
            return schema;
          }
        );
      return schema;
    }
  ),
  doesNotApplySecPN: Yup.bool(),
  workPhoneNumber: Yup.string().when(
    "mailAddressSameAsHomeAddress",
    ([mailAddressSameAsHomeAddress], schema) => {
      if (mailAddressSameAsHomeAddress === GenericYesNoOptionsValue.yes)
        return Yup.string().when(
          "doesNotApplyWorkPN",
          ([doesNotApplyWorkPN], schema) => {
            if (!doesNotApplyWorkPN)
              return Yup.string()
                .matches(/^\d{10}$/, "Must be a 10-digit number")
                .required("Required")
                .trim();
            return schema;
          }
        );
      return schema;
    }
  ),
  doesNotApplyWorkPN: Yup.bool(),
  emailArray: Yup.array().when("anyOtherEmail", ([anyOtherEmail], schema) => {
    if (anyOtherEmail === GenericYesNoOptionsValue.yes)
      return Yup.array().of(
        Yup.object().shape({
          additionalEmail: Yup.string()
            .email()
            .required("Required")
            .trim()
            .label("Additional email"),
        })
      );
    return schema;
  }),

  anyOtherPhoneNumber: Yup.string().required("Required").trim(),
  phoneNumberArray: Yup.array().when(
    "anyOtherPhoneNumber",
    ([anyOtherPhoneNumber], schema) => {
      if (anyOtherPhoneNumber === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            additionalPhoneNumber: Yup.string()
              .matches(/^\d{10}$/, "Must be a 10-digit number")
              .required("Required")
              .trim()
              .label("Additional phone number"),
          })
        );
      return schema;
    }
  ),
  anyOtherEmail: Yup.string().required("Required").trim(),
  socialMediaArray: Yup.array().of(
    Yup.object().shape({
      socialMedia: Yup.object().nullable().required("Required"),
      socialMediaIdentifier: Yup.string().required("Required").trim(),
    })
  ),
  presenceOnWebOrApp: Yup.string().required("Required").trim(),
  // additionalSocialMedia: Yup.string().when(
  //   "presenceOnWebOrApp",
  //   ([presenceOnWebOrApp], schema) => {
  //     if (presenceOnWebOrApp === GenericYesNoOptionsValue.yes)
  //       return Yup.string().required("Required").trim();
  //     return schema;
  //   }
  // ),
  //
  email: Yup.string().email().required("Required").trim(),
  // additionalSocialMediaHandle: Yup.string().when(
  //   "presenceOnWebOrApp",
  //   ([presenceOnWebOrApp], schema) => {
  //     if (presenceOnWebOrApp === GenericYesNoOptionsValue.yes)
  //       return Yup.string().required("Required").trim();
  //     return schema;
  //   }
  // ),

  additionalSocialMediaArray: Yup.array().when(
    "presenceOnWebOrApp",
    ([presenceOnWebOrApp], schema) => {
      if (presenceOnWebOrApp === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            additionalSocialMedia: Yup.string().required("Required").trim(),
            additionalSocialMediaHandle: Yup.string()
              .required("Required")
              .trim(),
          })
        );

      return schema;
    }
  ),
});

// ! PASSPORT VALIDATION
export const PassportValidation = Yup.object().shape<
  SchemaObject<PassportData>
>({
  passportType: Yup.object().nullable().required("Required"),
  passportNumber: Yup.string().required("Required").trim(),
  passportBookNumber: Yup.string().when(
    "doesNotApply",
    ([doesNotApply], schema) => {
      if (!doesNotApply) return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  doesNotApply: Yup.bool(),
  country: Yup.object().nullable().required("Required"),
  city: Yup.string().required("Required").trim(),
  stateProvince: Yup.object().nullable(),
  countryOrRegion: Yup.object().nullable().required("Required"),
  issuanceDate: Yup.string().nullable().required("Required").trim(),
  expirationDate: Yup.string()
    .nullable()
    .required("Required")
    .trim()
    .test(
      "issuanceDate",
      "The expiration date should be six months ahead of today's date.",
      (value, context) => {
        const data = context.parent as PassportData;
        const issuanceDate = data.issuanceDate;
        const expirationDate = data.expirationDate;

        return issuanceDate && expirationDate
          ? dayjs(expirationDate).diff(dayjs(), "month") >= 6
          : false;
      }
    ),
  everLostPassport: Yup.string().required("Required").trim(),
  passportLostArray: Yup.array().when(
    "everLostPassport",
    ([everLostPassport], schema) => {
      if (everLostPassport === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            passportLostPassportNumber: Yup.string().when(
              ["passportLostPassportNumberDoNotKnow"],
              ([passportLostPassportNumberDoNotKnow], schema) => {
                if (!passportLostPassportNumberDoNotKnow)
                  return Yup.string().required("Required").trim();
                return schema;
              }
            ),
            passportLostCountry: Yup.object().nullable().required("Required"),
            passportLostExplain: Yup.string().required("Required").trim(),
            passportLostPassportNumberDoNotKnow: Yup.bool(),
          })
        );

      return schema;
    }
  ),
});

// ! US CONTACT VALIDATION
export const USCOntactValidation = Yup.object().shape<
  SchemaObject<USContactData>
>({
  surname: Yup.string().required("Required").trim(),
  givenNames: Yup.string().when("doesNotApply", ([doesNotApply], schema) => {
    if (!doesNotApply) return Yup.string().required("Required").trim();
    return schema;
  }),
  doesNotApply: Yup.bool(),
  organizationName: Yup.string().required("Required").trim(),
  relationshipToYou: Yup.object().nullable().required(),
  usStreetAddressLine1: Yup.string().required("Required").trim(),
  usStreetAddressLine2: Yup.string(),
  city: Yup.string().required("Required").trim(),
  state: Yup.object().nullable().required("Required"),
  zipCode: Yup.string()
    .trim()
    .length(5, "Maximum of 5 characters allowed")
    .test("Digits only", "Must be a number", optionalDigitsOnly),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, "Must be a 10-digit number")
    .required("Required")
    .trim(),
  emailAddress: Yup.string()
    .email()
    .email("Invalid email format")
    .when("emailDoesNotApply", ([emailDoesNotApply], schema) => {
      if (!emailDoesNotApply)
        return Yup.string().email().required("Required").trim();
      return schema;
    }),
  emailDoesNotApply: Yup.bool(),
});

export const FamilyRelativeValidation = Yup.object().shape<
  SchemaObject<FamilyData>
>({
  fatherSurname: Yup.string().required("Required").trim(),
  fatherGivenNames: Yup.string().required("Required").trim(),
  fatherDateOfBirth: Yup.string().nullable().required("Required"),
  isFatherInTheUs: Yup.string().required("Required").trim(),
  fatherStatus: Yup.object()
    .nullable()
    .when("isFatherInTheUs", ([isFatherInTheUs], schema) => {
      if (isFatherInTheUs === GenericYesNoOptionsValue.yes)
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  motherSurname: Yup.string().when(
    "doNotKnowSurname",
    ([doNotKnowSurname], schema) => {
      if (!doNotKnowSurname) return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  doNotKnowSurname: Yup.bool(),
  motherGivenNames: Yup.string().when(
    "doNotKnowGivenNames",
    ([doNotKnowGivenNames], schema) => {
      if (!doNotKnowGivenNames) return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  doNotKnowGivenNames: Yup.bool(),
  motherDateOfBirth: Yup.string().nullable().required("Required"),
  isMotherInTheUs: Yup.string().required("Required").trim(),
  motherStatus: Yup.object()
    .nullable()
    .when("isMotherInTheUs", ([isMotherInTheUs], schema) => {
      if (isMotherInTheUs === GenericYesNoOptionsValue.yes)
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  immediateRelativesInTheUs: Yup.string().required("Required"),
  relativesArray: Yup.array().when(
    "immediateRelativesInTheUs",
    ([immediateRelativesInTheUs], schema) => {
      if (immediateRelativesInTheUs === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            surname: Yup.string().required("Required").trim(),
            givenNames: Yup.string().required("Required").trim(),
            relativeStatus: Yup.object().nullable().required("Required"),
          })
        );
      return schema;
    }
  ),
  anyOtherRelatives: Yup.string(),
});

export const WETPresentValidation = Yup.object().shape<
  SchemaObject<WETPresentFormData>
>({
  primaryOccupation: Yup.object().nullable().required("Required"),
  presentEmployerOrSchoolName: Yup.string().required("Required").trim(),
  monthlyIncome: Yup.string().when("doesNotApply", ([doesNotApply], schema) => {
    if (!doesNotApply) return Yup.string().required("Required").trim();
    return schema;
  }),
  doesNotApply: Yup.bool(),
  streetAddressLine1: Yup.string().required("Required").trim(),
  city: Yup.string().required("Required").trim(),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, "Must be a 10-digit number")
    .required("Required")
    .trim(),
  countryOrRegion: Yup.object().nullable().required("Required"),
  stateOrProvince: Yup.object().nullable().required("Required"),
  zipCode: Yup.string().required("Required").trim(),
  startDate: Yup.string().nullable().required("Required"),
  streetAddressLine2: Yup.string().trim(),
  dutiesDescription: Yup.string().required("Required").trim(),
});

export const WETPreviousValidation = Yup.object().shape<
  SchemaObject<WETPreviousFormData>
>({
  wereYouPreviouslyEmployed: Yup.string().required("Required").trim(),
  PrevEmpArray: Yup.array().when(
    "wereYouPreviouslyEmployed",
    ([wereYouPreviouslyEmployed], schema) => {
      if (wereYouPreviouslyEmployed === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            employerName: Yup.string().required("Required").trim(),
            employerStreetAddressLine1: Yup.string()
              .required("Required")
              .trim(),
            employerStreetAddressLine2: Yup.string().trim(),
            city: Yup.string().required("Required").trim(),
            stateOrProvince: Yup.object().nullable().required("Required"),
            zipCode: Yup.string().required("Required").trim(),
            phoneNumber: Yup.string()
              .matches(/^\d{10}$/, "Must be a 10-digit number")
              .required("Required")
              .trim(),
            countryOrRegion: Yup.object().nullable().required("Required"),
            jobTitle: Yup.string().required("Required").trim(),
            supervisorSurname: Yup.string().when(
              "doesNotApplySurname",
              ([doesNotApplySurname], schema) => {
                if (!doesNotApplySurname)
                  return Yup.string().required("Required").trim();
                return schema;
              }
            ),
            doesNotApplySurname: Yup.bool(),
            supervisorGivenNames: Yup.string().when(
              "doesNotApplyGivenNames",
              ([doesNotApplyGivenNames], schema) => {
                if (!doesNotApplyGivenNames)
                  return Yup.string().required("Required").trim();
                return schema;
              }
            ),
            doesNotApplyGivenNames: Yup.bool(),
            employmentDateFrom: Yup.string()
              .nullable()
              .required("Required")
              .trim(),
            employmentDateTo: Yup.string()
              .nullable()
              .required("Required")
              .test(
                "employment-from-to-date",
                "This date cannot be before Employment Date from",
                (value, context) => {
                  const { parent } = context as Yup.TestContext &
                    ValidateOptionsExtended;

                  const data = parent as PrevEmpArrayPropsPayload;
                  const fromDateValue = data.employmentDateFrom;

                  const toDateValue = data.employmentDateTo;

                  const fromDate = dayjs(fromDateValue);
                  const toDate = dayjs(toDateValue);

                  return fromDateValue && toDateValue
                    ? !toDate.isBefore(fromDate)
                    : true;
                }
              ),
            dutiesDescription: Yup.string().required("Required").trim(),
          })
        );
      return schema;
    }
  ),
  attendedAnyEduInstitution: Yup.string().required("Required").trim(),
  EduArray: Yup.array().when(
    "attendedAnyEduInstitution",
    ([attendedAnyEduInstitution], schema) => {
      if (attendedAnyEduInstitution === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            nameOfInstitute: Yup.string().required("Required").trim(),
            instituteStreetAddressLine1: Yup.string()
              .required("Required")
              .trim(),
            instituteStreetAddressLine2: Yup.string().trim(),
            instituteCity: Yup.string().required("Required").trim(),
            instituteStateOrProvince: Yup.object()
              .nullable()
              .required("Required"),
            instituteZipCode: Yup.string().required("Required").trim(),
            country: Yup.object().nullable().required("Required"),
            courseOfStudy: Yup.string().required("Required").trim(),
            dateOfAttendanceFrom: Yup.string()
              .nullable()
              .required("Required")
              .trim(),
            dateOfAttendanceTo: Yup.string()
              .nullable()
              .required("Required")
              .trim()
              .test(
                "education-from-to-date",
                "This date cannot be before Date of Attendance from",
                (value, context) => {
                  const { parent } = context as Yup.TestContext &
                    ValidateOptionsExtended;

                  const data = parent as EduArrayPropsPayload;
                  const fromDateValue = data.dateOfAttendanceFrom;

                  const toDateValue = data.dateOfAttendanceTo;

                  const fromDate = dayjs(fromDateValue);
                  const toDate = dayjs(toDateValue);

                  return fromDateValue && toDateValue
                    ? !toDate.isBefore(fromDate)
                    : true;
                }
              ),
          })
        );
      return schema;
    }
  ),
});

const travelToCountriesOrRegionsTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  formEligibility: DS160EligibilityPayload
) => {
  const data = context.parent as WETAdditionalFormData;

  if (
    WETATravelledLastFiveYearsCondition(data, formEligibility) &&
    !data.travelToCountriesOrRegions_risky_checkbox
  ) {
    return false; //not valid
  } else {
    return true;
  }
};

export const WETAdditionalValidation = (
  formEligibility: DS160EligibilityPayload
) =>
  Yup.object().shape<SchemaObject<WETAdditionalFormData>>({
    belongToClanOrTribe: Yup.string().required("Required").trim(),
    tribeName: Yup.string().when(
      ["belongToClanOrTribe"],
      ([belongToClanOrTribe], schema) => {
        if (belongToClanOrTribe === GenericYesNoOptionsValue.yes)
          return Yup.string().required("Required").trim();
        return schema;
      }
    ),
    LangArray: Yup.array().when(
      "belongToClanOrTribe",
      ([belongToClanOrTribe], schema) => {
        if (belongToClanOrTribe === GenericYesNoOptionsValue.yes)
          return Yup.array().of(
            Yup.object().shape({
              languages: Yup.string().required("Required").trim(),
            })
          );
        return schema;
      }
    ),
    travelToCountriesOrRegions: Yup.string()
      .required("Required")
      .trim()
      .test("spouseUSTestVal", "Risk detected", (value, context) => {
        return travelToCountriesOrRegionsTestVal(context, formEligibility);
      }),
    travelToCountriesOrRegions_risky_checkbox: Yup.bool(),
    CountryArray: Yup.array().when(
      "travelToCountriesOrRegions",
      ([travelToCountriesOrRegions], schema) => {
        if (travelToCountriesOrRegions === GenericYesNoOptionsValue.yes)
          return Yup.array().of(
            Yup.object().shape({
              countryOrRegion: Yup.object().nullable().required("Required"),
            })
          );
        return schema;
      }
    ),
    belongToCharitableOrg: Yup.string().required("Required").trim(),
    OrgArray: Yup.array().when(
      "belongToCharitableOrg",
      ([belongToCharitableOrg], schema) => {
        if (belongToCharitableOrg === GenericYesNoOptionsValue.yes)
          return Yup.array().of(
            Yup.object().shape({
              organizationName: Yup.string().required("Required").trim(),
            })
          );
        return schema;
      }
    ),
    anySpecialisedSkills: Yup.string().required("Required").trim(),
    skillExplanation: Yup.string().when(
      ["anySpecialisedSkills"],
      ([anySpecialisedSkills], schema) => {
        if (anySpecialisedSkills === GenericYesNoOptionsValue.yes)
          return Yup.string().required("Required").trim();
        return schema;
      }
    ),
    everServedInTheMilitary: Yup.string().required("Required").trim(),
    MilitaryArray: Yup.array().when(
      "everServedInTheMilitary",
      ([everServedInTheMilitary], schema) => {
        if (everServedInTheMilitary === GenericYesNoOptionsValue.yes)
          return Yup.array().of(
            Yup.object().shape({
              militaryCountryOrRegion: Yup.object()
                .nullable()
                .required("Required"),
              branchOfService: Yup.string().required("Required"),
              rank: Yup.string().required("Required"),
              militarySpecialty: Yup.string().required("Required"),
              dateOfServiceFrom: Yup.string().nullable().required("Required"),
              dateOfServiceTo: Yup.string()
                .nullable()
                .required("Required")
                .test(
                  "military-from-to-date",
                  "This date cannot be before Date of Service From",
                  (value, context) => {
                    const { parent } = context as Yup.TestContext &
                      ValidateOptionsExtended;

                    const data = parent as MilitaryArrayPropsPayload;
                    const fromDateValue = data.dateOfServiceFrom;

                    const toDateValue = data.dateOfServiceTo;

                    const fromDate = dayjs(fromDateValue);
                    const toDate = dayjs(toDateValue);

                    return fromDateValue && toDateValue
                      ? !toDate.isBefore(fromDate)
                      : true;
                  }
                ),
            })
          );
        return schema;
      }
    ),
  });

const testSecurityVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  value: string,
  isNo?: boolean
) => {
  const { options, parent } = context as Yup.TestContext &
    ValidateOptionsExtended;
  const key = options.key + riskyCheckBoxName;
  return (isNo
    ? value === GenericYesNoOptionsValue.no
    : value === GenericYesNoOptionsValue.yes) && !parent[key]
    ? false
    : true;
};

export const SecurityBackgroundP1Validation = Yup.object().shape<
  SchemaObject<saveDS160SecurityBackgroundP1Obj>
>({
  partOneI: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartOneI: Yup.string().when(["partOneI"], ([partOneI], schema) => {
    if (partOneI === GenericYesNoOptionsValue.yes)
      return Yup.string().required("Required").trim();
    return schema;
  }),
  partOneII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartOneII: Yup.string().when(
    ["partOneII"],
    ([partOneII], schema) => {
      if (partOneII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partOneIII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartOneIII: Yup.string().when(
    ["partOneIII"],
    ([partOneIII], schema) => {
      if (partOneIII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
});

export const SecurityBackgroundP2Validation = Yup.object().shape<
  SchemaObject<saveDS160SecurityBackgroundP2Obj>
>({
  partTwoI: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartTwoI: Yup.string().when(["partTwoI"], ([partTwoI], schema) => {
    if (partTwoI === GenericYesNoOptionsValue.yes)
      return Yup.string().required("Required").trim();
    return schema;
  }),
  partTwoII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartTwoII: Yup.string().when(
    ["partTwoII"],
    ([partTwoII], schema) => {
      if (partTwoII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partTwoIII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartTwoIII: Yup.string().when(
    ["partTwoIII"],
    ([partTwoIII], schema) => {
      if (partTwoIII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partTwoIV: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartTwoIV: Yup.string().when(
    ["partTwoIV"],
    ([partTwoIV], schema) => {
      if (partTwoIV === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partTwoV: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartTwoV: Yup.string().when(["partTwoV"], ([partTwoV], schema) => {
    if (partTwoV === GenericYesNoOptionsValue.yes)
      return Yup.string().required("Required").trim();
    return schema;
  }),
  partTwoVI: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartTwoVI: Yup.string().when(
    ["partTwoVI"],
    ([partTwoVI], schema) => {
      if (partTwoVI === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partTwoVII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartTwoVII: Yup.string().when(
    ["partTwoVII"],
    ([partTwoVII], schema) => {
      if (partTwoVII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
});

export const SecurityBackgroundP3Validation = Yup.object().shape<
  SchemaObject<saveDS160SecurityBackgroundP3Obj>
>({
  partThreeI: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeI: Yup.string().when(
    ["partThreeI"],
    ([partThreeI], schema) => {
      if (partThreeI === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeII: Yup.string().when(
    ["partThreeII"],
    ([partThreeII], schema) => {
      if (partThreeII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeIII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeIII: Yup.string().when(
    ["partThreeIII"],
    ([partThreeIII], schema) => {
      if (partThreeIII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeIV: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeIV: Yup.string().when(
    ["partThreeIV"],
    ([partThreeIV], schema) => {
      if (partThreeIV === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeV: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeV: Yup.string().when(
    ["partThreeV"],
    ([partThreeV], schema) => {
      if (partThreeV === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeVI: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeVI: Yup.string().when(
    ["partThreeVI"],
    ([partThreeVI], schema) => {
      if (partThreeVI === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeVII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeVII: Yup.string().when(
    ["partThreeVII"],
    ([partThreeVII], schema) => {
      if (partThreeVII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeVIII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeVIII: Yup.string().when(
    ["partThreeVIII"],
    ([partThreeVIII], schema) => {
      if (partThreeVIII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeIX: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeIX: Yup.string().when(
    ["partThreeIX"],
    ([partThreeIX], schema) => {
      if (partThreeIX === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeX: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeX: Yup.string().when(
    ["partThreeX"],
    ([partThreeX], schema) => {
      if (partThreeX === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partThreeXI: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartThreeXI: Yup.string().when(
    ["partThreeXI"],
    ([partThreeXI], schema) => {
      if (partThreeXI === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
});

export const SecurityBackgroundP4Validation = Yup.object().shape<
  SchemaObject<saveDS160SecurityBackgroundP4Obj>
>({
  partFourI: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartFourI: Yup.string().when(
    ["partFourI"],
    ([partFourI], schema) => {
      if (partFourI === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partFourII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartFourII: Yup.string().when(
    ["partFourII"],
    ([partFourII], schema) => {
      if (partFourII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
});

export const SecurityBackgroundP5Validation = Yup.object().shape<
  SchemaObject<saveDS160SecurityBackgroundP5Obj>
>({
  partFiveI: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartFiveI: Yup.string().when(
    ["partFiveI"],
    ([partFiveI], schema) => {
      if (partFiveI === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partFiveII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartFiveII: Yup.string().when(
    ["partFiveII"],
    ([partFiveII], schema) => {
      if (partFiveII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  partFiveIII: Yup.string()
    .required("Required")
    .trim()
    .test("security", "Risk detected", (value, context) => {
      return testSecurityVal(context, value);
    }),
  explanationPartFiveIII: Yup.string().when(
    ["partFiveIII"],
    ([partFiveIII], schema) => {
      if (partFiveIII === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
});

export const StudentExchangeVisaAdditionalValidation = Yup.object().shape<
  SchemaObject<StudentExchangeVisaAdditionalData>
>({
  ContactArray: Yup.array().of(
    Yup.object().shape({
      surname: Yup.string().required("Required").trim(),
      givenNames: Yup.string().required("Required").trim(),
      streetAddressLine1: Yup.string().required("Required").trim(),
      streetAddressLine2: Yup.string().trim(),
      city: Yup.string().required("Required").trim(),
      stateOrProvince: Yup.object()
        .nullable()
        .when(
          "doesNotApplyStateOrProvince",
          ([doesNotApplyStateOrProvince], schema) => {
            if (!doesNotApplyStateOrProvince)
              return Yup.object().nullable().required("Required");
            return schema;
          }
        ),
      doesNotApplyStateOrProvince: Yup.bool(),
      zipCode: Yup.string().when(
        "doesNotApplyZipCode",
        ([doesNotApplyZipCode], schema) => {
          if (!doesNotApplyZipCode) return Yup.string().required("Required");
          return schema;
        }
      ),
      doesNotApplyZipCode: Yup.bool(),
      countryOrRegion: Yup.object().nullable().required("Required"),
      telephoneNumber: Yup.string().when(
        "doesNotApplyTelephoneNumber",
        ([doesNotApplyTelephoneNumber], schema) => {
          if (!doesNotApplyTelephoneNumber)
            return Yup.string().required("Required");
          return schema;
        }
      ),
      doesNotApplyTelephoneNumber: Yup.bool(),
      emailAddress: Yup.string()
        .email()
        .when(
          "doesNotApplyEmailAddress",
          ([doesNotApplyEmailAddress], schema) => {
            if (!doesNotApplyEmailAddress)
              return Yup.string().email().required("Required");
            return schema;
          }
        ),
      doesNotApplyEmailAddress: Yup.bool(),
    })
  ),
});

export const StudentExchangeVisaSEVISValidation = Yup.object().shape<
  SchemaObject<StudentExchangeVisaSEVISFormData>
>({
  sevisID: Yup.string()
    .matches(/^N\d{10}$/, "Invalid SEVIS ID")
    .required("Required")
    .trim(),
  nameOfSchool: Yup.string().required("Required").trim(),
  courseOfStudy: Yup.object().nullable().required("Required"),
  streetAddressLine1: Yup.string().required("Required").trim(),
  streetAddressLine2: Yup.string().trim(),
  city: Yup.string().required("Required").trim(),
  stateOrProvince: Yup.object().nullable().required("Required"),
  zipCode: Yup.string().required("Required").trim(),
});

const dependsOnMaritalStatus = (isObj?: boolean, isDate?: boolean) => {
  if (isObj) {
    return Yup.object()
      .nullable()
      .when(["maritalStatus"], ([maritalStatus], schema) => {
        if (
          maritalStatus?.value &&
          maritalStatus?.value !== DS160MaritalStatusValues.SINGLE
        )
          return Yup.object().nullable().required("Required");
        return schema;
      });
  } else if (isDate) {
    return Yup.string()
      .nullable()
      .when(["maritalStatus"], ([maritalStatus], schema) => {
        if (
          maritalStatus?.value &&
          maritalStatus?.value !== DS160MaritalStatusValues.SINGLE
        )
          return Yup.string().nullable().required("Required").trim();
        return schema;
      });
  }

  return Yup.string().when(["maritalStatus"], ([maritalStatus], schema) => {
    if (
      maritalStatus?.value &&
      maritalStatus?.value !== DS160MaritalStatusValues.SINGLE
    )
      return Yup.string().required("Required").trim();
    return schema;
  });
};

const dependsOnPersonAddress = (isObj?: boolean) => {
  if (isObj) {
    return Yup.object()
      .nullable()
      .when("personAddress", ([personAddress], schema) => {
        if (personAddress?.value === PersonAddressOptionsValues.other)
          return Yup.object().nullable().required("Required");
        return schema;
      });
  } else
    return Yup.string().when("personAddress", ([personAddress], schema) => {
      if (personAddress?.value === PersonAddressOptionsValues.other)
        return Yup.string().required("Required").trim();
      return schema;
    });
};

export const MaritalStatusValidation = Yup.object().shape<
  SchemaObject<MaritalStatusFormData>
>({
  maritalStatus: Yup.object().nullable().required("Required"),
  personSurname: dependsOnMaritalStatus(),
  personGivenName: dependsOnMaritalStatus(),
  personDateOfBirth: dependsOnMaritalStatus(false, true),
  personCountry: dependsOnMaritalStatus(true, false),
  personCity: Yup.string().when(
    ["maritalStatus", "personCityDoNotKnow"],
    ([maritalStatus, personCityDoNotKnow], schema) => {
      if (
        maritalStatus?.value &&
        maritalStatus?.value !== DS160MaritalStatusValues.SINGLE &&
        !personCityDoNotKnow
      )
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  personCityDoNotKnow: Yup.bool(),
  personBirthCountry: dependsOnMaritalStatus(true, false),
  personAddress: dependsOnMaritalStatus(true, false),
  personAddressStreetAddressLine1: dependsOnPersonAddress(),
  personAddressStreetAddressLine2: Yup.string().trim(),
  personAddressCity: dependsOnPersonAddress(),
  personAddressState: Yup.object()
    .nullable()
    .when(
      ["personAddress", "doesNotApplyPersonAddressState"],
      ([personAddress, doesNotApplyPersonAddressState], schema) => {
        if (
          personAddress?.value === PersonAddressOptionsValues.other &&
          !doesNotApplyPersonAddressState
        )
          return Yup.object().nullable().required("Required");
        return schema;
      }
    ),
  doesNotApplyPersonAddressState: Yup.bool(),
  personAddressZipCode: Yup.string().when(
    ["personAddress", "doesNotApplyPersonAddressZipCode"],
    ([personAddress, doesNotApplyPersonAddressZipCode], schema) => {
      if (
        personAddress?.value === PersonAddressOptionsValues.other &&
        !doesNotApplyPersonAddressZipCode
      )
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  doesNotApplyPersonAddressZipCode: Yup.bool(),
  personAddressCountry: dependsOnPersonAddress(true),
});

import React, { FC } from "react";
import Constaint from "../layout/components/Constaint";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import IMG02 from "../../../images/web/happy-group.png";
import IMG03 from "../../../images/web/about-image.png";
import { motion } from "framer-motion";
import Testmonial from "../landing page/components/Testmonial";
import { ReactComponent as Point } from "../../../images/icons/bullet-logo.svg";

const About: FC = () => {
  return (
    <div>
      <Constaint>
        <AboutContents>
          <AboutTop>
            <AboutTopTexts>
              <h4>our mission</h4>
              <p>
                Empowering individuals{" "}
                <span className="text-[#979696]">and</span> families{" "}
                <span className="text-[#979696]">to achieve their</span>{" "}
                <span className="text-[#0076E9]">immigration goals</span>{" "}
                <span className="text-[#979696]">through</span> efficient,
                affordable, and top-tier services.
              </p>
            </AboutTopTexts>
            <AboutTopImages>
              <AboutPre>
                <h1>about us</h1>
                <p>
                  At SeamlessVisa, we understand that your immigration story is
                  not just paperwork. In every immigration journey and the
                  pursuit of a better future, we understand that there are
                  emotions attached to every decision, every document, and every
                  detail.
                </p>
                <p>
                  SeamlessVisa takes a personalized approach, not just because
                  it's a strategy, but because it's a heartfelt commitment to
                  making your application process more than a transaction. We
                  are committed to empowering individuals and families worldwide
                  by simplifying and streamlining the U.S. visa application
                  process. We provide a user-friendly platform with step-by-step
                  guidance, that helps individuals navigate immigration
                  challenges with a simplified and accessible visa application
                  process. Our self-directed online immigration platform covers
                  various applications, from citizenship to student, and tourist
                  visas.
                </p>
              </AboutPre>
              <AboutTopImageTwo src={IMG02} />
            </AboutTopImages>
          </AboutTop>
          <AboutMain>
            <TopSection>
              <h1>Why We Do What We Do</h1>
              <p>We understand your journey. We've lived it.</p>
              <p className="text-[14px] w-full max-w-[700px] mx-auto text-justify md:text-center">
                SeamlessVisa isn't just a company, it's a promise. A promise
                born from the struggles we faced as immigrants ourselves. We
                spent years navigating the complex US immigration system,
                encountering hidden pitfalls and exorbitant attorney fees. It
                was frustrating, confusing, and often insurmountable.
              </p>
              <p className="text-[14px] w-full max-w-[700px] mx-auto text-justify md:text-center">
                But that's why we're here. SeamlessVisa was built by immigrants,
                for immigrants. We've faced the same anxieties, the same
                uncertainties, the same mountains of paperwork. We know the toll
                it takes, not just on your finances, but on your dreams.
              </p>
            </TopSection>
            <AboutMainContents>
              <LeftSection>
                <LeftPara>
                  That's why "The trusted partner in your immigration journey"
                  isn't just a tagline, it's our mission. We're committed to
                  making your immigration journey as smooth and stress-free as
                  possible. We do this by:
                </LeftPara>

                <LeftPara>
                  <Point />
                  <span>
                    Empowering you with our User-friendly software: Our
                    attorney-reviewed platform simplifies complex forms, guides
                    you step-by-step, and does rigorous error-checking to ensure
                    your application is flawless.
                  </span>
                </LeftPara>

                <LeftPara>
                  <Point />
                  <span>
                    Providing exceptional support: Our dedicated support team is
                    just a click away, to answer your questions and alleviate
                    your anxieties.
                  </span>
                </LeftPara>

                <LeftPara>
                  <Point />
                  <span>
                    Offering the best value for your money: Forget exorbitant
                    attorney fees. We provide unparalleled services for one low,
                    fixed price. We believe access to top-tier immigration
                    support shouldn't break the bank.
                  </span>
                </LeftPara>

                <LeftPara>
                  <Point />
                  <span>
                    Unwavering commitment to improvement: We constantly update
                    our platform and resources based on the latest USCIS
                    regulations and user feedback.
                  </span>
                </LeftPara>

                <LeftPara>
                  We're not just online immigration services, we're your
                  partners. We'll celebrate your victories, hold your hand
                  through setbacks, and be your unwavering advocates every step
                  of the way. Because at SeamlessVisa, your dream is our
                  mission. Let us walk beside you and make your immigration
                  journey a success story.
                </LeftPara>
              </LeftSection>
              <RightSection
                initial="initial"
                whileHover="animate"
                animate="initial"
              >
                <RightImage src={IMG03} />
                {/* <OverlayText variants={AboutTextVariant}>
                We value honesty, integrity, and transparency in all aspects of
                our work.{" "}
              </OverlayText> */}
              </RightSection>
            </AboutMainContents>
          </AboutMain>
          <Testmonial />
        </AboutContents>
      </Constaint>
    </div>
  );
};

export default About;

const AboutContents = styled.div``;
// const LargeText = styled.div`
//   text-align: center;

//   & > h1 {
//     font-size: 2.5rem;
//     font-weight: bold;

//     & > span {
//       color: ${Colors.Blue00};
//     }
//   }

//   @media screen and (min-width: 768px) {
//     text-align: left;

//     & > h1 {
//       font-size: 3.5rem;
//     }
//   }
// `;

const AboutTop = styled.div`
  padding: 2rem 1rem;
`;

const AboutTopTexts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 0 auto 3rem;

  gap: 8px;

  & > h4 {
    font-size: 1rem;
    text-align: center;
    text-transform: capitalize;

    color: ${Colors.Blue00};

    & > span {
      opacity: 0.5;
    }
  }

  & > p {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    /* flex-direction: row; */

    & > h1 {
      text-align: left;
      font-size: 3.5rem;
    }

    & > p {
      width: 65%;
    }
  }
`;

const AboutTopImages = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 auto 3rem;

  gap: 24px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  & > img {
    border-radius: 8px;
    object-fit: cover;

    @media screen and (min-width: 768px) {
      height: 400px;
    }
  }
`;

// const AboutTopImageOne = styled.img`
//   /* flex: 0.6; */
//   @media screen and (min-width: 768px) {
//     width: 60%;
//   }
// `;

const AboutTopImageTwo = styled.img`
  /* flex: 0.4; */
  @media screen and (min-width: 768px) {
    width: 42.5%;
  }
`;

const AboutPre = styled.div`
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
  & > h1 {
    color: ${Colors.Blue00};
    text-transform: uppercase;
    font-size: 1rem;

    margin-bottom: 1.5rem;
  }

  & > p {
    margin: 0 0 1rem 0;

    @media screen and (min-width: 768px) {
      width: 80%;
    }
  }
`;

const AboutMain = styled.section``;

const TopSection = styled(motion.div)`
  text-align: center;

  & > h1 {
    font-size: 2.5rem;
    font-weight: bold;

    margin-bottom: 1rem;
    color: ${Colors.Grey8B};
  }

  & > p {
    margin-bottom: 1.5rem;
  }
`;

const AboutMainContents = styled.div`
  display: flex;
  flex-direction: column-reverse;

  align-items: center;

  margin: 2rem auto 3rem;

  @media only screen and (min-width: 768px) {
    flex-direction: row-reverse;
    gap: 64px;
  }
`;

const LeftSection = styled.div`
  flex: 0.5;

  display: flex;
  flex-direction: column;
  gap: 26px;
  width: 100%;

  margin: 4rem auto 0;

  @media only screen and (min-width: 768px) {
    width: 50%;
    margin: 0 auto;
  }

  & > ul {
    list-style-type: square;

    & > li {
      margin-bottom: 1rem;

      &::marker {
        color: ${Colors.Blue00};
      }
    }
  }
`;

const LeftPara = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #31393c;

  text-align: justify;

  display: flex;
  align-items: start;
  gap: 8px;

  & > svg {
    margin-top: 6px;
  }

  & > span {
    flex: 1;
  }

  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

// const LeftBtnWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 24px;
// `;

// const LeftBtn = styled.button`
//   width: 243px;
//   height: 56px;
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     background: #022e58;
//     transition: 0.3s ease-in-out;
//   }
// `;

// const ConnectBtn = styled(LeftBtn)`
//   background-color: #0076e9;
//   color: #f9fcff;

//   text-transform: uppercase;
// `;

const RightSection = styled(motion.div)`
  flex: 0.5;
  position: relative;

  @media only screen and (min-width: 768px) {
    margin: 0;
    margin-left: 10%;

    /* text-align: left; */
  }

  & > img {
    /* height: 350px;
    width: 350px; */
    object-fit: contain;
    width: 100%;

    transition: 0.5s ease-out;

    margin: auto;

    @media only screen and (min-width: 768px) {
      object-fit: cover;
    }
  }

  &:hover {
    img {
      filter: brightness(70%);
    }
  }
`;

const RightImage = styled.img`
  position: relative;

  height: 509px;
  object-fit: cover;
  /* width: 100%; */
`;

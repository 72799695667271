import { DS160EligibilityPayload } from "../../../../api/ds160/types";
import CountryCodeEligibility from "./CountryCodeEligibility";
import CountryEligibility from "./CountryEligibility";
import Ds160InfoContent from "./Ds160InfoContent";
import { ECongratulationType } from "./modals/EligibilityModal";
import {
  GenericEligibilityYesNoValues,
  RenderAltCompProps,
  checkQuestionsProps,
} from "./types";

const infoContent = <Ds160InfoContent />;

const unlessExcempted = (
  <>
    To know more about exemption,{" "}
    <a
      href="https://www.cbp.gov/sites/default/files/assets/documents/2022-Mar/Six-Month%20Passport%20Validity%20Update%2020220316.pdf"
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: "underline",
      }}
    >
      click here
    </a>
  </>
);

export enum EQIds {
  q1 = 1,
  q2,
  q3,
  q4,
  q5,
  q6,
  q7,
  q8,
  q9,
  q10,
  q11,
  q12,
  q13,
  q14,
  q15,
  q16,
  q17,
  q18,
  q19,
  q20,
}

export enum DS160PrimaryReasonForVisitingLabels {
  "Vacation/Tourism" = "Vacation/Tourism",
  "Business trip" = "Business trip",
  "To study" = "To study",
  "None" = "None",
}

export const checkFormDS160dataQuestions: checkQuestionsProps<
  keyof DS160EligibilityPayload
>[] = [
  {
    id: EQIds.q1,
    title: "What is your primary reason for visiting the U.S.?",
    storeField: "primary_reason_for_visiting_us",
    options: [
      {
        label: DS160PrimaryReasonForVisitingLabels["Vacation/Tourism"],
        nextStep: EQIds.q2,
      },
      {
        label: DS160PrimaryReasonForVisitingLabels["Business trip"],
        nextStep: EQIds.q2,
      },
      {
        label: DS160PrimaryReasonForVisitingLabels["To study"],
        nextStep: EQIds.q2,
      },
      {
        label: DS160PrimaryReasonForVisitingLabels["None"],
        terminate: {
          type: ECongratulationType.info,
          infoContent,
        },
      },
    ],
  },
  {
    id: EQIds.q2,
    title: "Where are you applying from?",
    storeField: "where_are_applying_from",
    singleOption: {
      renderAltComp: (data: RenderAltCompProps) => (
        <CountryEligibility
          formData={data.formData}
          onChange={data.onChange}
          result={data.result}
        />
      ),
      nextStep: EQIds.q3,
    },
  },
  {
    id: EQIds.q3,
    title: "Select the country code that matches the one on your passport.",
    storeField: "select_the_country_code",
    singleOption: {
      renderAltComp: (data: RenderAltCompProps) => (
        <CountryCodeEligibility
          formData={data.formData}
          onChange={data.onChange}
          result={data.result}
        />
      ),
      nextStep: EQIds.q4,
    },
  },
  {
    id: EQIds.q4,
    title: "",
    getTitle: (formData) => {
      const data = formData as DS160EligibilityPayload;
      return `Are you applying from ${data.where_are_applying_from}`;
    },
    storeField: "are_you_applying_from_country",
    options: [
      {
        label: "Yes",
        // nextStep: EQIds.q6,
        dynamicNextStep: (formData?: any) => {
          const data = formData as DS160EligibilityPayload;
          return data.primary_reason_for_visiting_us ===
            DS160PrimaryReasonForVisitingLabels["To study"]
            ? EQIds.q17
            : EQIds.q6;
        },
      },
      {
        label: "No",
        nextStep: EQIds.q5,
      },
    ],
  },
  {
    id: EQIds.q5,
    title: "Where will you apply for your visa?",
    storeField: "where_will_you_apply_for_your_visa",
    singleOption: {
      renderAltComp: (data: RenderAltCompProps) => (
        <CountryEligibility
          formData={data.formData}
          onChange={data.onChange}
          result={data.result}
        />
      ),
      dynamicNextStep: (formData?: any) => {
        const data = formData as DS160EligibilityPayload;
        return data.primary_reason_for_visiting_us ===
          DS160PrimaryReasonForVisitingLabels["To study"]
          ? EQIds.q17
          : EQIds.q6;
      },
    },
  },
  {
    id: EQIds.q6,
    title: "",
    getTitle: (formData) => {
      const data = formData as DS160EligibilityPayload;

      return `Do you either have a job, a spouse or own a property in ${data.where_are_applying_from}`;
    },
    storeField: "have_a_job_spouse_or_property_in_country",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q7,
      },
      {
        label: "No",
        nextStep: EQIds.q7,
      },
    ],
  },
  {
    id: EQIds.q7,
    title: "",
    getTitle: (formData) => {
      const data = formData as DS160EligibilityPayload;

      return `In the past have you traveled internationally and returned to ${data.where_are_applying_from}`;
    },
    storeField:
      "have_you_travelled_internationally_in_the_past_and_returned_to_country",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q8,
      },
      {
        label: "No",
        nextStep: EQIds.q8,
      },
    ],
  },
  {
    id: EQIds.q8,
    title: "Are you visiting friends or relatives in the U.S?",
    storeField: "are_you_visiting_friends_or_relatives_in_the_us",
    options: [
      {
        label: "Yes",
        dynamicNextStep: (formData?: any) => {
          const data = formData as DS160EligibilityPayload;
          return data.primary_reason_for_visiting_us ===
            DS160PrimaryReasonForVisitingLabels["Business trip"]
            ? EQIds.q2
            : EQIds.q9;
        },
      },
      {
        label: "No",
        dynamicNextStep: (formData?: any) => {
          const data = formData as DS160EligibilityPayload;
          return data.primary_reason_for_visiting_us ===
            DS160PrimaryReasonForVisitingLabels["Business trip"]
            ? EQIds.q16
            : EQIds.q9;
        },
      },
    ],
  },
  {
    id: EQIds.q9,
    title:
      "Do you have any immediate family members (parents, siblings, children) who are US citizens or permanent residents?",
    storeField:
      "have_any_immediate_family_members_who_are_us_citizens_or_permanent_residents",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q10,
      },
      {
        label: "No",
        nextStep: EQIds.q10,
      },
    ],
  },
  {
    id: EQIds.q10,
    title: "",
    getTitle: (formData) => {
      const data = formData as DS160EligibilityPayload;
      return `Have you traveled to any other countries in the past 5 years, aside from ${data.where_are_applying_from}`;
    },
    storeField:
      "have_you_traveled_to_any_other_countries_in_the_past_five_years_aside_country",
    options: [
      {
        label: GenericEligibilityYesNoValues.Yes,
        nextStep: EQIds.q11,
      },
      {
        label: GenericEligibilityYesNoValues.No,
        nextStep: EQIds.q12,
      },
    ],
  },
  {
    id: EQIds.q11,
    title: "Do you have U.S or Canada or U.K Visa?",
    storeField: "do_you_have_us_or_canada_uk_visa",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q12,
      },
      {
        label: "No",
        nextStep: EQIds.q12,
      },
    ],
  },

  {
    id: EQIds.q12,
    title: "Have you been denied a travel visa to any country before?",
    storeField: "have_you_been_denied_a_travel_visa_to_any_country",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q13,
      },
      {
        label: "No",
        nextStep: EQIds.q13,
      },
    ],
  },
  {
    id: EQIds.q13,
    title:
      "Have you been denied a travel visa to the U.S in the last 90 days or 6 months?",
    storeField:
      "have_you_been_denied_a_travel_visa_to_the_us_in_the_last_six_months",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q14,
      },
      {
        label: "No",
        nextStep: EQIds.q14,
      },
    ],
  },
  {
    id: EQIds.q14,
    title:
      "Do you have a valid passport with a validity date extending at least 6 months beyond your intended stay, unless exempted",
    storeField:
      "do_you_have_valid_passport_with_validity_date_extending_at_least_six_months_beyond_intended_stay",
    options: [
      {
        label: "Yes",
        dynamicNextStep: (formData?: any) => {
          const data = formData as DS160EligibilityPayload;
          return data.primary_reason_for_visiting_us !==
            DS160PrimaryReasonForVisitingLabels["To study"]
            ? EQIds.q15
            : undefined;
        },
        terminate: {
          type: ECongratulationType.eligible, //will work just because dynamicStep comes nefore terminate in the function
        },
        note: unlessExcempted,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.info,
          infoContent,
        },
        note: unlessExcempted,
      },
    ],
  },
  {
    id: EQIds.q15,
    title:
      "Do you have enough funds to cover your travel, accommodation, and other expenses during your stay in the US?",
    storeField:
      "do_you_have_enough_funds_to_cover_your_travel_accommodation_and_educational_expenses_in_us",
    options: [
      {
        label: "Yes",
        dynamicNextStep: (formData?: any) => {
          const data = formData as DS160EligibilityPayload;
          return data.primary_reason_for_visiting_us ===
            DS160PrimaryReasonForVisitingLabels["To study"]
            ? (EQIds.q6 as number)
            : undefined;
        },
        terminate: {
          type: ECongratulationType.eligible, //will work just because dynamicStep comes nefore terminate in the function
        },
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.info,
          infoContent,
        },
      },
    ],
  },
  {
    id: EQIds.q16,
    title:
      "Are you traveling for a scientific, educational, professional or business convention, conference or meeting on specific dates?",
    storeField:
      "are_you_travelling_for_a_scientific_educational_professional_or_business_convention",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q9,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.info,
          infoContent,
        },
      },
    ],
  },
  {
    id: EQIds.q17,
    title:
      "Are you planning to attend a U.S.-based academic institution, including language training programs?",
    storeField:
      "are_you_planning_to_attend_us_based_academic_institution_including_language_training_programs",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q18,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.info,
          infoContent,
        },
      },
    ],
  },
  {
    id: EQIds.q18,
    title: "Have you been accepted into an SEVP-approved school in the U.S.?",
    storeField: "have_you_been_accepted_into_sevp_approved_school_in_us",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q19,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.info,
          infoContent,
        },
      },
    ],
  },
  {
    id: EQIds.q19,
    title:
      "Have you been issued an I-20 document by an SEVP-approved school in the U.S?",
    storeField:
      "have_you_been_issued_a_document_by_a_sevp_approved_school_in_us",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q15,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.info,
          infoContent,
        },
      },
    ],
  },
];

import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import ExtraNavItem from "./ExtraNavItem";
import { AnimatePresence } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import Colors from "../../../../styles/Colors";
import { useState } from "react";
import { ProfileNavProps } from "../../layout/components/data";

interface props {
  label: string;
  url?: string;
  children?: ProfileNavProps["children"];
}

const ProfileNavItem: React.FC<props> = ({ label, url, children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = pathname.split("/")[1];
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onNavigate = (url?: string) => {
    if (url) {
      navigate(url);
    }
    return;
  };

  return (
    <Wrapper
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <Link onClick={() => onNavigate(url)} isActive={url === path}>
        {label} {children && <IoIosArrowDown />}
      </Link>
      <AnimatePresence>
        {children && <ExtraNavItem data={children} onClose={onClose} />}
      </AnimatePresence>
    </Wrapper>
  );
};

export default ProfileNavItem;

interface WrapperProps {
  isOpen: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;

  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: unset;
  }

  .child-nav {
    visibility: ${({ isOpen }) => isOpen && "visible"};
    opacity: ${({ isOpen }) => isOpen && "1"};
    transition: ${({ isOpen }) => isOpen && " 0.3s ease-out"};
  }
`;

interface linkProps {
  isActive?: boolean;
}

const Link = styled.div<linkProps>`
  font-weight: 450;
  font-size: 1.1rem;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? Colors.Blue00 : "#31393C")};
  cursor: pointer;
  transition: all 0.2s ease-in;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    color: #0076e9;
    transition: 0.3s ease-in-out;
  }
`;

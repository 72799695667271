import React from "react";
import Hero from "./components/Hero";
import Offers from "./components/Offers";
import Steps from "./components/Steps";
import Process from "./components/Process";
import About from "./components/About";
import Testmonial from "./components/Testmonial";
import ChatSystem from "./components/ChatSystem";
// import Consultation from "./components/Consultation";
// import News from "./components/News";

const LandingPage = () => {
  return (
    <>
      <Hero />
      <Offers />
      <Steps />
      <Process />
      <About />
      <Testmonial />
      {/* <Consultation /> */}
      {/* <News /> */}
      <ChatSystem />
    </>
  );
};

export default LandingPage;

import React, { FC, useEffect } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { getFormatedFormName } from "./data";
import { CaseHistoryObj, N400FillingUrlObj } from "../../../types/types";
import { FormCategories } from "../../../routes/type";
import { DownloadFormsContentProps } from "./type";
import { WebPaths } from "../../../routes/data";
import { useNavigate } from "react-router-dom";
import { convertToLowercaseDashed } from "../../../utils";
import { usePDF } from "@react-pdf/renderer";
import MyDoc from "../form ds 160/components/Review/components/MyDoc";
import { GetDs160Obj } from "../../../api/ds160/types";
import Spinner from "../../../utils/Spinner";

interface Props extends DownloadFormsContentProps {
  data: CaseHistoryObj;
  headerText?: string;
  ds160InfoData?: GetDs160Obj;
  isToStudy?: boolean;
  isDs160?: boolean;
}

const DownloadFormsContent: FC<Props> = ({
  data,
  headerText,
  hideMainform,
  hideFilingForm,
  hideG1145Form,
  formType,
  ds160InfoData,
  isDs160,
  isToStudy,
}) => {
  const navigate = useNavigate();
  const [instance, updateInstance] = usePDF({
    document: (
      <MyDoc
        data={ds160InfoData}
        isToStudy={isToStudy === undefined ? true : isToStudy}
      />
    ),
  });

  const getFillingUrl = () => {
    if (data.caseType === FormCategories.n400) {
      return (
        (data.filingURL as N400FillingUrlObj).n400_marriage_greenURL ||
        (data.filingURL as N400FillingUrlObj).n400_filing_greenURL
      );
    } else return data.filingURL as string;
  };

  const onDocUpload = () => {
    navigate(
      `${WebPaths.Profile}?active=documents&form=${convertToLowercaseDashed(
        formType
      )}`
    );
  };

  useEffect(() => {
    updateInstance(
      <MyDoc
        data={ds160InfoData}
        isToStudy={isToStudy === undefined ? true : isToStudy}
      />
    );
  }, [ds160InfoData, isToStudy, updateInstance]);

  return (
    <ContentWrapper>
      <TextBold>
        {headerText || "Below are documents available for you to download:"}
      </TextBold>
      <DocumentFlexWrapper>
        {!hideMainform && (
          <DocumentFlex>
            <p>
              Completed <Texter>{getFormatedFormName(data.caseType)}</Texter>{" "}
              Form
            </p>
            {isDs160 ? (
              <a href={instance.url || ""} download="DS-160 Form.pdf">
                {instance.loading ? (
                  <Spinner size={16} color={Colors.Black31} />
                ) : (
                  "download"
                )}
              </a>
            ) : (
              <a href={data.formDownloadUrl}>download</a>
            )}
          </DocumentFlex>
        )}
        {!hideFilingForm && (
          <DocumentFlex>
            <p>Filing Instruction</p>
            <a href={getFillingUrl()}>download</a>
          </DocumentFlex>
        )}
        {!hideG1145Form && (
          <DocumentFlex>
            <p>
              G1145 form <TextRed>(optional)</TextRed>
            </p>
            <a href={data.g1145URL}>download</a>
          </DocumentFlex>
        )}
      </DocumentFlexWrapper>

      <Text>
        Please click the button below to continue to the next stage of{" "}
        <FormTypeBold>
          <span className="uppercase">{getFormatedFormName(formType)}</span>{" "}
          Document Upload
        </FormTypeBold>
        .
      </Text>

      <CTA onClick={onDocUpload}>continue</CTA>
    </ContentWrapper>
  );
};

export default DownloadFormsContent;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 1rem;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  /* font-weight: bold; */
  display: flex;
  align-items: center;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const DocumentFlexWrapper = styled.div`
  padding: 0 1rem;
`;

const DocumentFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;

  & > p {
    font-size: 0.85rem;
  }

  & > a {
    padding: 4px 11px;
    border: 1px solid;
    font-size: 0.75rem;
    border-radius: 4px;

    text-transform: capitalize;
    transition: 300ms all;

    &:hover {
      background-color: ${Colors.Blue00};
      color: #fff;
      border: none;
    }

    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    border-color: ${Colors.Black31};
    color: ${Colors.Black31};
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

const Texter = styled.span`
  text-transform: uppercase;
`;

const TextRed = styled.span`
  color: ${Colors.RedE3};
`;

const Btn = styled.button`
  width: max-content;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;
  margin: auto;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CTA = styled(Btn)`
  background: ${Colors.Blue00};
  color: ${Colors.WhiteF9};
  padding: 18.5px 47px;

  &:hover:not(:disabled) {
    background: #022e58;
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;

const FormTypeBold = styled(Text)`
  font-weight: bold;
`;

import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { HR, HeadFlex } from "./styles";
import FormProgressBar from "./FormProgressBar";

interface Props {
  title: string;
  subText: string;
  sectionComp: JSX.Element;
  subHead?: string;
  getType: string;
  enumVal: any;
}

const RightSectionWrapper: FC<Props> = ({
  title,
  subText,
  sectionComp,
  subHead,
  getType,
  enumVal,
}) => {
  return (
    <Container>
      <HeadFlex>
        <Header>{title}</Header>
        <FormProgressBar section={getType || ""} enumVal={enumVal} />
      </HeadFlex>
      <Line />
      {subHead && <SubHead>{subHead}</SubHead>}
      <Para>{subText}</Para>
      {sectionComp}
    </Container>
  );
};

export default RightSectionWrapper;

const Container = styled.div`
  width: 100%;
  padding: 20px 20px 47px 20px;
  background-color: ${Colors.WhiteF9};
  border-radius: 12px;

  input,
  textarea,
  .css-1ulr2sp-control {
    background-color: transparent;
  }
`;

const Line = styled(HR)`
  margin: 0;
  margin-bottom: 16px;
`;

const Header = styled.div`
  color: ${Colors.Blue00};
`;

const Para = styled.p`
  color: ${Colors.Black31};
  margin-bottom: 32px;
`;

const SubHead = styled.div`
  color: ${Colors.Black31};
  margin: 16px 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
`;

import React from "react";
import { ReactComponent as Quote } from "../../../../images/icons/quote.svg";
import { loginSlides } from "./data";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";

const AuthRightSession = () => {
  // CAROUSEL
  const autoplayOptions = {
    delay: 3000,
    // jump: false,
    // playOnInit: true,
    stopOnMouseEnter: false,
  };

  const [emblaRef] = useEmblaCarousel({ loop: false }, [
    Autoplay(autoplayOptions),
  ]);

  return (
    <div className="p-16 bg-[linear-gradient(180deg,#D1E8FF33_20.89%,#EAC5FC33_85.16%)] hidden lg:block flex-[0.5]">
      <div className="rightContainerContents max-w-[620px] flex flex-col justify-center items-start">
        <div className="text-section">
          <h1 className="my-2 text-3xl font-bold">
            Simplifying Immigration For You
          </h1>
          <p className="my-5  mb-[95px] text-[#6B6D70] max-w-[600px] text-lg font-[500]">
            Immigration made simple. Complete your Immigration application
            process with our user-friendly platform
          </p>
        </div>
        <div className="w-full slideContainer h-1/2">
          <div className="h-full embla rounded-xl" ref={emblaRef}>
            <div className="h-full embla__container">
              {loginSlides.map((slide, i) => (
                <div
                  key={i}
                  className={`embla__slide min-h-[250px] w-full even:bg-[#ffffff] even:text-[#31393C] odd:bg-[#31393C] odd:text-[#ffffff] rounded-xl relative p-5`}
                >
                  <Quote className="absolute top-[-5%] left-[5%] fill-[#0076E9]" />
                  <p className="mt-[10%] text-lg italic">{slide.testimony}</p>
                  <div className="flex gap-3 mt-8 profile-info">
                    {/* <div className="img">
                      <img
                        src={slide.img}
                        alt={slide.img}
                        className="w-[48px] h-[48px] rounded-full"
                      />
                    </div> */}
                    <div className="name-country">
                      <p className="text-xl capitalize">{slide.name}</p>

                      {/* <span className="text-[#A7AABD] text-[10px] font-bold uppercase">
                        {slide.country}
                      </span> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthRightSession;

import React, { FC } from "react";
import { saveDS160FamilyObj } from "../../../../../../api/ds160/types";
import ReviewBox, {
  // ReviewData,
  ReviewSectionChildrenData,
} from "../../../../components/Review/ReviewBox";
import { reviewDoNotKnow } from "../../../../components/data";
import { FormDS160Progress } from "../../types";
import { saveDS160MaritalStatusObj } from "../../FamilyRelatives/MaritalStatus";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160FamilyObj;
  maritalStatusData?: saveDS160MaritalStatusObj;
}

const ReviewFamily: FC<Props> = ({
  title,
  data,
  maritalStatusData,
  isDownload,
}) => {
  const sectionA: ReviewSectionChildrenData[] = [
    {
      label: " ",
      value: " ",
    },
    {
      section: {
        title: "Father's Full name and Date of birth",
        children: [
          {
            label: "Surname",
            value: data?.fatherSurname,
          },
          {
            label: "Given Name",
            value: data?.fatherGivenNames,
          },
          {
            label: "Date of Birth",
            value: data?.fatherDateOfBirth,
          },
          {
            label: "Is your father in the US?",
            value: data?.isFatherInTheUs,
          },
        ],
      },
    },
    {
      section: {
        title: "Mother's Full name and Date of birth",
        children: [
          {
            label: "Surname",
            value: reviewDoNotKnow(data?.motherSurname, data?.doNotKnowSurname),
          },
          {
            label: "Given Names",
            value: reviewDoNotKnow(
              data?.motherGivenNames,
              data?.doNotKnowGivenNames
            ),
          },
          {
            label: "Date of Birth",
            value: data?.motherDateOfBirth,
          },
          {
            label: "Is your mother in the US?",
            value: data?.isMotherInTheUs,
          },
          {
            label: "Mother's Status",
            value: data?.motherStatus,
          },
          {
            label:
              "Do you have any immediate relatives, not including parents, in the united states?",
            value: data?.immediateRelativesInTheUs,
          },
          {
            label: "Do you have any other relatives in the united states?",
            value: data?.anyOtherRelatives,
          },
          {
            section: {
              title: "Provide the following details",
              children: [
                {
                  label: "Surname",
                  value: data?.surname,
                },
                {
                  label: "Given Names",
                  value: data?.givenNames,
                },
                {
                  label: "Relative's Status",
                  value: data?.relativeStatus,
                },
              ],
            },
          },
        ],
      },
    },
  ];

  const sectionB: ReviewSectionChildrenData[] = [
    {
      label: "Marital status",
      value: maritalStatusData?.maritalStatus,
    },
    {
      label: "Surname",
      value: maritalStatusData?.personSurname,
    },
    {
      label: "Given Name",
      value: maritalStatusData?.personGivenName,
    },
    {
      label: "Date of birth",
      value: maritalStatusData?.personDateOfBirth,
    },
    {
      label: "Country/Region",
      value: maritalStatusData?.personCountry,
    },
    {
      label: "City",
      value: maritalStatusData?.personCity,
    },
    {
      label: "Birth Country",
      value: maritalStatusData?.personBirthCountry,
    },
    {
      label: "Address",
      value: maritalStatusData?.personAddress,
    },
    {
      label: "Street Address (Line 1)",
      value: maritalStatusData?.personAddressStreetAddressLine1,
    },
    {
      label: "Street Address (Line 2)",
      value: maritalStatusData?.personAddressStreetAddressLine2,
    },
    {
      label: "City",
      value: maritalStatusData?.personAddressCity,
    },
    {
      label: "Country",
      value: maritalStatusData?.personAddressCountry,
    },
    {
      label: "State",
      value: maritalStatusData?.personAddressState,
    },
    {
      label: "Postal Zone/ZIP Code",
      value: maritalStatusData?.personAddressZipCode,
    },
  ];

  const section = [
    {
      section: {
        title: "Relatives",
        children: sectionA,
      },
    },
    {
      section: {
        title: "Marital Status",
        children: sectionB,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.familyRelatives,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewFamily;

import React, { FC } from "react";
import {
  saveDS160SEVAdditionalContactObj,
  saveDS160SivesObj,
} from "../../../../../../api/ds160/types";
import ReviewBox, {
  ReviewData,
  ReviewSectionChildrenData,
} from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: {
    additionalContact: saveDS160SEVAdditionalContactObj;
    sevis: saveDS160SivesObj;
  };
}

const ReviewSEV: FC<Props> = ({ title, data, isDownload }) => {
  const sectionA: ReviewSectionChildrenData[] = [
    {
      label: "Surname",
      value: data?.additionalContact?.surname,
    },
    {
      label: "Given Names",
      value: data?.additionalContact?.givenNames,
    },
    {
      label: "Street Address Line 1",
      value: data?.additionalContact?.streetAddressLine1,
    },
    {
      label: "Street Address Line 2 (Optional)",
      value: data?.additionalContact?.streetAddressLine2,
    },
    {
      label: "City",
      value: data?.additionalContact?.city,
    },
    {
      label: "State/Province",
      value: data?.additionalContact?.stateOrProvince,
    },
    {
      label: "Postal Code/Zip Code",
      value: data?.additionalContact?.zipCode,
    },
    {
      label: "Country/Region",
      value: data?.additionalContact?.countryOrRegion,
    },
    {
      label: "Phone Number",
      value: data?.additionalContact?.telephoneNumber,
    },
    {
      label: "Email Address",
      value: data?.additionalContact?.emailAddress,
    },
  ];

  const sectionB: ReviewSectionChildrenData[] = [
    {
      label: "SEVIS ID",
      value: data?.sevis?.sevisID,
    },
    {
      label: "Name of School",
      value: data?.sevis?.nameOfSchool,
    },
    {
      label: "Course Of Study",
      value: data?.sevis?.courseOfStudy,
    },
    {
      label: "Street Address Line 1",
      value: data?.sevis?.streetAddressLine1,
    },
    {
      label: "Street Address Line 2 (Optional)",
      value: data?.sevis?.streetAddressLine2,
    },
    {
      label: "City",
      value: data?.sevis?.city,
    },
    {
      label: "State/Province",
      value: data?.sevis?.stateOrProvince,
    },
    {
      label: "Postal Code/Zip Code",
      value: data?.sevis?.zipCode,
    },
  ];

  const section: ReviewData[] = [
    {
      section: {
        title: "Additional Contact",
        children: sectionA,
      },
    },
    {
      section: {
        title: "SEVIS",
        children: sectionB,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.studentExchangeVisaAdditional,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewSEV;

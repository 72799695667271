import React, { FC } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { mediaObj } from "../../../../styles/Media";
import { motion } from "framer-motion";
import Overlay from "../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../checkEligibilty/components/modals/variant";
import { ImCancelCircle } from "react-icons/im";
import CongratsImage from "../../../../images/web/congrats.svg";
import Colors from "../../../../styles/Colors";

interface Props {
  onClose: () => void;
}

const EmailNotif: FC<Props> = ({ onClose }) => {
  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel onClick={onClose}>
          <ImCancelCircle />
        </Cancel>
        <MainIcon>
          <img src={CongratsImage} alt="" />
        </MainIcon>
        <ContentWrapper>
          <TextBold>Thanks for joining SeamlessVisa! 🎉</TextBold>
          <Text>
            To secure your account, a verification email has been sent to the
            address you provided during signup. Check your inbox and follow the
            instructions to complete the process.
            <br />
            <br />
            If not received within a few minutes, check your spam or junk
            folder. Ensure the correct email address was provided during
            registration.
            <br />
            <br />
            Once verified, enjoy full access to SeamlessVisa's amazing
            features.
            <br />
            <br />
            <TextBold> Welcome aboard!</TextBold>
          </Text>
        </ContentWrapper>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default EmailNotif;

const Wrapper = styled(motion.div)`
  width: 802px;
  max-height: 80vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 76px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 70vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
  font-size: 1.1rem;
`;

export enum ProfileSections {
  dashboard = "dashboard",
  applications = "applications",
  cases = "cases",
  documents = "documents",
  settings = "settings",
}

export enum SettingsTabs {
  setting = "setting",
  password = "password",
  referral = "referral",
}

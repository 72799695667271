import React, { FC } from "react";
import { saveDS160PassportObj } from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { reviewDoesNotApply } from "../../../../components/data";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160PassportObj;
}

const ReviewPassport: FC<Props> = ({ title, data, isDownload }) => {
  const section: ReviewData[] = [
    {
      label: "Passport/Travel Document Type",
      value: data?.passportType,
    },
    {
      label: "Passport/Travel Document Number",
      value: reviewDoesNotApply(data?.passportNumber, data?.doesNotApply),
    },
    {
      label: "Passport Book Number",
      value: data?.passportBookNumber,
    },
    {
      label: "Country",
      value: data?.country,
    },
    {
      section: {
        title: "Where was the passport/travel document issued?",
        children: [
          {
            label: "City",
            value: data?.city,
          },
          {
            label: "State province (if shown on passport)",
            value: data?.stateProvince,
          },
          {
            label: "Country or Region",
            value: data?.countryOrRegion,
          },
          {
            label: "Issuance Date",
            value: data?.issuanceDate,
          },
          {
            label: "Expiration Date",
            value: data?.expirationDate,
          },
          {
            label: "Have you ever lost a passport or had one stolen?",
            value: data?.everLostPassport,
          },
          {
            label: "Passport/Travel Document Number",
            value: data?.passportLostPassportNumber,
          },
          {
            label: "Country or Authority that issued passport/travel document",
            value: data?.passportLostCountry,
          },
          {
            label: "Explain",
            value: data?.passportLostExplain,
          },
        ],
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.passport,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewPassport;

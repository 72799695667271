import React from "react";
import styled from "styled-components";
import { ProfileNav } from "../../layout/components/data";
import ProfileNavItem from "./ProfileNavItem";

const AccountBox = () => {
  return (
    <ItemWrapper>
      {ProfileNav.map((ev, i) => (
        <ProfileNavItem
          key={i}
          label={ev.label}
          // url={ev.url}
          children={ev.children}
        />
      ))}
    </ItemWrapper>
  );
};

export default AccountBox;

const ItemWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  transition: 0.3s all ease;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 55px;
  }
`;

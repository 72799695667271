import React, { useEffect } from "react";
import styled from "styled-components";
import { ProfileSections } from "./types";
import { useSearchParams } from "react-router-dom";
import Dashboard from "./Dashboard";
import Applications from "./applications";
import Case from "./case";
import Setting from "./settings/index";
import { useGetUserData } from "../../../../hooks/utils/useUtils";
import { userDataStore } from "../../../../store/userData";
import Spinner from "../../../../utils/Spinner";
import Colors from "../../../../styles/Colors";
import Documents from "./documents";

const RightSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get("active");
  const { data, isFetching } = useGetUserData();
  userDataStore.userData = data;

  useEffect(() => {
    if (!getType) {
      searchParams.set("active", ProfileSections.dashboard);
      setSearchParams(searchParams);
    }
  }, [searchParams, getType, setSearchParams]);

  return (
    <Container>
      {isFetching ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          {ProfileSections.dashboard === getType && <Dashboard />}
          {ProfileSections.applications === getType && <Applications />}
          {ProfileSections.cases === getType && <Case />}
          {ProfileSections.documents === getType && <Documents />}
          {ProfileSections.settings === getType && <Setting />}
        </>
      )}
    </Container>
  );
};

export default RightSection;

const Container = styled.div`
  width: 100%;
  height: 100%;

  input,
  textarea,
  .css-1qmnlw8-control {
    background-color: transparent;
  }
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.WhiteF9};
  border-radius: 12px;
`;

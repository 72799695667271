import { useGetStates } from "../../../hooks/utils/useUtils";
import { FormatingData } from "./data";
import { statesObj } from "../../../api/utils/types";
import { useEffect, useState } from "react";

const useStatesByCountry = (passedCountry?: string) => {
  const [selectedCountry, setCountry] = useState("");
  const { data: stateData, isLoading: stateLoading } =
    useGetStates(selectedCountry);

  useEffect(() => {
    if (passedCountry) {
      setCountry(passedCountry);
    }
  }, [passedCountry]);

  const formattedStateData =
    FormatingData<statesObj>("state_code", "name", stateData?.states) || [];

  return {
    formattedStateData,
    stateLoading,
    setCountry,
    selectedCountry,
  };
};

export default useStatesByCountry;

import React from "react";
import Constaint from "../../layout/components/Constaint";
import styled from "styled-components";
import OfferBg from "../../../../images/web/offer-bg.png";
import { OfferData } from "./data";
import Colors from "../../../../styles/Colors";
import { motion } from "framer-motion";
import {
  TitleVariant,
  listItemContainerVariant,
  listItemVariant,
} from "./variants";
import { useNavigate } from "react-router-dom";

const Offers = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Label>WHAT WE ARE OFFERING</Label>
          <H2
            variants={TitleVariant}
            whileInView={"animate"}
            initial={"initial"}
            viewport={{ once: true }}
          >
            Outstanding <Colored>Immigration</Colored> visa services
          </H2>
          <CardFlex
            variants={listItemContainerVariant}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
          >
            {OfferData.map((ev, i) => (
              <Card key={i} variants={listItemVariant}>
                <CardImage src={ev.img} />
                <CardContent>
                  <CardName>{ev.name}</CardName>
                  <HR />
                  <CardPara>{ev.subtext}</CardPara>
                  <CardBtn onClick={() => navigate(ev.url)}>
                    Check Eligibility
                  </CardBtn>
                </CardContent>
              </Card>
            ))}
          </CardFlex>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Offers;

const Wrapper = styled.div`
  background: linear-gradient(
      0deg,
      rgba(0, 52, 102, 0.38),
      rgba(0, 52, 102, 0.38)
    ),
    url(${OfferBg});
  background-size: cover;
`;

const Container = styled.div`
  padding-top: 41px;
  padding-bottom: 107px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  color: #f8f4f4;
`;

const H2 = styled(motion.h2)`
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.6875rem;
  letter-spacing: 0em;
  margin-top: 16px;
  margin-bottom: 61px;
  color: #ffffff;

  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

const Colored = styled.span`
  color: #cfa202;
`;

const CardFlex = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Card = styled(motion.div)`
  width: 350px;
  height: 450px;
  background: #ffffff;
  border-radius: 12px;

  @media only screen and (min-width: 768px) {
    width: 315px;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 227px;
  border-radius: 12px 12px 0px 0px;
  object-fit: cover;
`;

const CardContent = styled.div`
  height: 223px;
  padding-top: 21px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
  padding-left: 12px;
  padding-right: 12px;
`;

const HR = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: rgba(167, 170, 189, 0.4);
`;

const CardName = styled.h6`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: 0em;
  padding-bottom: 6px;
`;

const CardPara = styled.p`
  flex: 1;
  padding-top: 10px;
  padding-bottom: 16px;
  width: 90%;
`;

const CardBtn = styled.button`
  height: 36px;
  border-radius: 8px;
  padding: 10px 24px;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #0076e9;
  border: 1px solid #0076e9;
  border-radius: 8px;

  &:hover {
    background: ${Colors.Blue00};
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }
`;

import { FormCategories } from "../../../../routes/type";
import { ProfileSections, SettingsTabs } from "./types";
import * as Icons from "@heroicons/react/24/solid";
import { IoDocuments } from "react-icons/io5";

export const leftSideBarData = [
  {
    label: "Dashboard",
    url: `?active=${ProfileSections.dashboard}`,
    icon: <Icons.RectangleGroupIcon className="h-8" />,
  },
  {
    label: "Applications",
    url: `?active=${ProfileSections.applications}`,
    icon: <Icons.DocumentTextIcon className="h-8" />,
  },
  {
    label: "Manage Case",
    url: `?active=${ProfileSections.cases}`,
    icon: <Icons.BanknotesIcon className="h-8" />,
  },
  {
    label: "Upload Documents",
    url: `?active=${ProfileSections.documents}`,
    icon: <IoDocuments className="h-8" />,
  },
  {
    label: "Settings",
    url: `?active=${ProfileSections.settings}`,
    icon: <Icons.Cog6ToothIcon className="h-8" />,
  },
];

export interface NavItemsProps {
  label: string;
  url?: string;
  children?: {
    name: JSX.Element;
    url: string;
  }[];
}

export const NavItems: NavItemsProps[] = [
  {
    label: "My Account",
    children: [
      {
        name: <div>Settings</div>,
        url: `?active=${ProfileSections.settings}`,
      },
      {
        name: <div>Sign Out</div>,
        url: "",
      },
    ],
  },
];

export const settingsTab = [
  {
    title: "Profile Settings",
    url: SettingsTabs.setting,
  },
  {
    title: "Password",
    url: SettingsTabs.password,
  },
  {
    title: "Referral",
    url: SettingsTabs.referral,
  },
];

export const newApplicationData = [
  {
    value: FormCategories.n400,
    label: "N-400 Citizenship through Naturalization",
  },
  {
    value: FormCategories.n565,
    label: "N-565 Replacement of Citizenship Document",
  },
  { value: FormCategories.n600, label: "N-600 Certficate of Citizenship" },
  { value: FormCategories.ds160, label: "Travel Visa" },
  { value: FormCategories.ds160, label: "Student Visa" },
  { value: FormCategories.ds160, label: "F1 Visa" },
];

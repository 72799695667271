import React, { FC } from "react";
import { saveDS160USContactObj } from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { reviewDoesNotApply } from "../../../../components/data";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160USContactObj;
}

const ReviewUSContact: FC<Props> = ({ title, data, isDownload }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Contact person or organization in the united states",

        children: [
          {
            label: "Surname",
            value: data?.surname,
          },
          {
            label: "Given Name",
            value: reviewDoesNotApply(data?.givenNames, data?.doesNotApply),
          },
          {
            label: "Organization Name",
            value: data?.organizationName,
          },
          {
            label: "Relationship to you",
            value: data?.relationshipToYou,
          },
        ],
      },
    },

    {
      section: {
        title: "Address and phone number of the point of contact",
        children: [
          {
            label: "U.S Street Address (Line 1)",
            value: data?.usStreetAddressLine1,
          },
          {
            label: "U.S Street Address Line 2 (optional)",
            value: data?.usStreetAddressLine2,
          },
          {
            label: "City",
            value: data?.city,
          },
          {
            label: "State",
            value: data?.state,
          },
          {
            label: "Zip Code",
            value: data?.zipCode,
          },
          {
            label: "Phone Number",
            value: data?.phoneNumber,
          },
          {
            label: "Email Address",
            value: reviewDoesNotApply(
              data?.emailAddress,
              data?.emailDoesNotApply
            ),
          },
        ],
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.usContact,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewUSContact;

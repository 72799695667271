import React, { useState } from "react";
import FormBtn from "../../../components/FormBtn";
import ReviewTop from "../../../components/Review/ReviewTop";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import useScrollVisible from "../../../components/Review/useScrollVisible";
import { useGetN400Info, usePayN400 } from "../../../../../hooks/n400/useN400";
import ReviewTime from "./components/ReviewTime";
import ReviewAccomodation from "./components/ReviewAccomodation";
import ReviewExemptionToEnglishAndCivics from "./components/ReviewExemptionToEnglishAndCivics";
import ReviewExemptionFromEnglishTest from "./components/ReviewExemptionFromEnglishTest";
import ReviewAdditionalInfo from "./components/ReviewAdditionalInfo";
import ReviewBioInfo from "./components/ReviewBioInfo";
import ReviewAddressHistory from "./components/ReviewAddressHistory";
import ReviewPI from "../../../PI/components/ReviewPI";
import ReviewMaritalHistory from "./components/ReviewMaritalHistory";
// import ReviewEmploymentHistory from "./components/ReviewEmploymentHistory";
import ReviewChildrenInfo from "./components/ReviewChildrenInfo";
import ReviewBtns from "../../../components/Review/ReviewBtns";
import { useSearchParams } from "react-router-dom";
import N400PaySuccess from "../N400PaySuccess";
import N400PayFail from "../N400PayFail";
import useIsPaid from "../../../../../hooks/useIsPaid";
import { FormN400Progress } from "../types";
import PricingModal from "../../../components/PricingModal";
import { pricingData } from "../../../components/data";
import { BtnFooterWrapper } from "../../../components/styles";
// import ReviewCheckPart1 from "./components/ReviewCheckPart1";
// import ReviewCheckPart2 from "./components/ReviewCheckPart2";
// import ReviewCheckPart3 from "./components/ReviewCheckPart3";
// import ReviewCheckPart4 from "./components/ReviewCheckPart4";
import ReviewCheckPart5 from "./components/ReviewCheckPart5";
import ReviewCheckPart6 from "./components/ReviewCheckPart6";
import { FormCategories } from "../../../../../routes/type";
import DownloadForms from "../../../components/DownloadForms";
import { CaseHistoryObj } from "../../../../../types/types";
import { userDataStore } from "../../../../../store/userData";
import { useSnapshot } from "valtio";
import ReviewApplicantInfo1 from "./components/ReviewApplicantInfo1";
import ReviewApplicantInfo2 from "./components/ReviewApplicantInfo2";
import ReviewEmploymentHistory from "./components/ReviewEmploymentHistory";
import ReviewCheckPart1 from "./components/ReviewCheckPart1";
import ReviewCheckPart2 from "./components/ReviewCheckPart2";
import ReviewCheckPart3 from "./components/ReviewCheckPart3";
import ReviewCheckPart4 from "./components/ReviewCheckPart4";
import ReviewFeeDeduction from "./components/ReviewFeeDeduction";

const N400Review = () => {
  const { isFormPaid } = useIsPaid();
  const { showTop } = useScrollVisible();
  const { data, isFetching, isError } = useGetN400Info();
  const { mutate: payMutate, isLoading: isPaying } = usePayN400();
  const { userData } = useSnapshot(userDataStore);

  const [searchParams] = useSearchParams();
  const isSuccess = searchParams.get("pay");
  const isFail = searchParams.get("fail");

  const [selectPlan, setSelectPlan] = useState<boolean>(false);

  const [onDownload, setOnDownload] = useState<CaseHistoryObj>();

  const onOpenDownload = () => {
    const formType = FormCategories.n400;
    const tableData = userData?.caseHistory.find(
      (ev) => ev.caseType === formType
    );
    setOnDownload(tableData);
  };

  const onCloseDownload = () => {
    setOnDownload(undefined);
  };

  const onSelectPlan = () => setSelectPlan(true);

  // the onPay function for N400 having plan options,
  // so we use the API type data `payN400Data`; {amount: string; plan: string;}
  const onPay = (plan: "standard" | "premium", couponCode: string) => {
    payMutate(
      // the data is supposed to go in here, but I;m so confused on how to do that 🥲
      { plan, couponCode },
      {
        onSuccess: (data) => {
          window.location.replace(data.data.url);
        },
      }
    );
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <>
        {/* this modal only comes up when the user clicks on the ulock form button from below that has the onPay function initially */}
        {selectPlan && (
          <PricingModal
            pricing={pricingData.N400Pricing}
            onModalClose={() => setSelectPlan(false)}
            onContinue={onPay}
            // the actual on pay button the problem I have is how to pass in the arguments from here or from the Modal file, it's so confusing... 😪
            // onContinue={(onPay}
          />
        )}
        <ReviewBtns
          // if the user haven't paid, the form toggles the pricing modal passing in the N400Pricing data
          onSave={isFormPaid ? onOpenDownload : onSelectPlan}
          isSaving={isPaying}
          formType={"N-400"}
          hasPaid={isFormPaid}
        />
        <ReviewApplicantInfo1
          title="1a. Applicant's Information 1"
          data={data?.applicantInfoOne}
        />
        <ReviewApplicantInfo2
          title="1b. Applicant's Information 2"
          data={data?.applicantInfoTwo}
        />
        <ReviewBioInfo
          title="2. Biographic Information"
          data={data?.applicantBioInfo}
        />
        <ReviewAddressHistory
          title="3. Information About Your Residence"
          data={data?.applicantAddressHistory}
        />
        <ReviewMaritalHistory
          title="4. Marital History"
          data={data?.maritalHistory}
        />
        <ReviewChildrenInfo
          title="5. Information About Children"
          data={data?.infoAboutChildren}
        />

        <ReviewEmploymentHistory
          title="6. Employment and School History"
          data={data?.employmentAndSchoolHistory}
        />

        <ReviewTime title="7. Time Outside the US" data={data?.timeOutsideUS} />

        {/* <ReviewAccomodation
          title="3. Accommodations for Individuals with Disabilities and Impairments"
          data={data?.accommodationsForIndividualsWithDisabilities}
        />
        <ReviewExemptionToEnglishAndCivics
          title="4. Exemption to the English and Civics Testing Requirements"
          data={data?.exemptionToEnglishAndCivics}
        />
        <ReviewExemptionFromEnglishTest
          title="5. Exemptions from the English Language Test"
          data={data?.exemptionToEnglish}
        />
        <ReviewAdditionalInfo
          title="6. Additional Information"
          data={data?.additionalInfo}
        /> */}

        <ReviewCheckPart1
          title="8a. Additional Information about you (Person Applying for Naturalization 1)"
          data={data?.checklistForNaturalizationOne}
        />
        <ReviewCheckPart2
          title="8b. Additional Information about you (Person Applying for Naturalization 2)"
          data={data?.checklistForNaturalizationTwo}
        />
        <ReviewCheckPart3
          title="8c. Additional Information about you (Person Applying for Naturalization 3)"
          data={data?.checklistForNaturalizationThree}
        />
        <ReviewCheckPart4
          title="8d. Additional Information about you (Person Applying for Naturalization 4)"
          data={data?.checklistForNaturalizationFour}
        />
        <ReviewFeeDeduction
          title="9. Request for a Fee Reduction"
          data={data?.requestFeeReduction}
        />
        {/* <ReviewCheckPart5
          title="12e. Additional Information about you (Person Applying for Naturalization 5)"
          data={data?.checklistForNaturalizationFive}
        />
        <ReviewCheckPart6
          title="12f. Additional Information about you (Person Applying for Naturalization 6)"
          data={data?.checklistForNaturalizationSix}
        /> */}
        <ReviewPI
          title="14. Preparer and Interpreter"
          data={data?.n400PreparerAndInterpreter}
          editUrl={FormN400Progress.PI}
        />
        <BtnFooterWrapper>
          <FormBtn
            isLoading={false}
            addBackBtn
            isError={false}
            addContinueBtn={false}
            removeHR
          />
          <ReviewBtns
            // if the user haven't paid, the form toggles the pricing modal passing in the N400Pricing data
            onSave={isFormPaid ? onOpenDownload : onSelectPlan}
            isSaving={isPaying}
            formType={"N-400"}
            hasPaid={isFormPaid}
          />
        </BtnFooterWrapper>
        <ReviewTop showTop={showTop} />
        {isSuccess && <N400PaySuccess formType={FormCategories.n400} />}
        {isFail && <N400PayFail onRetry={onSelectPlan} isRetrying={isPaying} />}
        {onDownload && (
          <DownloadForms onClose={onCloseDownload} data={onDownload} />
        )}
      </>
    </FormSectionWrapper>
  );
};

export default N400Review;

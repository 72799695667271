import * as Yup from "yup";

export type SchemaObject<T> = {
  [key in keyof T]: Yup.Schema<any>;
};

export interface leftSideBarDataProps {
  label: string;
  // url?: string;
  formProgress?: string;
  children?: {
    name: string;
    // url: string;
    formProgress: string;
  }[];
}

export enum ApplicationPreparerValues {
  iAmTheApplicant = "I am the applicant",
  iAmApreparer = "I am not an attorney but i am a preparer",
  iAmTheAttorney = "I am an attorney",
}

export enum ReceiveInterpreterHelpValues {
  yes = "yes",
  no = "no",
}

export interface ValidateOptionsExtended {
  options: {
    index: number;
    key: string;
  };
}

export interface DownloadFormsContentProps {
  hideMainform?: boolean;
  hideFilingForm?: boolean;
  hideG1145Form?: boolean;
  formType: string;
}

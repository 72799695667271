import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { tabText } from "./LeftSide";
import { SettingsTabs } from "../../types";
import ProfileSettings from "./ProfileSettings";
import styled from "styled-components";
import Password from "./Password";
import Referral from "./Referral";

const RightSide = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get(tabText);

  useEffect(() => {
    if (!getType) {
      searchParams.set(tabText, SettingsTabs.setting);
      setSearchParams(searchParams);
    }
  }, [getType, searchParams, setSearchParams]);

  return (
    <Wrapper>
      {getType === SettingsTabs.setting && <ProfileSettings />}
      {getType === SettingsTabs.password && <Password />}
      {getType === SettingsTabs.referral && <Referral />}
    </Wrapper>
  );
};

export default RightSide;

const Wrapper = styled.div`
  flex: 0.8;
`;

import { FormikProps } from "formik";
import { SingleOption } from "../../../types/types";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { camelCaseToWords } from "../../../utils";
import { FormCategories } from "../../../routes/type";
dayjs.extend(customParseFormat);

export enum MaritalStatusValues {
  "single & never married" = "single & never married",
  married = "married",
  divorced = "divorced",
  widowed = "widowed",
  separated = "separated",
  "marriage annulled" = "marriage annulled",
  other = "other",
}

export const MaritalStatusData: SingleOption[] = [
  {
    value: MaritalStatusValues["single & never married"],
    label: "Single, Never Married",
  },
  { value: MaritalStatusValues.married, label: "Married" },
  { value: MaritalStatusValues.divorced, label: "Divorced" },
  { value: MaritalStatusValues.widowed, label: "Widowed" },
  { value: MaritalStatusValues.separated, label: "Separated" },
  {
    value: MaritalStatusValues["marriage annulled"],
    label: "Marriage Annulled",
  },
  { label: "Other", value: "Other" },
];

export const ApartmentSuiteFloorData: SingleOption[] = [
  { value: "", label: "-Does not apply-" },
  { value: "Apartment", label: "Apartment" },
  { value: "Suite", label: "Suite" },
  { value: "Floor", label: "Floor" },
];

export enum ApartmentSuiteFloorValues {
  Apartment = "Apartment",
  Floor = "Floor",
  Suite = "Suite",
}

export const DocumentData: SingleOption[] = [
  { value: "Passport", label: "Passport" },
  { value: "Travel Document", label: "Travel Document" },
];

export enum DocumentDataValues {
  passport = "Passport",
  travelDoc = "Travel Document",
}

export enum GenericYesNoOptionsValue {
  yes = "yes",
  no = "no",
}

export const GenericYesNo = [
  { label: "Yes", value: GenericYesNoOptionsValue.yes },
  { label: "No", value: GenericYesNoOptionsValue.no },
];

export const resetDynamicFields = (
  formik: FormikProps<any>,
  params: { name: string; value: "" | any[] | false | null }[]
) => {
  params.forEach((ev) => {
    formik.setFieldTouched(ev.name, false);
    formik.setFieldValue(ev.name, ev.value);
  });
};

export const FormatingData = <T,>(
  valueKey: keyof T,
  labelKey: keyof T,
  data?: Array<T>
): SingleOption[] | undefined => {
  if (!data) return;
  return data.map((ev) => {
    const temp = {
      label: ev[labelKey],
      value: ev[valueKey],
    } as SingleOption;
    return temp;
  });
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getSelectedOption = (
  data: SingleOption<any, any>[],
  value: string | number
) => {
  return data.filter((ev) => ev.value === value)[0];
};

export const initInput = (params?: string) => {
  return params || "";
};

export const initCheck = (params?: boolean) => {
  return params || false;
};

export const initSelect = (option?: SingleOption[], params?: string) => {
  return params !== undefined && option !== undefined
    ? getSelectedOption(option, params)
    : null;
};

export const initDate = (params?: string, format = USDateFormat) => {
  return params ? dayjs(params, format) : null;
};

export const ToSaveDate = (params: Dayjs | null, format = USDateFormat) => {
  return params ? dayjs(params).format(format || "YYYY-MM-DD") : "";
};

export const saveDynamicCheck = (params?: string) => {
  return params?.toLowerCase() === "true" ? true : false;
};

export const DoesNotApplyText = "Does not apply";
export const DoNotKnowText = "Do not know";

export const reviewDoesNotApply = (value?: string, condition?: boolean) => {
  return condition ? DoesNotApplyText : value;
};

export const reviewDoNotKnow = (value?: string, condition?: boolean) => {
  return condition ? DoNotKnowText : value;
};

export const getFormatedFormName = (params?: string) => {
  if (!params) return "--";
  const groups = params.match(/[a-z]+|[^a-z]+/gi) || "";
  return `${groups[0]}-${groups[1]}`;
};

export const getEnumIndex = (enumVal: any, params?: string) => {
  return params ? Object.values(enumVal).indexOf(params as any) : 0;
};

export const getKeyByValue = (enumVal: any, value: string) => {
  const indexOfS = Object.values(enumVal).indexOf(value);

  const key = Object.keys(enumVal)[indexOfS];

  return key;
};

export const getIsValuePartOfEnum = (str: string, enumVal: any) => {
  return Object.values(enumVal).includes(str as any);
};

export const riskyCheckBoxName = "_risky_checkbox";

interface PricingDataInterface {
  N400Pricing: PricingType[];
  N565Pricing: PricingType[];
  N600Pricing: PricingType[];
  StudentVisaPricing: PricingType[];
  VisitorsVisaPricing: PricingType[];
}
export interface PricingType {
  package: string;
  price: number;
  extras: string;
  upgrade?: string;
  offers: string[];
  plan: "standard" | "premium";
}

export const pricingData: PricingDataInterface = {
  N400Pricing: [
    {
      package: "standard package",
      price: 199,
      extras: "+USCIS fees",
      plan: "standard",
      offers: [
        "Eligibility check before starting your application",
        "Step by step guide to complete N-400 using our immigration software",
        "Personalized instructions and document checklist",
        "Form N-400 ready for submission to USCIS",
        "USCIS Status Updates",
        "Guaranteed approval or your money back",
        "Online Chat Support",
      ],
    },
    {
      package: "Premium package",
      price: 299,
      extras: "+USCIS fees",
      plan: "premium",
      offers: [
        "Eligibility check before starting your application",
        "Step by step guide to complete N-400 using our immigration software",
        "Personalized instructions and document checklist",
        "Premium Review by Immigration Experts",
        "We'll print and mail professionally assembled N-400 to you",
        "We include a pre-paid envelope for you to send your package directly to USCIS",
        "USCIS Status Updates",
        "Guaranteed approval or your money back",
        "Online Chat Support",
      ],
    },
  ],
  N565Pricing: [
    {
      package: "standard package",
      price: 149,
      extras: "+USCIS fees",
      plan: "standard",
      offers: [
        "Eligibility check before starting your application",
        "Step by step guide to complete N-565 using our immigration software",
        "Personalized instructions and document checklist",
        "Form N-565 ready for submission to USCIS",
        "USCIS Status Updates",
        "Guaranteed approval or your money back",
        "Online Chat Support",
      ],
    },
  ],
  N600Pricing: [
    {
      package: "standard package",
      price: 199,
      extras: "+USCIS fees",
      plan: "standard",
      offers: [
        "Eligibility check before starting your application",
        "Step by step guide to complete N-600 using our immigration software",
        "Personalized instructions and document checklist",
        "Form N-600 ready for submission to USCIS",
        "USCIS Status Updates",
        "Guaranteed approval or your money back",
        "Online Chat Support",
      ],
    },
    {
      package: "premium package",
      price: 299,
      extras: "+USCIS fees",
      plan: "premium",
      offers: [
        "Eligibility check before starting your application",
        "Step by step guide to complete N-600 using our immigration software",
        "Personalized instructions and document checklist",
        "Premium Review by Immigration Experts",
        "We'll print and mail professionally assembled N-600 to you",
        "We include a pre-paid envelope for you to send your package directly to USCIS",
        "USCIS Status Updates",
        "Guaranteed approval or your money back",
        "Online Chat Support",
      ],
    },
  ],
  StudentVisaPricing: [
    {
      package: "Student Visa Package ",
      price: 199,
      plan: "standard",
      extras: "+Gov fees",
      upgrade:
        "Add on $60: Upgrade to priority visa processing for qualified applicants",
      offers: [
        "Eligibility assessment and expert support for visa application",
        "Personalized instructions and document checklist ",
        "Appointment arrangement and scheduling",
        "Support with paying your government fees, including the MRV Appointment fee and SEVIS FEE",
        "Interview preparation call with our team trained by a former consular officer",
        "Unlimited support from SeamlessVisa experts",
        "Free assistance for reapplication in case of denial",
      ],
    },
  ],
  VisitorsVisaPricing: [
    {
      package: "Travel Visa Package ",
      price: 199,
      plan: "standard",
      extras: "+Gov fees",
      offers: [
        "Eligibility assessment and expert support for visa application",
        "Personalized instructions and document checklist ",
        "Appointment arrangement and scheduling",
        "Support with paying your government fees, including the MRV Appointment fee",
        "Interview preparation call with our team trained by a former consular officer",
        "Unlimited support from SeamlessVisa experts",
        "Free assistance for reapplication in case of denial",
      ],
    },
  ],
};

export const USDateFormat = "MM-DD-YYYY";
export const USDatePlaceholderFormat = "mm-dd-yyyy";
export const NGDateFormat = "DD-MM-YYYY";
export const NGDatePlaceholderFormat = "dd-mm-yyyy";

export const OccupationDropDown: SingleOption[] = [
  {
    value: "Architecture and Engineering",
    label: "Architecture and Engineering",
  },
  {
    value: "Arts. Design and Fashion",
    label: "Arts. Design and Fashion",
  },
  {
    value: "Audio/Video Technology",
    label: "Audio/Video Technology",
  },
  {
    value: "Building and Grounds Cleaning and Maintenance",
    label: "Building and Grounds Cleaning and Maintenance",
  },
  {
    value: "Management and Administration",
    label: "Management and Administration",
  },
  {
    value: "Computer. Mathematical and Information Technology",
    label: "Computer. Mathematical and Information Technology",
  },
  {
    value: "Construction and Extraction",
    label: "Construction and Extraction",
  },
  {
    value: "Education. Training and Library",
    label: "Education. Training and Library",
  },
  {
    value: "Entertainment and Sports",
    label: "Entertainment and Sports",
  },
  {
    value: "Farming. Fishing and Forestry",
    label: "Farming. Fishing and Forestry",
  },
  {
    value: "Business and Financial Operations",
    label: "Business and Financial Operations",
  },
  {
    value: "Food Preparation and Service",
    label: "Food Preparation and Service",
  },
  {
    value: "Government and Public Administration",
    label: "Government and Public Administration",
  },
  {
    value: "Healthcare Practitioners",
    label: "Healthcare Practitioners",
  },
  {
    value: "Healthcare Technicians and Support",
    label: "Healthcare Technicians and Support",
  },
  {
    value: "Hospitality and Tourism",
    label: "Hospitality and Tourism",
  },
  {
    value: "Human and Social Services",
    label: "Human and Social Services",
  },
  {
    value: "Law Enforcement, Public Safety and Security",
    label: "Law Enforcement, Public Safety and Security",
  },
  {
    value: "Legal",
    label: "Legal",
  },
  {
    value: "Life. Physical and Social Science",
    label: "Life. Physical and Social Science",
  },
  {
    value: "Installation. Maintenance and Repair",
    label: "Installation. Maintenance and Repair",
  },
  {
    value: "Manufacturing and Production",
    label: "Manufacturing and Production",
  },
  {
    value: "Marketing. Sales, and Service",
    label: "Marketing. Sales, and Service",
  },
  {
    value: "Media and Communication",
    label: "Media and Communication",
  },
  {
    value: "Military",
    label: "Military",
  },
  {
    value: "Office and & Administrative Support",
    label: "Office and & Administrative Support",
  },
  {
    value: "Personal Care and Service",
    label: "Personal Care and Service",
  },
  {
    value: "Retired",
    label: "Retired",
  },
  {
    value: "Student",
    label: "Student",
  },
  {
    value: "Transportation. Material Moving. Distribution. and Logistics",
    label: "Transportation. Material Moving. Distribution. and Logistics",
  },
  {
    value: "Other",
    label: "Other",
  },
];

interface FormatMultiStatesProps {
  dataKey: string;
  fieldKey: string;
  dataArray: SingleOption[][];
}

export const formatMultiStates = ({
  dataKey,
  fieldKey,
  dataArray,
}: FormatMultiStatesProps) => {
  return dataKey.split(",").map((ev, index) => ({
    [fieldKey]: dataArray[index].find((child) => child.value === ev) || null,
  }));
};

export const SpecialSeparator = "&";

export const GenderDropDown: SingleOption[] = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];

export const ImmigrationStatus: SingleOption[] = [
  {
    value: "A Lawful Permanent Resident (LPR)",
    label: "A Lawful Permanent Resident (LPR)",
  },
  { value: "A Nonimmigrant", label: "A Nonimmigrant" },
  { value: "A Refugee/Asylee", label: "A Refugee/Asylee" },
  { value: "Other", label: "Other" },
];

export enum ImmigrationStatusValues {
  lpr = "A Lawful Permanent Resident (LPR)",
  nonImmigrant = "A Nonimmigrant",
  refugee = "A Refugee/Asylee",
  other = "Other",
}

export const isZipCode = (params?: string) => {
  if (params === "Philippines" || params === "United States") {
    return true;
  }

  return false;
};

export const turnCheckFieldToReadable = (params?: any) => {
  if (!params) return "--";
  return Object.keys(params)
    .filter((k) => params[k] === true)
    .map((ev) => camelCaseToWords(ev))
    .join(", ");
};

export const FormAISystemInstruction = ({
  formCategory,
}: {
  formCategory: FormCategories;
}) => `Role: ${formCategory} AI Chat Support Specialist (SeamlessVisa.com)

Purpose: Assist users who have passed SeamlessVisa's ${formCategory} eligibility quiz.

Expertise: U.S. immigration laws (specifically Form ${formCategory}) and SeamlessVisa services.

Information Source: Knowledge base and SeamlessVisa services.

User Interaction:
- Provide short, concise and accurate information on ${formCategory} and related immigration processes.
- Do not provide legal advice.
- Redirect unrelated inquiries to SeamlessVisa.com.

SeamlessVisa Services:
- They offer eligibility quiz and form input validation, error check against eligibilty answers and data correctness across the forms which they offer which is N-565, N400, N600 and DS-160 (Travel and student visa)
- Generates a customized document checklist based on the user's ${formCategory} application.
- Reviews and provides feedback on uploaded documents.
- Offers filing assistance after expert review.
- Allows users to edit and re-file denied applications.
- N-565 has a standard price package of $149 + USCIS fees
- N-600 has a standard price package of $199 + USCIS fees, premium price package of $299 + USCIS fees
- N-400 has a standard price package of $199 + USCIS fees, premium price package of $299 + USCIS fees
- Affordable Pricing
- Automated Error System Checks Our integrated artificial intelligence system checks all errors while filling your forms to minimize mistakes and increase your chances of approval.
- Mission: Empowering individuals and families to achieve their immigration goals through efficient, affordable, and top-tier service
- Travel Visa has a price package of $199 + Gov fees
- Student Visa has a price package of $199 + Gov fees (Add on $60: Upgrade to priority visa processing for qualified applicants)
- Eligibility check before starting your application
- Step by step guide to complete ${formCategory} using our immigration software
- Personalized instructions and document checklist
- Form ${formCategory} ready for submission to USCIS
- USCIS Status Updates
- Guaranteed approval or your money back
- Online Chat Support,
- 

Important Notes:
- SeamlessVisa provides the document checklist.
- Users should contact SeamlessVisa directly (connect@seamlessvisa.com) for further questions or assistance.

Example Rephrase:
You are an AI assistant specializing in ${formCategory} applications for SeamlessVisa.com. You can answer questions about the form and SeamlessVisa's services, but cannot offer legal advice. If a user has questions outside your scope, politely redirect them to SeamlessVisa.com.

SeamlessVisa offers a document checklist based on the user's application, document review with feedback, and filing assistance. Users with denied applications can edit and re-submit through SeamlessVisa.

Remember, users should contact SeamlessVisa directly (connect@seamlessvisa.com) for specific questions or concerns.`;

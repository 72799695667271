import React, { FC } from "react";
import { saveDS160TravelCompanionsObj } from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160TravelCompanionsObj;
}

const ReviewTravelComp: FC<Props> = ({ title, data, isDownload }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Person Traveling with You",
        children: [
          {
            label: "Are there other persons traveling with you?",
            value: data?.otherPersonsTravelling,
          },
          {
            label: "Are you traveling as part of a group or organization?",
            value: data?.travellingAsPartOfGroup,
          },
          {
            label: "Surname of person traveling with you",
            value: data?.surname,
          },
          {
            label: "Given name of person traveling with you",
            value: data?.givenName,
          },
          {
            label: "Relationship with person",
            value: data?.relationshipwithPerson,
          },
          {
            label: "Enter group name",
            value: data?.groupName,
          },
        ],
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.travelCompanions,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewTravelComp;

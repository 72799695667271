import FormSectionWrapper from "../../components/FormSectionWrapper";
import {
  ArrayWrapper,
  Info,
  InfoBold,
  SubTitle,
  VerticalFormWrapper,
  Wrapper,
} from "./styles";
import { FieldArray, Form, Formik } from "formik";
import SelectField from "../../components/formik fields/SelectField";
import FormBtn from "../../components/FormBtn";
import styled from "styled-components";
// import DatePickerField from "../../components/DatePickerField";
import InputField from "../../components/formik fields/InputField";
// import { PurposeTripOptions, SpecificOptions } from "./data";
import RadioComp from "../../components/RadioComp";
import { PassportType } from "./data";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  NGDateFormat,
  NGDatePlaceholderFormat,
  ToSaveDate,
  getSelectedOption,
  initCheck,
  initDate,
  initInput,
  initSelect,
  saveDynamicCheck,
} from "../../components/data";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import DatePickerField from "../../components/formik fields/DatePickerField";
import { Modify, SingleOption } from "../../../../types/types";
import { saveDS160PassportObj } from "../../../../api/ds160/types";
import {
  useGetDS160Passport,
  useSaveDS160Passport,
} from "../../../../hooks/ds160/usDS160";
import { Dayjs } from "dayjs";
import { PassportValidation } from "./validation";
import { resetDynamicFields } from "../../components/data";
import useToNextSection from "../../../../hooks/useToNextSection";
import useCountries from "../../components/useCountries";
import useStatesByCountry from "../../components/useStatesByCountry";
import GroupedSection from "./GroupedSection";
import AddAnotherBtn from "../../components/AddAnotherBtn";

const initialPassportLostArray = {
  passportLostPassportNumber: "",
  passportLostCountry: null,
  passportLostExplain: "",
  passportLostPassportNumberDoNotKnow: false,
};

export interface PassportLostArrayPropsPayload {
  passportLostPassportNumber: string;
  passportLostCountry: SingleOption | null;
  passportLostExplain: string;
  passportLostPassportNumberDoNotKnow: boolean;
}

export interface PassportData
  extends Modify<
    Omit<
      saveDS160PassportObj,
      | "passportLostPassportNumber"
      | "passportLostCountry"
      | "passportLostExplain"
      | "passportLostPassportNumberDoNotKnow"
    >,
    {
      passportType: SingleOption | null;
      issuanceDate: Dayjs | null;
      expirationDate: Dayjs | null;
      country: SingleOption | null;
      countryOrRegion: SingleOption | null;
      stateProvince: SingleOption | null;
      passportLostArray: PassportLostArrayPropsPayload[];
    }
  > {}

const Passport = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetDS160Passport();
  const { mutate, isLoading: isSaving } = useSaveDS160Passport();

  const { formattedCountryData, countryLoading } = useCountries();

  const {
    formattedCountryData: whereFormattedCountryData,
    countryLoading: whereCountryLoading,
  } = useCountries();

  const {
    formattedStateData: whereFormattedStateData,
    stateLoading: whereStateLoading,
    setCountry: whereSetCountry,
    selectedCountry: whereSelectedCountry,
  } = useStatesByCountry(data?.countryOrRegion);

  const savedPassportLostArray = () => {
    if (
      data?.passportLostPassportNumber ||
      data?.passportLostCountry ||
      data?.passportLostExplain
    ) {
      const passportLostPassportNumberData = data?.passportLostPassportNumber
        .split(",")
        .map((ev) => ({ passportLostPassportNumber: ev }));

      const passportLostCountryData = data?.passportLostCountry
        .split(",")
        .map((ev) => ({
          passportLostCountry: getSelectedOption(formattedCountryData, ev),
        }));

      const passportLostExplainData = data?.passportLostExplain
        .split(",")
        .map((ev) => ({ passportLostExplain: ev }));

      const passportLostPassportNumberDoNotKnowData =
        data?.passportLostPassportNumberDoNotKnow.split(",").map((ev) => ({
          passportLostPassportNumberDoNotKnow: saveDynamicCheck(ev),
        }));

      return passportLostPassportNumberData.map((ev, i) => ({
        ...ev,
        ...passportLostCountryData[i],
        ...passportLostExplainData[i],
        ...passportLostPassportNumberDoNotKnowData[i],
      }));
    } else return [initialPassportLostArray];
  };

  const onSubmit = (values: PassportData) => {
    const {
      passportType,
      issuanceDate,
      expirationDate,
      country,
      countryOrRegion,
      stateProvince,
      passportLostArray,
      ...rest
    } = values;

    if (!passportType || !countryOrRegion) return;

    const payload = {
      ...rest,
      passportType: passportType.value,
      issuanceDate: ToSaveDate(issuanceDate, NGDateFormat),
      expirationDate: ToSaveDate(expirationDate, NGDateFormat),
      country: country?.value || "",
      countryOrRegion: countryOrRegion.value,
      stateProvince: stateProvince?.value || "",
      passportLostPassportNumber: passportLostArray
        .map((ev) => ev.passportLostPassportNumber || "")
        .join(","),
      passportLostCountry: passportLostArray
        .map((ev) => ev.passportLostCountry?.value || "")
        .join(","),
      passportLostExplain: passportLostArray
        .map((ev) => ev.passportLostExplain || "")
        .join(","),
      passportLostPassportNumberDoNotKnow: passportLostArray
        .map((ev) => ev.passportLostPassportNumberDoNotKnow.toString())
        .join(","),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: PassportData = {
    passportType: initSelect(PassportType, data?.passportType),
    passportNumber: initInput(data?.passportNumber),
    passportBookNumber: initInput(data?.passportBookNumber),
    doesNotApply: initCheck(data?.doesNotApply),
    country: initSelect(formattedCountryData, data?.country),
    city: initInput(data?.city),
    countryOrRegion: initSelect(
      whereFormattedCountryData,
      data?.countryOrRegion
    ),
    stateProvince: initSelect(whereFormattedStateData, data?.stateProvince),
    issuanceDate: initDate(data?.issuanceDate, NGDateFormat),
    expirationDate: initDate(data?.expirationDate, NGDateFormat),
    everLostPassport: initInput(data?.everLostPassport),
    passportLostArray: savedPassportLostArray(),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Passport.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={PassportValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <SelectField
                  name="passportType"
                  label="Passport/Travel Document Type"
                  options={PassportType}
                />

                <InputField
                  label="Passport/Travel Document Number"
                  placeholder=""
                  name="passportNumber"
                />

                <div className="flex flex-col gap-2">
                  <InputField
                    label="Passport Book Number"
                    placeholder=""
                    name="passportBookNumber"
                    isDisabled={!!formik.values.doesNotApply}
                  />
                  <CheckBoxField
                    name="doesNotApply"
                    label="Does not apply"
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "passportBookNumber", value: "" },
                      ]);
                    }}
                  />
                </div>

                <SelectField
                  label="Country or Authority that issued passport/travel document"
                  placeholder=""
                  name="country"
                  options={formattedCountryData}
                  isLoading={countryLoading}
                />

                <Sub>Where was the passport/travel document issued?</Sub>

                <InputField label="City" placeholder="" name="city" />

                <SelectField
                  label="Country/Region"
                  placeholder=""
                  name="countryOrRegion"
                  options={whereFormattedCountryData}
                  isLoading={whereCountryLoading}
                  onChange={(value) => {
                    whereSetCountry(value.label);
                    formik.setFieldValue(`stateProvince`, null);
                  }}
                />

                <SelectField
                  label="State/province"
                  coloredLabel="(if shown on passport)"
                  placeholder=""
                  name="stateProvince"
                  options={whereFormattedStateData}
                  isLoading={whereStateLoading}
                  isDisabled={!whereSelectedCountry}
                />

                <DatePickerField
                  name="issuanceDate"
                  label="Issuance Date"
                  placeholder={NGDatePlaceholderFormat}
                  disableFuture={true}
                />

                <DatePickerField
                  name="expirationDate"
                  label="Expiration Date"
                  placeholder={NGDatePlaceholderFormat}
                />

                <RadioComp
                  title="Have you ever lost a passport or had one stolen?"
                  name="everLostPassport"
                  options={GenericYesNo}
                />

                {formik.values.everLostPassport ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <div className="px-2 py-3 border rounded">
                      <FieldArray
                        name="passportLostArray"
                        render={(arrayHelpers) => (
                          <ArrayWrapper>
                            {formik.values.passportLostArray.map((_, index) => (
                              <GroupedSection
                                key={index}
                                index={index}
                                onCancel={() => arrayHelpers.remove(index)}
                              >
                                <div className="mb-4">
                                  <InputField
                                    name={`passportLostArray[${index}].passportLostPassportNumber`}
                                    label="Passport/Travel Document Number"
                                    placeholder=""
                                    isDisabled={
                                      formik.values.passportLostArray[index]
                                        .passportLostPassportNumberDoNotKnow
                                    }
                                  />

                                  <CheckBoxField
                                    name={`passportLostArray[${index}].passportLostPassportNumberDoNotKnow`}
                                    label="Does not know"
                                    onChange={() => {
                                      resetDynamicFields(formik, [
                                        {
                                          name: `passportLostArray[${index}].passportLostPassportNumber`,
                                          value: "",
                                        },
                                      ]);
                                    }}
                                  />
                                </div>

                                <div className="mb-4">
                                  <SelectField
                                    name={`passportLostArray[${index}].passportLostCountry`}
                                    label="Country or Authority that issued passport/travel document"
                                    placeholder=""
                                    options={formattedCountryData}
                                    isLoading={countryLoading}
                                  />
                                </div>

                                <div className="mb-4">
                                  <InputField
                                    name={`passportLostArray[${index}].passportLostExplain`}
                                    label="Explain"
                                    placeholder=""
                                    inputType="text-area"
                                  />
                                </div>
                              </GroupedSection>
                            ))}
                            <AddAnotherBtn
                              onClick={() =>
                                arrayHelpers.push(initialPassportLostArray)
                              }
                            />
                          </ArrayWrapper>
                        )}
                      />
                    </div>
                  </>
                )}

                {/* end both */}
              </FormWrapper>
              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default Passport;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    width: 50%;
  }
`;

export const Sub = styled(SubTitle)`
  margin-bottom: 0;
`;

import { ApiResponse } from "../../types/types";
import request from "../request";
import { GetInterviewHistoryRes, saveInterviewPayload } from "./types";
import aiRequest from "../aiRequest";

export const saveInterview = async (payload: saveInterviewPayload) => {
  return await request.post<ApiResponse>(
    `/ai/interview-support${payload.id ? `?id=${payload.id}` : ""}`,
    payload.payload
  );
};

export const saveChat = async (payload: saveInterviewPayload) => {
  return await request.post<ApiResponse>(
    `/ai/customer-support${payload.id ? `?id=${payload.id}` : ""}`,
    payload.payload
  );
};

export const getInterviewHistory = async () => {
  return await request.get<GetInterviewHistoryRes>(`/ai/get-interview-history`);
};

export const getInterviewVoiceHistory = async () => {
  return await request.get<GetInterviewHistoryRes>(
    `/ai/get-interview-history-voice`
  );
};

export const aiExtractData = async (payload: FormData) => {
  return await request.post(`/ai/extract-data`, payload);
};

export const aiModelQuery = async (payload: any) => {
  return await aiRequest.post<ApiResponse>(`/model-query`, payload);
};

export const deleteInterviewHistory = async () => {
  return await request.delete<GetInterviewHistoryRes>(
    `ai/clear-interview-history`
  );
};

export const postRiskAssessment = async (payload: any) => {
  return await request.post<ApiResponse>(`ai/risk-assessment`, payload);
};

import React, { FC } from "react";
import { saveDS160PreviousUSTravelObj } from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { reviewDoNotKnow } from "../../../../components/data";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160PreviousUSTravelObj;
}

const ReviewPreviousUS: FC<Props> = ({ title, data, isDownload }) => {
  const section: ReviewData[] = [
    {
      label: "Have you ever been to the US?",
      value: data?.everBeenToTheUs,
    },
    {
      label: "Date Arrived",
      value: data?.dateArrived,
    },
    {
      label: "Length of Stay",
      value: `${data?.lengthOfStayDuration} ${data?.lengthOfStayDropdown}`,
    },
    {
      label: "Do you or did you have a driver’s license?",
      value: data?.haveDriverLicense,
    },
    {
      label: "Driver’s License Number",
      value: data?.driverLicenseNumber,
    },
    {
      label: "State of Driver’s License",
      value: data?.stateOfDriverLicense,
    },
    {
      label: "Have you ever been issued a US visa?",
      value: data?.everIssuedUsVisa,
    },
    {
      label: "Date last visa was issued",
      value: data?.lastVisaDate,
    },
    {
      label: "Visa Number",
      value: reviewDoNotKnow(data?.visaNumber, data?.doNotKnowVisaNumber),
    },
    {
      label: "Are you applying for the same type of visa?",
      value: data?.applyingForSameTypeOfVisa,
    },
    {
      label:
        "Are you applying in the same country or location where the visa above was issued, and is this country or location your place of principal residence?",
      value: data?.applyingInSameLocation,
    },
    {
      label: "Have you been ten-printed?",
      value: data?.beenTenPrinted,
    },
    {
      label: "Has your US visa ever been lost or stolen?",
      value: data?.visaEverStolen,
    },
    {
      label: "Year",
      value: data?.visaStolenYear,
    },
    {
      label: "Explanation",
      value: data?.visaStolenExplanation,
    },
    {
      label: "Has your US visa ever been cancelled or revoked?",
      value: data?.visaEverRevoked,
    },
    {
      label: "Year",
      value: data?.visaRevokeYear,
    },
    {
      label: "Explanation",
      value: data?.visaRevokeExplanation,
    },
    {
      label:
        "Have you ever been refused a US visa or been refused admission to United States or withdrawn your application for admission at the port of entry?",
      value: data?.everBeenRefusedUsVisa,
    },
    {
      label: "Explanation",
      value: data?.everBeenRefusedUsVisaExplanation,
    },
    {
      label: "Are you or have you ever been a U.S. legal permanent resident?",
      value: data?.beenLPR,
    },
    {
      label: "Explanation",
      value: data?.beenLPRExplanation,
    },
    {
      label:
        "Has anyone ever filed an immigration petition on your behalf with the United States Citizenship and Immigration Services?",
      value: data?.anyoneFiledPetition,
    },
    {
      label: "Explanation",
      value: data?.anyoneFiledPetitionExplanation,
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.previousUSTravel,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewPreviousUS;

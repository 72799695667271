import { ApiResponse } from "../../types/types";
import request from "../request";
import {
  SignInRes,
  changePasswordPayload,
  forgotPasswordPayload,
  signinPayload,
  signupPayload,
  updateProfilePayload,
} from "./types";

export const changePassword = async (payload: changePasswordPayload) => {
  return await request.post<ApiResponse>(`/user/change-password`, payload);
};

export const updateProfile = async (payload: updateProfilePayload) => {
  return await request.post<ApiResponse>(`/user/update-profile`, payload);
};

interface SignUpPayload {
  data: signupPayload;
  refCode?: string | null;
}

export const signup = async (payload: SignUpPayload) => {
  return await request.put<ApiResponse>(
    `/user/signup${payload.refCode ? `?ref=${payload.refCode}` : ""}`,
    payload.data
  );
};

export const signin = async (payload: signinPayload) => {
  return await request.post<SignInRes>(`/user/signin`, payload);
};

export const forgotPassword = async (payload: forgotPasswordPayload) => {
  return await request.post<ApiResponse>(`/user/forgot-password`, payload);
};

export const getChangePasswordCode = async () => {
  return await request.get<ApiResponse>(`/user/get-change-password-code`);
};

import React, {
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import { RiSendPlaneFill } from "react-icons/ri";
import Colors from "../../../../styles/Colors";
// import { GrMicrophone } from "react-icons/gr";
import Markdown from "markdown-to-jsx";
import Spinner from "../../../../utils/Spinner";
import { saveInterview } from "../../../../api/ai";
// import { TfiTrash } from "react-icons/tfi";
import AiDisclaimer from "../../components/AiDisclaimer";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../store/userData";
import {
  useDeleteInterviewHistory,
  useGetInterviewHistory,
  // useGetInterviewHistory,
} from "../../../../hooks/utils/useUtils";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import FiBiAuth from "./FiBiAuth";
import { VscDebugRestart } from "react-icons/vsc";
// import { PiSpeakerHighBold } from "react-icons/pi";
// import { IoIosPause } from "react-icons/io";

enum MessageSender {
  user = "user",
  bot = "bot",
}

interface Message {
  message: string;
  sender: MessageSender;
}

const FibiAi = () => {
  const { data: historyData, isFetching: historyLoading } =
    useGetInterviewHistory();
  const { mutate: deleteMutate, isLoading: deleteLoading } =
    useDeleteInterviewHistory();
  const { userData } = useSnapshot(userDataStore);
  // const [sessionId, setSessionId] = useState<string>("");
  const [input, setInput] = useState<string>("");
  const [chatHistory, setChatHistory] = useState<Message[]>([]);
  const [prevHistory, setPrevHistory] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  // const [isSpeaking, setIsSpeaking] = useState(false);
  // const [speakText, setSpeakText] = useState<string>();
  const isLoggedIn = useIsLoggedIn();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatHistory.length]);

  const sendMessage = async (tempData?: string) => {
    if (!userData) return;
    const temp = tempData || input;
    if (temp.trim()) {
      const newMessage: Message = {
        message: temp.trim(),
        sender: MessageSender.user,
      };
      setChatHistory([...chatHistory, newMessage]);
      setInput("");
      setLoading(true);
      try {
        const result = await saveInterview({
          id: userData?.userInfo.userId,
          payload: {
            prompt: temp,
          },
        });

        const aiResponse = {
          sender: MessageSender.bot,
          message: result.data.result,
        };
        setChatHistory((prev) => [...prev, aiResponse]);
      } catch (error) {
        const errorMessage = {
          sender: MessageSender.bot,
          message:
            "We're experiencing a temporary hiccup. Feel free to retry your request. If you still encounter problems, our support team at connect@seamlessvisa.com is here for you.",
        };
        setChatHistory((prev) => [...prev, errorMessage]);
        console.error("Error generating response:", error);
      } finally {
        setLoading(false);
        setInput("");
      }
    }
  };

  const recognition = useMemo(() => {
    if ("webkitSpeechRecognition" in window) {
      const recognition = new (window as any).webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.lang = "en-US";
      return recognition;
    }
    return null;
  }, []);

  // const startListening = () => {
  //   if (recognition) {
  //     recognition.start();
  //     setIsListening(true);
  //   }
  // };

  // const stopListening = () => {
  //   if (recognition) {
  //     recognition.abort();
  //     setIsListening(false);
  //   }
  // };

  const onSendVoice = () => {
    if (recognition) {
      recognition.onresult = (event: any) => {
        const transcript = event.results[0][0].transcript;
        setIsListening(false);
        sendMessage(transcript);
      };
    }
  };

  // let timeoutResumeInfinity: any;

  // function resumeInfinity() {
  //   window.speechSynthesis.resume();
  //   timeoutResumeInfinity = setTimeout(resumeInfinity, 1000);
  // }

  // const speakResponse = (text: string) => {
  //   if (isSpeaking) {
  //     stopSpeaking();
  //   }
  //   if ("speechSynthesis" in window) {
  //     const utterance = new SpeechSynthesisUtterance(text);
  //     utterance.onstart = () => {
  //       setSpeakText(text);
  //       setIsSpeaking(true);
  //       // resumeInfinity();
  //     };
  //     utterance.onend = () => {
  //       setSpeakText(undefined);
  //       setIsSpeaking(false);
  //       // clearTimeout(timeoutResumeInfinity);
  //     };
  //     speechSynthesis.speak(utterance);
  //   }
  // };

  // const stopSpeaking = () => {
  //   if ("speechSynthesis" in window) {
  //     speechSynthesis.cancel();
  //     setIsSpeaking(false);
  //     setSpeakText(undefined);
  //   }
  // };
  useEffect(() => {
    if (historyLoading || (historyData && historyData?.length > 0)) return;
    const fetch = async () => {
      setLoading(true);
      try {
        const result = await saveInterview({
          id: "",
          payload: {
            prompt: "",
          },
        });

        const aiResponse = {
          sender: MessageSender.bot,
          message: result.data.result,
        };
        // setSessionId(result.data.id);
        setChatHistory((prev) => [...prev, aiResponse]);
      } catch (error) {
        const errorMessage = {
          sender: MessageSender.bot,
          message:
            "We're experiencing a temporary hiccup. Feel free to retry your request. If you still encounter problems, our support team at connect@seamlessvisa.com is here for you.",
        };
        setChatHistory((prev) => [...prev, errorMessage]);
        console.error("Error generating response:", error);
      } finally {
        setLoading(false);
        setInput("");
      }
    };
    fetch();
    // }, []);
  }, [historyData, historyLoading]);

  useEffect(() => {
    if (typeof historyData === "string") return;
    const prevTemp = [];

    const modelArray = historyData?.find((ev) => ev.role === "model");

    const userArrayTemp = historyData?.find((ev) => ev.role === "user");
    const userArray = {
      ...userArrayTemp,
      parts: userArrayTemp?.parts.filter((ev) => ev.text) || [],
    };
    const arrayLength = Math.max(
      modelArray?.parts.length || 0,
      userArray?.parts.length || 0
    );

    // const longerArray = modelArray?.parts.length === arrayLength ? modelArray?.parts : userArray?.parts.length

    for (let index = 0; index < arrayLength; index++) {
      const modelRes = modelArray?.parts[index]?.text;

      prevTemp.push({
        sender: MessageSender.bot,
        message: modelRes || "",
      });
      //
      const userRes = userArray?.parts[index]?.text;
      if (userRes) {
        prevTemp.push({
          sender: MessageSender.user,
          message: userRes || "",
        });
      }
    }

    setPrevHistory(prevTemp);
  }, [historyData]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  const onRestart = () => {
    deleteMutate();
    setPrevHistory([]);
    setChatHistory([]);
  };

  const masterLoading =
    loading || isListening || historyLoading || deleteLoading;
  // const masterLoading = loading || isListening || deleteLoading;
  // loading || isListening || historyLoading || deleteLoading;

  // Function to handle key down events in the textarea
  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        return;
      } else {
        event.preventDefault();
        sendMessage();
      }
    }
  };

  return (
    <Wrapper>
      <Constaint>
        <Container>
          {/* <button
            className="mb-2 font-bold text-left text-primaryColor w-max"
            onClick={onRestart}
            disabled={deleteLoading}
          >
            {deleteLoading ? <Spinner size={16} /> : "Restart interview"}
          </button> */}

          <MiniContainer>
            {!isLoggedIn && <FiBiAuth />}

            <Body ref={chatContainerRef}>
              {(prevHistory.length > 0
                ? prevHistory.concat(chatHistory)
                : chatHistory
              ).map((message, index) => (
                <div
                  key={index}
                  className={`mb-4 flex ${
                    message.sender === "user" ? "justify-end" : ""
                  }`}
                >
                  <span
                    className={`inline-block p-2 rounded-3xl relative ${
                      message.sender === "user"
                        ? "inline-block max-w-[70%] px-4 py-3 rounded-tr-[4px] bg-[#0276E9] text-white"
                        : "bg-white shadow px-4 py-3 border border-gray-300 text-gray-600 rounded-tl-[4px] mb-4 max-w-[85%]"
                    }`}
                  >
                    <Markdown>{message.message}</Markdown>

                    {/* {message.sender === MessageSender.bot ? (
                    message.message === speakText ? (
                      <IoIosPause
                        size={16}
                        className="ml-auto cursor-pointer"
                        onClick={() => stopSpeaking()}
                      />
                    ) : (
                      <PiSpeakerHighBold
                        size={16}
                        className="ml-auto cursor-pointer"
                        onClick={() => speakResponse(message.message)}
                      />
                    )
                  ) : message.message === speakText ? (
                    <IoIosPause
                      size={16}
                      onClick={() => stopSpeaking()}
                      className="cursor-pointer"
                    />
                  ) : (
                    <PiSpeakerHighBold
                      size={16}
                      onClick={() => speakResponse(message.message)}
                      className="cursor-pointer"
                    />
                  )} */}
                  </span>
                </div>
              ))}
            </Body>
            <Footer
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            >
              <Btn
                bg={Colors.RedE3}
                onClick={onRestart}
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <Spinner size={24} color="white" />
                ) : (
                  <VscDebugRestart size={24} color="white" />
                )}
              </Btn>
              <Input
                className="flex-1 w-full border-none rounded-md"
                rows={1}
                placeholder={isListening ? "Recording..." : "Message FiBi"}
                onChange={handleChange}
                disabled={masterLoading}
                value={input}
                onKeyDown={handleKeyDown}
              />
              {/* {isListening && (
              <TfiTrash
                size={24}
                color={"red"}
                className="self-center flex-shrink-0 cursor-pointer"
                onClick={() => {
                  stopListening();
                }}
              />
            )}
            <GrMicrophone
              size={24}
              color={isListening ? "blue" : undefined}
              className="self-center flex-shrink-0"
              onClick={() => {
                if (!isListening) {
                  startListening();
                }
              }}
            /> */}
              <Btn
                disabled={
                  !(!isListening ? !!input.trim() : true) || masterLoading
                }
                // isActive={!isListening ? !!input.trim() : true}
                onClick={() => (isListening ? onSendVoice() : sendMessage())}
              >
                {loading || isListening || historyLoading ? (
                  <Spinner size={24} color="white" />
                ) : (
                  <RiSendPlaneFill color="white" size={24} />
                )}
              </Btn>
            </Footer>
            <AiDisclaimer />
          </MiniContainer>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default FibiAi;

const Wrapper = styled.div`
  background-color: #f9fcff;
  background-color: white;
`;

const Container = styled.div`
  /* padding: 100px 0; */
  padding: 50px 0;
`;

const MiniContainer = styled.div`
  height: calc(100vh - 200px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  gap: 24px;
`;

const Body = styled.div`
  overflow-y: auto;
  border: 1px solid #b3bac1;
  border-radius: 8px;
  background-color: #f9fcff;
  padding: 24px;
`;

// const Input = styled.input`
//   height: 100%;
//   background-color: transparent;
//   width: 100%;
//   padding: 0.5rem;
//   &:focus-visible {
//     outline: 0;
//   }
// `;

const Input = styled.textarea`
  width: 100%;
  min-height: 100%;
  max-height: 6.5em;
  /* overflow-y: hidden; */
  resize: none;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  background: none !important;
`;

const Footer = styled.form`
  min-height: 56px;
  border: 1px solid #b3bac1;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  gap: 12px;
  /* position: sticky;
  bottom: 0;
  left: 0; */
`;

interface BtnProps {
  bg?: string;
}

const Btn = styled.button<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) => (bg ? bg : Colors.Blue00)};
  width: 48px;
  height: 48px;
  border-radius: 4px;
  flex-shrink: 0;

  &:disabled {
    background-color: #b3bac1;
  }
`;

import React, { FC } from "react";
import {
  saveDS160WETAdditionalObj,
  saveDS160WETPresentObj,
  saveDS160WETPreviousObj,
} from "../../../../../../api/ds160/types";
import ReviewBox, {
  ReviewData,
  ReviewSectionChildrenData,
} from "../../../../components/Review/ReviewBox";
import { reviewDoesNotApply } from "../../../../components/data";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: {
    present: saveDS160WETPresentObj;
    previous: saveDS160WETPreviousObj;
    additional: saveDS160WETAdditionalObj;
  };
}

const ReviewWETAdditional: FC<Props> = ({ title, data, isDownload }) => {
  const sectionA: ReviewSectionChildrenData[] = [
    {
      label: "Primary Occupation",
      value: data?.present?.primaryOccupation,
    },
    {
      label: "Present employer or school name",
      value: data?.present?.presentEmployerOrSchoolName,
    },
    {
      label: "Street Address Line 1",
      value: data?.present?.streetAddressLine1,
    },
    {
      label: "Street Address Line 2 (Optional)",
      value: data?.present?.streetAddressLine2,
    },
    {
      label: "City",
      value: data?.present?.city,
    },
    {
      label: "State/Province",
      value: data?.present?.stateOrProvince,
    },
    {
      label: "Postal Code/Zip Code",
      value: data?.present?.zipCode,
    },
    {
      label: "Phone Number",
      value: data?.present?.phoneNumber,
    },
    {
      label: "Country/Region",
      value: data?.present?.countryOrRegion,
    },
    {
      label: "Start Date",
      value: data?.present?.startDate,
    },
    {
      label: "Monthly income in local currency",
      value: reviewDoesNotApply(
        data?.present?.monthlyIncome,
        data?.present.doesNotApply
      ),
    },
    {
      label: "Briefly describe your duties",
      value: data?.present?.dutiesDescription,
    },
  ];

  const sectionB: ReviewSectionChildrenData[] = [
    {
      label: "Were you previously employed?",
      value: data?.previous?.wereYouPreviouslyEmployed,
    },
    {
      label: "Employer Name",
      value: data?.previous?.employerName,
    },
    {
      label: "Street Address Line 1",
      value: data?.previous?.employerStreetAddressLine1,
    },
    {
      label: "Street Address Line 2 (Optional)",
      value: data?.previous?.employerStreetAddressLine2,
    },
    {
      label: "City",
      value: data?.previous?.city,
    },
    {
      label: "State or Province",
      value: data?.previous?.stateOrProvince,
    },
    {
      label: "Postal Code/Zip Code",
      value: data?.previous?.zipCode,
    },
    {
      label: "Phone number",
      value: data?.previous?.phoneNumber,
    },
    {
      label: "Country/Region",
      value: data?.previous?.countryOrRegion,
    },
    {
      label: "Job title",
      value: data?.previous?.jobTitle,
    },
    {
      label: "Supervisor's Surname",
      value: data?.previous?.supervisorSurname,
    },
    {
      label: "Supervisor's Given Name",
      value: data?.previous?.supervisorGivenNames,
    },
    {
      label: "Employment Date from",
      value: data?.previous?.employmentDateFrom,
    },
    {
      label: "Employment Date to",
      value: data?.previous?.employmentDateTo,
    },
    {
      label: "Briefly Describe your duties",
      value: data?.previous?.dutiesDescription,
    },
  ];

  const sectionC: ReviewSectionChildrenData[] = [
    {
      label: "Do you belong to a Clan or Tribe?",
      value: data?.additional?.belongToClanOrTribe,
    },
    {
      label: "Clan or Tribe Name",
      value: data?.additional?.tribeName,
    },
    {
      label: "Provide a list of the Languages you speak`",
      value: data?.additional?.languages,
    },
    {
      label:
        "Have you traveled to any countries/region within the last 5years?",
      value: data?.additional?.travelToCountriesOrRegions,
    },
    {
      label: "Country/Region",
      value: data?.additional?.countryOrRegion,
    },
    {
      label:
        "Have you ever belonged to, contributed to, or worked for any professional, social or charitable organization?",
      value: data?.additional?.belongToCharitableOrg,
    },
    {
      label: "Organisation Name",
      value: data?.additional?.organizationName,
    },
    {
      label:
        "Do you have any specialised skills or training such as firearms explosives no clear biological or chemical experience?",
      value: data?.additional?.anySpecialisedSkills,
    },
    {
      label: "Other Status? explain",
      value: data?.additional?.skillExplanation,
    },
    {
      label: "Have you ever served in the military?",
      value: data?.additional?.everServedInTheMilitary,
    },
    {
      label: "Name of Country/Region",
      value: data?.additional?.militaryCountryOrRegion,
    },
    {
      label: "Branch Of Service",
      value: data?.additional?.branchOfService,
    },
    {
      label: "Rank/Position",
      value: data?.additional?.rank,
    },
    {
      label: "Military Specialty",
      value: data?.additional?.militarySpecialty,
    },
    {
      label: "Date of Service From",
      value: data?.additional?.dateOfServiceFrom,
    },
    {
      label: "Date of Service to",
      value: data?.additional?.dateOfServiceTo,
    },
  ];

  const section: ReviewData[] = [
    {
      section: {
        title: "Present",
        children: sectionA,
      },
    },
    {
      section: {
        title: "Previous",
        children: sectionB,
      },
    },
    {
      section: {
        title: "Additional",
        children: sectionC,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.WETPresent,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewWETAdditional;

import { ApiResponse } from "../../types/types";
import { saveDS160MaritalStatusObj } from "../../views/web/form ds 160/components/FamilyRelatives/MaritalStatus";
import { GetFileDetailObj } from "../n400/types";
import request from "../request";
import {
  saveDS160AddressAndPhoneObj,
  saveDS160FamilyObj,
  saveDS160PassportObj,
  saveDS160PersonalInfo2Obj,
  saveDS160PersonalInfoObj,
  saveDS160PreviousUSTravelObj,
  saveDS160TravelCompanionsObj,
  saveDS160TravelObj,
  saveDS160USContactObj,
  saveDS160WETPresentObj,
  saveDS160WETPreviousObj,
  saveDS160WETAdditionalObj,
  saveDS160SEVAdditionalContactObj,
  saveDS160SivesObj,
  saveDS160SecurityBackgroundP1Obj,
  saveDS160SecurityBackgroundP2Obj,
  saveDS160SecurityBackgroundP3Obj,
  saveDS160SecurityBackgroundP4Obj,
  saveDS160SecurityBackgroundP5Obj,
  GetDs160Obj,
  GetDS160GetStartedRes,
  DS160EligibilityPayload,
  payDS160Data,
  UpdateDS160FilesPayload,
  SaveDS160VistorEmailPayload,
} from "./types";

export const saveDS160GetStarted = async (payload: FormData) => {
  return await request.post<ApiResponse>(`/user/ds160-get-started`, payload);
};

export const getDS160GetStarted = async () => {
  return await request.get<ApiResponse<GetDS160GetStartedRes>>(
    `/user/get-ds160-get-started`
  );
};

export const saveDS160PersonalInfo = async (
  payload: saveDS160PersonalInfoObj
) => {
  return await request.post<ApiResponse>(`/user/personal-info`, payload);
};

export const getDS160PersonalInfo = async () => {
  return await request.get<ApiResponse<saveDS160PersonalInfoObj>>(
    `/user/get-personal-info`
  );
};

export const saveDS160PersonalInfo2 = async (
  payload: saveDS160PersonalInfo2Obj
) => {
  return await request.post<ApiResponse>(`/user/personal-info-2`, payload);
};

export const getDS160PersonalInfo2 = async () => {
  return await request.get<ApiResponse<saveDS160PersonalInfo2Obj>>(
    `/user/get-personal-info-2`
  );
};

export const saveDS160Travel = async (payload: saveDS160TravelObj) => {
  return await request.post<ApiResponse>(`/user/travel`, payload);
};

export const getDS160Travel = async () => {
  return await request.get<ApiResponse<saveDS160TravelObj>>(`/user/get-travel`);
};

export const saveDS160TravelCompanions = async (
  payload: saveDS160TravelCompanionsObj
) => {
  return await request.post<ApiResponse>(`/user/travel-companions`, payload);
};

export const getDS160TravelCompanions = async () => {
  return await request.get<ApiResponse<saveDS160TravelCompanionsObj>>(
    `/user/get-travel-companions`
  );
};

export const saveDS160PreviousUSTravel = async (
  payload: saveDS160PreviousUSTravelObj
) => {
  return await request.post<ApiResponse>(`/user/previous-us-travel`, payload);
};

export const getDS160PreviousUSTravel = async () => {
  return await request.get<ApiResponse<saveDS160PreviousUSTravelObj>>(
    `/user/get-previous-us-travel`
  );
};

export const saveDS160AddressAndPhone = async (
  payload: saveDS160AddressAndPhoneObj
) => {
  return await request.post<ApiResponse>(`/user/address-and-phone`, payload);
};

export const getDS160AddressAndPhone = async () => {
  return await request.get<ApiResponse<saveDS160AddressAndPhoneObj>>(
    `/user/get-address-and-phone`
  );
};

export const saveDS160Passport = async (payload: saveDS160PassportObj) => {
  return await request.post<ApiResponse>(`/user/passport`, payload);
};

export const getDS160Passport = async () => {
  return await request.get<ApiResponse<saveDS160PassportObj>>(
    `/user/get-passport`
  );
};

export const saveDS160USContact = async (payload: saveDS160USContactObj) => {
  return await request.post<ApiResponse>(`/user/us-contact`, payload);
};

export const getDS160USContact = async () => {
  return await request.get<ApiResponse<saveDS160USContactObj>>(
    `/user/get-us-contact`
  );
};

export const saveDS160Family = async (payload: saveDS160FamilyObj) => {
  return await request.post<ApiResponse>(`/user/family`, payload);
};

export const getDS160Family = async () => {
  return await request.get<ApiResponse<saveDS160FamilyObj>>(`/user/get-family`);
};

export const saveDS160WETPresent = async (payload: saveDS160WETPresentObj) => {
  return await request.post<ApiResponse>(`/user/wet-present`, payload);
};

export const getDS160WETPresent = async () => {
  return await request.get<ApiResponse<saveDS160WETPresentObj>>(
    `/user/get-wet-present`
  );
};

export const saveDS160WETPrevious = async (
  payload: saveDS160WETPreviousObj
) => {
  return await request.post<ApiResponse>(`/user/wet-previous`, payload);
};

export const getDS160WETPrevious = async () => {
  return await request.get<ApiResponse<saveDS160WETPreviousObj>>(
    `/user/get-wet-previous`
  );
};

export const saveDS160WETAdditional = async (
  payload: saveDS160WETAdditionalObj
) => {
  return await request.post<ApiResponse>(`/user/wet-additional`, payload);
};

export const getDS160WETAdditional = async () => {
  return await request.get<ApiResponse<saveDS160WETAdditionalObj>>(
    `/user/get-wet-additional`
  );
};

export const saveDS160SEVAdditionalContact = async (
  payload: saveDS160SEVAdditionalContactObj
) => {
  return await request.post<ApiResponse>(
    `/user/sev-additional-contact
`,
    payload
  );
};

export const getDS160SEVAdditionalContact = async () => {
  return await request.get<ApiResponse<saveDS160SEVAdditionalContactObj>>(
    `/user/get-sev-additional-contact
`
  );
};

export const saveDS160Sives = async (payload: saveDS160SivesObj) => {
  return await request.post<ApiResponse>(
    `/user/sevis
`,
    payload
  );
};

export const getDS160Sives = async () => {
  return await request.get<ApiResponse<saveDS160SivesObj>>(
    `/user/get-sevis
`
  );
};

export const saveDS160SecurityBackgroundP1 = async (
  payload: saveDS160SecurityBackgroundP1Obj
) => {
  return await request.post<ApiResponse>(
    `user/security-part-1
`,
    payload
  );
};

export const getSecurityBackgroundP1 = async () => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP1Obj>>(
    `user/get-security-part-1
`
  );
};

export const saveDS160SecurityBackgroundP2 = async (
  payload: saveDS160SecurityBackgroundP2Obj
) => {
  return await request.post<ApiResponse>(
    `user/security-part-2
`,
    payload
  );
};

export const getSecurityBackgroundP2 = async () => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP2Obj>>(
    `user/get-security-part-2
`
  );
};

export const saveDS160SecurityBackgroundP3 = async (
  payload: saveDS160SecurityBackgroundP3Obj
) => {
  return await request.post<ApiResponse>(
    `user/security-part-3
`,
    payload
  );
};

export const getSecurityBackgroundP3 = async () => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP3Obj>>(
    `user/get-security-part-3
`
  );
};

export const saveDS160SecurityBackgroundP4 = async (
  payload: saveDS160SecurityBackgroundP4Obj
) => {
  return await request.post<ApiResponse>(
    `user/security-part-4
`,
    payload
  );
};

export const getSecurityBackgroundP4 = async () => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP4Obj>>(
    `user/get-security-part-4
`
  );
};

export const saveDS160SecurityBackgroundP5 = async (
  payload: saveDS160SecurityBackgroundP5Obj
) => {
  return await request.post<ApiResponse>(
    `user/security-part-5
`,
    payload
  );
};

export const getSecurityBackgroundP5 = async () => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP5Obj>>(
    `user/get-security-part-5`
  );
};

export const getDS160Info = async () => {
  return await request.get<ApiResponse<GetDs160Obj>>(`user/get-ds-160-info`);
};

export const saveDS160Eligibility = async (
  payload: DS160EligibilityPayload
) => {
  return await request.post<ApiResponse>(`/user/ds-160-eligibility`, payload);
};

export const getDS160Eligibility = async () => {
  return await request.get<ApiResponse<DS160EligibilityPayload>>(
    `/user/get-ds-160-eligibility`
  );
};

export const payDS160 = async (payload: payDS160Data) => {
  return await request.post<ApiResponse>(`/user/ds160-payment`, payload);
};

export const getDS160Form = async () => {
  return await request.get<ApiResponse>(`/user/get-ds-160-application`, {});
};

export const getDS160PaySuccess = async (payId: string) => {
  return await request.get<ApiResponse>(`user/ds160-payment-success/${payId}`);
};

export const getDS160PayFail = async () => {
  return await request.get<ApiResponse>(`/user/ds160-payment-cancel`);
};

export const uploadDS160Files = async (payload: FormData) => {
  return await request.post<ApiResponse>(`/user/upload-ds160-files`, payload);
};

export const getDS160Files = async () => {
  return await request.get<ApiResponse<GetFileDetailObj[]>>(
    `/user/get-upload-ds160-files`
  );
};

export const updateDS160Files = async (payload: UpdateDS160FilesPayload) => {
  return await request.post<ApiResponse>(
    `/user/update-ds160-files/${payload.id}/${payload.formCategory}`,
    payload.data
  );
};

export const saveDS160VistorEmail = async (
  payload: SaveDS160VistorEmailPayload
) => {
  return await request.post<ApiResponse>(`user/visitor-email`, payload);
};

export const getDS160Trigger = async () => {
  return await request.get(`/user/download-ds160-application`);
};

export const getDS160MaritalStatus = async () => {
  return await request.get<ApiResponse<saveDS160MaritalStatusObj>>(
    `/user/get-ds160-marital-status`
  );
};

export const saveDS160MaritalStatus = async (
  payload: saveDS160MaritalStatusObj
) => {
  return await request.post<ApiResponse>(`/user/ds160-marital-status`, payload);
};

import { useNavigate } from "react-router-dom";
import "./auth.css";
import { WebPaths } from "../../../../routes/data";
import { useState } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { userDataStore } from "../../../../store/userData";
import {
  accessTokenStore,
  seamlessAccessToken,
  seamlessRefreshToken,
} from "../../../../store/accessToken";
import storage from "../../../../utils/storage";
import Modal from "../../components/Modal";
import AuthRightSession from "./AuthRightSession";
import { Form, Formik } from "formik";
import InputField from "../../components/formik fields/InputField";
import { LoginValidation } from "./validation";
import { useSignin } from "../../../../hooks/auth/useAuth";
import Spinner from "../../../../utils/Spinner";
import Constaint from "../../layout/components/Constaint";
import ForgotPassword from "./ForgotPassword";

interface FormData {
  email: string;
  password: string;
  agreement: boolean;
}

const Login = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useSignin();
  const [isVerified, setIsVerified] = useState<boolean>(true);
  const [forgotPass, setForgotPass] = useState<boolean>(false);

  const onSubmit = (values: FormData) => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    mutate(payload, {
      onSuccess: (data) => {
        if (data.status === 206) {
          setIsVerified(false);
          return;
        }
        //store in store
        userDataStore.userData = data.data.userData;

        const accessToken = data.data.accessToken;
        const refreshToken = data.data.refreshToken;
        //store in store
        accessTokenStore.accessToken = accessToken;
        accessTokenStore.refreshToken = refreshToken;
        //store in local storage
        storage.set(seamlessAccessToken, accessToken);
        storage.set(seamlessRefreshToken, refreshToken);
      },
    });
  };

  return (
    <Constaint>
      <div className="flex items-center justify-center">
        <div className="flex mx-auto w-full">
          <div className="p-5 sm:p-16 md:flex-[0.5] w-full">
            <h2 className="mb-8 text-2xl font-semibold text-center">Login</h2>
            <Formik
              initialValues={
                {
                  email: "",
                  password: "",
                  // agreement: false,
                } as FormData
              }
              onSubmit={onSubmit}
              validationSchema={LoginValidation}
            >
              <Former>
                <InputField name="email" label="Email" placeholder="" />

                <InputField
                  name="password"
                  label="Password"
                  placeholder=""
                  inputType="password"
                />

                {/* <CheckBoxField
                label={
                  <>
                    By checking this box, you confirm that you've read and
                    accepted our{" "}
                    <span className="text-[#0076E9]">Terms of Service</span> and{" "}
                    <span className="text-[#0076E9]">Privacy Policy.</span>
                  </>
                }
                name="agreement"
              /> */}

                <SigninBtn disabled={isLoading}>
                  {isLoading && <Spinner color={Colors.White} size={14} />}
                  Login
                </SigninBtn>
              </Former>
            </Formik>

            <ResetPass onClick={() => setForgotPass(true)}>
              Forgot password?
            </ResetPass>

            <p className="my-5 text-center">
              Don’t have an account?{" "}
              <button
                onClick={() => navigate(WebPaths.Join)}
                className="text-[#0076E9]"
              >
                Sign up
              </button>
            </p>
          </div>

          <AuthRightSession />
        </div>

        {!isVerified && <Modal onClose={() => setIsVerified(!isVerified)} />}
        {forgotPass && <ForgotPassword onClose={() => setForgotPass(false)} />}
      </div>
    </Constaint>
  );
};
export default Login;

const SigninBtn = styled.button`
  padding: 0.8rem 0;
  text-align: center;
  background-color: ${Colors.Blue00};
  width: 100%;
  border-radius: 0.6rem;
  margin-top: 1rem;

  transition: 0.3s all ease;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    background: #022e58;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${Colors.GreyA7};
  }
`;

const Former = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ResetPass = styled.div`
  font-weight: 500;
  color: ${Colors.Blue00};
  text-align: right;
  font-size: 0.85rem;

  width: max-content;
  margin: 1rem 0 0;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`;

import styled from "styled-components";
import { useGetChangePasswordCode } from "../../../../../../hooks/auth/useAuth";
import InputField from "../../../../components/formik fields/InputField";
import Spinner from "../../../../../../utils/Spinner";

const PasswordCode: React.FC = () => {
  const { isLoading: gettingCode, isError } = useGetChangePasswordCode();
  return (
    <FormWrapper>
      {gettingCode && <Spinner />}
      {!gettingCode && isError ? (
        <ErrText>Error sending code.</ErrText>
      ) : (
        <InputField name="code" label="Code sent to your mail" placeholder="" />
      )}
    </FormWrapper>
  );
};

export default PasswordCode;

const FormWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ErrText = styled.p`
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
`;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ds160QueryKeys } from "./types";
import {
  getDS160AddressAndPhone,
  getDS160Family,
  getDS160Passport,
  getDS160PersonalInfo,
  getDS160PersonalInfo2,
  getDS160PreviousUSTravel,
  getDS160Travel,
  getDS160TravelCompanions,
  getDS160USContact,
  saveDS160AddressAndPhone,
  saveDS160Family,
  saveDS160Passport,
  getDS160WETPresent,
  getDS160WETPrevious,
  getDS160WETAdditional,
  getDS160SEVAdditionalContact,
  getDS160Sives,
  getSecurityBackgroundP1,
  getSecurityBackgroundP2,
  getSecurityBackgroundP3,
  getSecurityBackgroundP4,
  getSecurityBackgroundP5,
  saveDS160PersonalInfo,
  saveDS160PersonalInfo2,
  saveDS160PreviousUSTravel,
  saveDS160Travel,
  saveDS160TravelCompanions,
  saveDS160USContact,
  saveDS160WETPresent,
  saveDS160WETPrevious,
  saveDS160WETAdditional,
  saveDS160SEVAdditionalContact,
  saveDS160Sives,
  saveDS160SecurityBackgroundP1,
  saveDS160SecurityBackgroundP2,
  saveDS160SecurityBackgroundP3,
  saveDS160SecurityBackgroundP4,
  saveDS160SecurityBackgroundP5,
  getDS160Info,
  saveDS160GetStarted,
  getDS160GetStarted,
  saveDS160Eligibility,
  getDS160Eligibility,
  payDS160,
  getDS160Form,
  getDS160PaySuccess,
  getDS160PayFail,
  uploadDS160Files,
  getDS160Files,
  updateDS160Files,
  saveDS160VistorEmail,
  getDS160Trigger,
  getDS160MaritalStatus,
  saveDS160MaritalStatus,
} from "../../api/ds160";
import { downloadPdf, parseError } from "../../utils";
import { utilsQueryKeys } from "../utils/types";

export const useSaveDS1600GetStarted = () => {
  return useMutation(saveDS160GetStarted, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160GetStarted = () => {
  return useQuery([ds160QueryKeys.getDS160GetStarted], getDS160GetStarted, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160PersonalInfo = () => {
  return useMutation(saveDS160PersonalInfo, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160PersonalInfo = () => {
  return useQuery([ds160QueryKeys.getDS160PersonalInfo], getDS160PersonalInfo, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160PersonalInfo2 = () => {
  return useMutation(saveDS160PersonalInfo2, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160PersonalInfo2 = () => {
  return useQuery(
    [ds160QueryKeys.getDS160PersonalInfo2],
    getDS160PersonalInfo2,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160Travel = () => {
  return useMutation(saveDS160Travel, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Travel = () => {
  return useQuery([ds160QueryKeys.getDS160Travel], getDS160Travel, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160TravelCompanions = () => {
  return useMutation(saveDS160TravelCompanions, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160TravelCompanions = () => {
  return useQuery(
    [ds160QueryKeys.getDS160TravelCompanions],
    getDS160TravelCompanions,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160PreviousUSTravel = () => {
  return useMutation(saveDS160PreviousUSTravel, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160PreviousUSTravel = () => {
  return useQuery(
    [ds160QueryKeys.getDS160PreviousUSTravel],
    getDS160PreviousUSTravel,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160AddressAndPhone = () => {
  return useMutation(saveDS160AddressAndPhone, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160AddressAndPhone = () => {
  return useQuery(
    [ds160QueryKeys.getDS160AddressAndPhone],
    getDS160AddressAndPhone,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160Passport = () => {
  return useMutation(saveDS160Passport, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Passport = () => {
  return useQuery([ds160QueryKeys.getDS160Passport], getDS160Passport, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160USContact = () => {
  return useMutation(saveDS160USContact, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160USContact = () => {
  return useQuery([ds160QueryKeys.getDS160USContact], getDS160USContact, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160Family = () => {
  return useMutation(saveDS160Family, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Family = () => {
  return useQuery([ds160QueryKeys.getDS160Family], getDS160Family, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160WETPresent = () => {
  return useMutation(saveDS160WETPresent, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160WETPresent = () => {
  return useQuery([ds160QueryKeys.getDS160WETPresent], getDS160WETPresent, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160WETPrevious = () => {
  return useMutation(saveDS160WETPrevious, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160WETPrevious = () => {
  return useQuery([ds160QueryKeys.getDS160WETPrevious], getDS160WETPrevious, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160SEVAdditionalContact = () => {
  return useMutation(saveDS160SEVAdditionalContact, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SEVAdditionalContact = () => {
  return useQuery(
    [ds160QueryKeys.getDS160SEVAdditionalContact],
    getDS160SEVAdditionalContact,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160Sives = () => {
  return useMutation(saveDS160Sives, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Sives = () => {
  return useQuery([ds160QueryKeys.getDS160Sives], getDS160Sives, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160WETAdditional = () => {
  return useMutation(saveDS160WETAdditional, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160WETAdditional = () => {
  return useQuery(
    [ds160QueryKeys.getDS160WETAdditional],
    getDS160WETAdditional,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP1 = () => {
  return useMutation(saveDS160SecurityBackgroundP1, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP1 = () => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP1],
    getSecurityBackgroundP1,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP2 = () => {
  return useMutation(saveDS160SecurityBackgroundP2, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP2 = () => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP2],
    getSecurityBackgroundP2,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP3 = () => {
  return useMutation(saveDS160SecurityBackgroundP3, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP3 = () => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP3],
    getSecurityBackgroundP3,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP4 = () => {
  return useMutation(saveDS160SecurityBackgroundP4, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP4 = () => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP4],
    getSecurityBackgroundP4,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP5 = () => {
  return useMutation(saveDS160SecurityBackgroundP5, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP5 = () => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP5],
    getSecurityBackgroundP5,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetDS160Info = (isEnabled?: boolean) => {
  return useQuery([ds160QueryKeys.getDs160Info], getDS160Info, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });
};

export const useSaveDS160Eligibility = () => {
  const queryClient = useQueryClient();

  return useMutation(saveDS160Eligibility, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
  });
};

export const useGetDS160Eligibility = (isEnabled?: boolean) => {
  return useQuery([ds160QueryKeys.getDS160Eligibility], getDS160Eligibility, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    enabled: isEnabled,
    refetchOnWindowFocus: false,
  });
};

export const usePayDS160 = () => {
  return useMutation(payDS160, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Form = (isEnabled?: boolean) => {
  return useQuery([ds160QueryKeys.getDS160Form], getDS160Form, {
    enabled: isEnabled,
    onError: (error) => parseError(error),
    onSuccess: (data) => {
      downloadPdf(data.data.fileBuffer.data, `ds160-form.pdf`);
    },
    // select: (data) => data.data.fileBuffer.data,
    // refetchOnWindowFocus: false,
  });
};

export const useGetDS160PaySuccess = (payId: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    [ds160QueryKeys.getDS160PaySuccess, payId],
    () => getDS160PaySuccess(payId),
    {
      enabled: !!payId,
      onSuccess: () =>
        queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetDS160PayFail = () => {
  const queryClient = useQueryClient();

  return useQuery([ds160QueryKeys.getDS160PayFail], getDS160PayFail, {
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useUploadDS160Files = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadDS160Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([ds160QueryKeys.getDS160Files]),
  });
};

export const useGetDS160Files = () => {
  return useQuery([ds160QueryKeys.getDS160Files], getDS160Files, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    // refetchOnWindowFocus: false,
  });
};

export const useUpdateDS160Files = () => {
  const queryClient = useQueryClient();

  return useMutation(updateDS160Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([ds160QueryKeys.getDS160Files]),
  });
};

export const useSaveDS160VistorEmail = () => {
  return useMutation(saveDS160VistorEmail, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Trigger = (isEnabled: boolean) => {
  return useQuery([ds160QueryKeys.getDS160Trigger], getDS160Trigger, {
    enabled: isEnabled,
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetDS160MaritalStatus = () => {
  return useQuery(
    [ds160QueryKeys.getDS160MaritalStatus],
    getDS160MaritalStatus,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160MaritalStatus = () => {
  return useMutation(saveDS160MaritalStatus, {
    onError: (error) => parseError(error),
  });
};

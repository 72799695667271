import Colors from "../../../../styles/Colors";
import Offer1 from "../../../../images/web/offer-image2.png";
import Offer2 from "../../../../images/web/offer-image1.png";
import Offer3 from "../../../../images/web/offer-image3.png";
import Avatar from "../../../../images/web/avatar.png";
import News from "../../../../images/web/news-image.png";
import { WebPaths } from "../../../../routes/data";
import { ReactComponent as GuidanceIcon } from "../../../../images/icons/expert-guidance.svg";
import { ReactComponent as SupportIcon } from "../../../../images/icons/customer-support.svg";
import { ReactComponent as AffordableIcon } from "../../../../images/icons/affordable-pricing.svg";
import { ReactComponent as ErrorChecksIcon } from "../../../../images/icons/automated-checks.svg";
import { ReactComponent as EfficiencyIcon } from "../../../../images/icons/efficiency.svg";
import { ReactComponent as UpdatesIcon } from "../../../../images/icons/realtime-updates.svg";
import { ReactComponent as AssessmentIcon } from "../../../../images/icons/assessment.svg";
import { ReactComponent as FormfillingIcon } from "../../../../images/icons/form-filling.svg";
import { ReactComponent as TailoredIcon } from "../../../../images/icons/guidance.svg";
import { ReactComponent as SubmissionIcon } from "../../../../images/icons/review.svg";
import { ReactNode } from "react";

export const OfferData = [
  {
    name: (
      <div>
        Citizenship{" "}
        <span
          style={{
            color: Colors.Blue00,
          }}
        >
          Visa
        </span>
      </div>
    ),
    subtext: "Simplify your U.S. Citizenship application process.",
    img: Offer1,
    url: `${WebPaths.Eligibility}/n400`,
  },
  {
    name: (
      <div>
        Student{" "}
        <span
          style={{
            color: Colors.Blue00,
          }}
        >
          Visa
        </span>
      </div>
    ),
    subtext:
      "Navigate your academic journey with ease. For international study in the U.S. ",
    img: Offer2,
    url: `${WebPaths.checkEligibility}/ds160`,
  },
  {
    name: (
      <div>
        Travel{" "}
        <span
          style={{
            color: Colors.Blue00,
          }}
        >
          Visa
        </span>
      </div>
    ),
    subtext: "Apply for your U.S. trip with confidence.",
    img: Offer3,
    url: `${WebPaths.checkEligibility}/ds160`,
  },
];

export const StepsData = [
  {
    name: "Affordable Pricing",
    subtext:
      "Our platform empowers you to navigate immigration for less. Get the same value as traditional attorneys, at a cost you can afford.",
    img: <AffordableIcon />,
    gridArea: "2 / 3 / 6 / 7",
    bg: "#F0FDF0",
    iconBg: "#D9FFD9",
    align: "center",
  },
  {
    name: "Automated Error System Checks",
    subtext:
      "Our integrated artificial intelligence system checks all errors while filling your forms to minimize mistakes and increase your chances of approval.",
    img: <ErrorChecksIcon />,
    gridArea: "6 / 3 / 10 / 7",
    bg: "#F0FDFB",
    iconBg: "#CEEDE8",
    align: "center",
  },
  {
    name: "Expert Guidance",
    subtext:
      "Immigration experts to guide and support you throughout your immigration journey. ",
    img: <GuidanceIcon />,
    gridArea: "1 / 1 / 5 / 3",
    bg: "#F0F7FD",
    iconBg: "#B7DBFF",
    align: "start",
  },
  {
    name: "Customer Support",
    subtext: "Need help? Our support team is here to guide you all the way. ",
    img: <SupportIcon />,
    gridArea: "5 / 1 / 11 / 3",
    bg: "#FFF2EB",
    iconBg: "#FFE6D9",
    align: "start",
  },

  {
    name: "Efficiency",
    subtext: "Save time and effort with a simplified application process.",
    img: <EfficiencyIcon />,
    gridArea: "1 / 7 / 7 / 9",
    bg: "#FDFDF0",
    iconBg: "#FFFFCF",
    align: "start",
  },
  {
    name: "Real-Time Updates",
    subtext: "Get real-time updates on your application process",
    img: <UpdatesIcon />,
    gridArea: "7 / 7 / 11 / 9",
    bg: "#F6F4FF",
    iconBg: "#E6E3FF",
    align: "start",
  },
];

export const ProcessData = [
  {
    name: "Assessment",
    subtext:
      "Answer simple questions online to confirm eligibility and get instant alerts if there is a problem.",
    img: <AssessmentIcon />,
  },
  {
    name: "Form Filling",
    subtext:
      "Effortlessly fill out USCIS forms with our platform, pay only upon completion. Receive a professionally assembled application ready for submission. No upfront charges. ",
    img: <FormfillingIcon />,
  },
  {
    name: "Tailored Guidance & Checklist",
    subtext:
      "We provide detailed filling instructions based on your responses to questions and a checklist of supporting documents to submit.",
    img: <TailoredIcon />,
  },
  {
    name: "Expert Review & Submission",
    subtext:
      "Enjoy peace of mind by having your application reviewed, assembled and submitted to USCIS by our immigration experts.",
    img: <SubmissionIcon />,
  },
];

export const TestimonialData = [
  {
    name: "Maria",
    location: "FROM UNITED STATES",
    text: "Your platform interface and detailed guidance made the citizen visa application process seamless.",
    img: Avatar,
  },
  {
    name: "Zack. S",
    location: "FROM UNITED STATES",
    text: "Filling out forms on this platform was easy. They were also available to assist when I needed them.",
    img: Avatar,
  },
  {
    name: "Stephen",
    location: "FROM UNITED STATES",
    text: "SeamlessVisa played a vital role in my citizenship application process. I got regular updates. Their immigration experts also ensured that I was well prepared every step of the way.",
    img: Avatar,
  },
  {
    name: "Samuel Okafor",
    location: "FROM UNITED STATES",
    text: "Outstanding service. I used them to apply for my student visa and I love how responsive they were.",
    img: Avatar,
  },
  // {
  //   name: "David Ejike",
  //   location: "FROM UNITED STATES",
  //   text: "“I couldn't have done it without SeamlessVisa! Their team of experts provided me with all the necessary support and guidance to secure my citizenship visa. The process was smooth and hassle-free, and I couldn't be happier with the results.”",
  //   img: Avatar,
  // },
  // {
  //   name: "David Ejike",
  //   location: "FROM UNITED STATES",
  //   text: "“I couldn't have done it without SeamlessVisa! Their team of experts provided me with all the necessary support and guidance to secure my citizenship visa. The process was smooth and hassle-free, and I couldn't be happier with the results.”",
  //   img: Handshake,
  // },
];

export const NewsData = [
  {
    name: "New Immigration Reform Bill Proposed to Provide Path to Citizenship for Undocumented Immigrants",
    img: News,
  },
  {
    name: "New Immigration Reform Bill Proposed to Provide Path to Citizenship for Undocumented Immigrants",
    img: News,
  },
  {
    name: "New Immigration Reform Bill Proposed to Provide Path to Citizenship for Undocumented Immigrants",
    img: News,
  },
];

export const AiChatSystemInstructions = `You are an AI customer support specialist hosted on SeamlessVisa.com. Your role is to assist users with U.S. immigration inquiries and questions related to SeamlessVisa's services. Respond professionally, like a U.S. immigration lawyer, providing accurate and concise information based on your knowledge.

Key Points:

Expertise: You possess extensive knowledge of U.S. immigration laws and SeamlessVisa's offerings.
Professionalism: Maintain a professional tone in all interactions.
Accuracy: Provide accurate information based on your knowledge base.
No Legal Advice: Do not offer legal advice. If a user's question requires legal counsel, direct them to consult with a licensed immigration attorney.
SeamlessVisa Promotion: When unable to answer a question, or when relevant, encourage users to contact SeamlessVisa directly for more information.
Redirect to SeamlessVisa: For inquiries unrelated to U.S. immigration or SeamlessVisa, gently guide users back to explore the information and resources available on SeamlessVisa.com.
Example Interactions:

User: "What are the requirements for a B-2 tourist visa?"

You: To be eligible for a B-2 tourist visa, you typically need to demonstrate strong ties to your home country, prove your intention to return after a temporary visit, and show you have sufficient funds to cover your trip. For detailed information and assistance with the application process, I recommend contacting SeamlessVisa directly.
User: "I'm having trouble logging into my SeamlessVisa account."

You: Please visit the SeamlessVisa website and use the "Forgot Password" feature to reset your password. If you continue to experience problems, their customer support team will be happy to assist you.
User: "How do I get a green card through marriage?"

You: The process for obtaining a green card through marriage involves multiple steps and specific requirements. While I cannot provide legal advice, I recommend contacting SeamlessVisa for detailed guidance on this process and to explore their services that may be helpful.
User: "What is the best restaurant in New York City?"

You: While I can't offer restaurant recommendations, you can find a wealth of information about U.S. visas and immigration on SeamlessVisa.com`;

interface CustomLinkProps {
  children: ReactNode;
  href: string;
  [key: string]: any;
}

export const CustomMDLink: React.FC<CustomLinkProps> = ({
  children,
  ...props
}) => (
  <a
    {...props}
    target="_blank"
    className="text-blue-600 font-bold no-underline hover:underline"
  >
    {children}
  </a>
);

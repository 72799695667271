import { useSnapshot } from "valtio";
import { userDataStore } from "../store/userData";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

const useIsPaid = () => {
  const { formCategory: urlFormCategory } = useParams();
  const { userData } = useSnapshot(userDataStore);
  const [isFormPaid, setIsFormPaid] = useState(false);

  const getIsPaid = useCallback(
    (formType?: string) => {
      if (!userData) return false;
      const result = userData.caseHistory.find(
        (ev) => (formType || urlFormCategory) === ev.caseType && ev.Payment
      );
      return result ? true : false;
    },
    [urlFormCategory, userData]
  );

  useEffect(() => {
    const result = getIsPaid();
    setIsFormPaid(result);
  }, [getIsPaid]);

  return { isFormPaid, getIsPaid };
};

export default useIsPaid;

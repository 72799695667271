import React, { FC } from "react";
import {
  saveDS160PersonalInfo2Obj,
  saveDS160PersonalInfoObj,
} from "../../../../../../api/ds160/types";
import ReviewBox, {
  ReviewData,
  ReviewSectionChildrenData,
} from "../../../../components/Review/ReviewBox";
import { reviewDoesNotApply } from "../../../../components/data";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";

interface Props extends GenericDs160ReviewProps {
  data?: {
    personalOne: saveDS160PersonalInfoObj;
    personalTwo: saveDS160PersonalInfo2Obj;
  };
}

const ReviewPersonal: FC<Props> = ({ title, data, isDownload }) => {
  const sectionA: ReviewSectionChildrenData[] = [
    {
      label: "Family Name (Last Name)",
      value: data?.personalOne?.lastName,
    },
    {
      label: "Given Names",
      value: data?.personalOne?.givenNames,
    },
    {
      label: "Full Name in Native Alphabet",
      value: reviewDoesNotApply(
        data?.personalOne?.fullName,
        data?.personalOne.doesNotApply
      ),
    },
    {
      label: "Have you used any other names since birth?",
      value: data?.personalOne?.anyOtherNames,
    },
    {
      label: "Given Name",
      value: data?.personalOne?.otherFirstName,
    },
    {
      label: "Middle Name",
      value: data?.personalOne?.otherMiddleName,
    },
    {
      label: "Family Name",
      value: data?.personalOne?.otherLastName,
    },
    {
      label: "Do you have a telecode that represents your name?",
      value: data?.personalOne?.teleCode,
    },
    {
      label: "Telecode Surname",
      value: data?.personalOne?.teleCodeSurname,
    },
    {
      label: "Telecode Given Names",
      value: data?.personalOne?.teleCodeGivenNames,
    },
    {
      label: "Sex",
      value: data?.personalOne?.sex,
    },
    // {
    //   label: "Marital status",
    //   value: data?.personalOne?.maritalStatus,
    // },
    {
      section: {
        title: "Birth Information",
        children: [
          {
            label: "State and Province",
            value: data?.personalOne?.stateAndProvince,
          },
          {
            label: "Date of birth",
            value: data?.personalOne?.dateOfBirth,
          },
          {
            label: "City",
            value: data?.personalOne?.city,
          },
          {
            label: "Country/Region",
            value: data?.personalOne?.countryOrRegion,
          },
        ],
      },
    },
  ];

  const sectionB: ReviewSectionChildrenData[] = [
    {
      label: "Country/Region of Origin (Nationality)",
      value: data?.personalTwo?.countryOrRegion,
    },
    {
      label:
        "Do you hold or have you held another nationality other than the one indicated above on nationality?",
      value: data?.personalTwo?.heldAnotherNationality,
    },
    {
      label:
        "Are you a permanent resident of a country/region other than your country/region of origin (nationality) indicated above?",
      value: data?.personalTwo?.prOfOtherCountry,
    },
    {
      label: "Other Country/Region of Origin (Nationality)",
      value: data?.personalTwo?.otherCountryOrRegion,
    },
    {
      label: "National Identification Number",
      value: reviewDoesNotApply(
        data?.personalTwo?.nin,
        data?.personalTwo.doesNotApplyNin
      ),
    },
    {
      label: "U.S Social Security Number",
      value: reviewDoesNotApply(
        data?.personalTwo?.SSN,
        data?.personalTwo.doesNotApplySSN
      ),
    },
    {
      label: "U.S Taxpayer ID Number",
      value: reviewDoesNotApply(
        data?.personalTwo?.idNumber,
        data?.personalTwo.doesNotApplyIdNumber
      ),
    },
  ];

  const section: ReviewData[] = [
    {
      section: {
        title: "Personal 1",
        children: sectionA,
      },
    },
    {
      section: {
        title: "Personal 2",
        children: sectionB,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.personalInfoP1,
    isActive: true,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewPersonal;

// src/components/AnimatedButton.tsx
import { useState } from "react";
import { motion } from "framer-motion";

interface AnimatedButtonProps {
  icon: React.ReactNode;
  text: string;
  isOpen: boolean;
}

const AnimatedButton: React.FC<AnimatedButtonProps> = ({
  icon,
  text,
  isOpen,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <motion.div
      className={`bg-[#0276E9] flex items-center justify-center p-5 rounded-full ${
        isHovered || isOpen ? "gap-x-2" : "animate-wiggle"
      } active:scale-[0.9] duration-150`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transition={{ duration: 0.4, ease: "easeInOut" }}
    >
      <motion.p
        initial={{ opacity: 0, width: 0 }}
        animate={{
          opacity: isHovered || isOpen ? 1 : 0,
          width: isHovered || isOpen ? "auto" : 0,
        }}
        transition={{ duration: 0.1, ease: "easeInOut" }}
        className="whitespace-nowrap select-none pointer-events-none"
      >
        {text}
      </motion.p>
      {icon}
    </motion.div>
  );
};

export default AnimatedButton;

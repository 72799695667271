import { motion } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import { OverlayVariants } from "./variant";
import { mediaObj } from "../../../../../styles/Media";

interface Props {
  children: JSX.Element;
  onClose: () => void;
}

const Overlay: FC<Props> = ({ children, onClose }) => {
  return (
    <Wrapper
      onClick={onClose}
      variants={OverlayVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      {children}
    </Wrapper>
  );
};

export default Overlay;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: rgba(27, 9, 44, 0.3);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaObj.smallDesktop} {
    width: 100%;
  }
`;

import React, { FC, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { mediaObj } from "../../../../styles/Media";
import { motion } from "framer-motion";
import Overlay from "../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../checkEligibilty/components/modals/variant";
import { ImCancelCircle } from "react-icons/im";
import CongratsImage from "../../../../images/web/congrats.svg";
import Colors from "../../../../styles/Colors";
import { Form, Formik } from "formik";
import { forgotPasswordPayload } from "../../../../api/auth/types";
import InputField from "../../components/formik fields/InputField";
import { useForgotPassword } from "../../../../hooks/auth/useAuth";
import Spinner from "../../../../utils/Spinner";
import { ResetPasswordValidation } from "./validation";

interface Props {
  onClose: () => void;
}

const ForgotPassword: FC<Props> = ({ onClose }) => {
  const { mutate, isLoading } = useForgotPassword();
  const [passwordSent, setPasswordSent] = useState<boolean>(false);

  const onSubmit = (values: forgotPasswordPayload) => {
    const payload = {
      email: values.email,
    };
    mutate(payload, {
      onSuccess: (data) => {
        // if (data.status === 500) {
        //   setInvalidEmail(true);
        //   return;
        // }

        setPasswordSent(true);
      },
    });
  };

  if (passwordSent) {
    return createPortal(
      <Overlay onClose={onClose}>
        <Wrapper
          onClick={(e) => e.stopPropagation()}
          variants={ContainerVariants}
        >
          <Cancel onClick={onClose}>
            <ImCancelCircle />
          </Cancel>
          <MainIcon>
            <img src={CongratsImage} alt="" />
          </MainIcon>
          <ContentWrapper>
            <TextBold>Check your email!</TextBold>
            <Text>
              A new password has been sent to you, for login, then you can
              change it in your account seetings.
              <br />
              {/* Enter your email address below. */}
              <br />
            </Text>
          </ContentWrapper>
        </Wrapper>
      </Overlay>,
      document.body
    );
  }

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel onClick={onClose}>
          <ImCancelCircle />
        </Cancel>
        {/* <MainIcon>
          <img src={CongratsImage} alt="" />
        </MainIcon> */}
        <ContentWrapper>
          <TextBold>Reset your password!</TextBold>
          <Text>
            A new password will be sent to you, for login, then you can change
            it in your account seetings.
            <br />
            <br />
            Enter your email address below.
            <br />
          </Text>

          <Formik
            initialValues={
              {
                email: "",
              } as forgotPasswordPayload
            }
            onSubmit={onSubmit}
            validationSchema={ResetPasswordValidation}
          >
            <Former>
              <InputField name="email" label="Email" placeholder="" />

              <SigninBtn disabled={isLoading}>
                {isLoading && <Spinner color={Colors.White} size={14} />}
                Reset password
              </SigninBtn>
            </Former>
          </Formik>
        </ContentWrapper>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default ForgotPassword;

const Wrapper = styled(motion.div)`
  width: 650px;
  max-height: 80vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 76px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 70vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
  font-size: 1.1rem;
`;

const SigninBtn = styled.button`
  padding: 0.8rem 0;
  text-align: center;
  background-color: ${Colors.Blue00};
  width: 100%;
  border-radius: 0.6rem;
  margin-top: 1rem;

  transition: 0.3s all ease;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    background: #022e58;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${Colors.GreyA7};
  }
`;

const Former = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 450px;
  margin: 0 auto;
`;

import React, { useState } from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import Colors from "../../../../styles/Colors";
import { pricingData } from "../../components/data";
import pattern from "../../../../images/web/pattern.png";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";

const PricingTab: React.FC = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<number>(1);

  const onClickTab = (tabIndex: number) => setIsActive(tabIndex);

  return (
    <Wrapper>
      <Constaint>
        <Tabs>
          <TabHeaderWrapper>
            <TabHeader isActive={isActive === 1} onClick={() => onClickTab(1)}>
              <p>citizenship visa</p>
            </TabHeader>
            <TabHeader isActive={isActive === 2} onClick={() => onClickTab(2)}>
              <p>student visa</p>
            </TabHeader>
            <TabHeader isActive={isActive === 3} onClick={() => onClickTab(3)}>
              <p>travel visa</p>
            </TabHeader>
          </TabHeaderWrapper>
          <TabContentsWrapper>
            {isActive === 1 && (
              <>
                <TabContent>
                  <TabPattern src={pattern} />
                  <TabContentDescription>
                    <h1>Citizenship N-400</h1>
                    <h6>(Application for Naturalization)</h6>
                    <p>
                      N-400 price package and what you will benefit when you
                      purchase this package:
                    </p>
                    <button
                      onClick={() =>
                        navigate(`${WebPaths.checkEligibility}/n400`)
                      }
                    >
                      check eligibility
                    </button>
                  </TabContentDescription>
                  {pricingData.N400Pricing.map((ev) => (
                    <TabPricing plan={ev.plan}>
                      <h4>{ev.package}</h4>
                      <h2>${ev.price}</h2>
                      <p className="font-bold">{ev.extras}</p>
                      <span className="text-[12px] font-bold text-[#f9f9f9]">
                        70% less than attorney fees
                      </span>
                      <hr className="my-6 bg-gray-300" />
                      {ev.offers.map((offer) => (
                        <div className="flex flex-row gap-2 mb-3">
                          <span>•</span> <p>{offer}</p>
                        </div>
                      ))}
                    </TabPricing>
                  ))}
                </TabContent>

                <TabContent bg="#F0FDFB">
                  <TabPattern src={pattern} />
                  <TabContentDescription>
                    <h1>Citizenship N-600</h1>
                    <h6>(Application for Certificate of Citizenship)</h6>
                    <p>
                      N-600 price package and what you will benefit when you
                      purchase this package:
                    </p>
                    <button
                      onClick={() =>
                        navigate(`${WebPaths.checkEligibility}/n600`)
                      }
                    >
                      check eligibility
                    </button>
                  </TabContentDescription>
                  {pricingData.N600Pricing.map((ev) => (
                    <TabPricing plan={ev.plan}>
                      <h4>{ev.package}</h4>
                      <h2>${ev.price}</h2>
                      <p className="font-bold">{ev.extras}</p>
                      <span className="text-[12px] font-bold text-[#f9f9f9]">
                        70% less than attorney fees
                      </span>
                      <hr className="my-6 bg-gray-300" />
                      {ev.offers.map((offer) => (
                        <div className="flex flex-row gap-2 mb-3">
                          <span>•</span> <p>{offer}</p>
                        </div>
                      ))}
                    </TabPricing>
                  ))}
                </TabContent>

                <TabContent bg="#F0FDF0">
                  <TabPattern src={pattern} />
                  <TabContentDescription>
                    <h1>Citizenship N-565</h1>
                    <h6>
                      (Application for replacement of citizenship document)
                    </h6>
                    <p>
                      N-565 price package and what you will benefit when you
                      purchase this package:
                    </p>
                    <button
                      onClick={() =>
                        navigate(`${WebPaths.checkEligibility}/n565`)
                      }
                    >
                      check eligibility
                    </button>
                  </TabContentDescription>
                  {pricingData.N565Pricing.map((ev, i) => (
                    <TabPricing plan={ev.plan} mxWidth="450">
                      <h4>{ev.package}</h4>
                      <h2>${ev.price}</h2>
                      <p className="font-bold">{ev.extras}</p>
                      <span className="text-[12px] font-bold text-[#f9f9f9]">
                        70% less than attorney fees
                      </span>
                      <hr className="my-6 bg-gray-300" />
                      {ev.offers.map((offer) => (
                        <div className="flex flex-row gap-2 mb-3">
                          <span>•</span> <p>{offer}</p>
                        </div>
                      ))}
                    </TabPricing>
                  ))}
                </TabContent>
              </>
            )}

            {isActive === 2 && (
              <TabContent>
                <TabPattern src={pattern} />
                <TabContentDescription>
                  <h1>Student Visa</h1>
                  <h6>(Navigate your academic dreams with ease.)</h6>
                  <p>What you will benefit from our Student Visa package.</p>
                  <button
                    onClick={() =>
                      navigate(`${WebPaths.checkEligibility}/ds160`)
                    }
                  >
                    check eligibility
                  </button>
                </TabContentDescription>
                {pricingData.StudentVisaPricing.map((ev) => (
                  <TabPricing plan={ev.plan} mxWidth="450">
                    <h4>{ev.package}</h4>
                    <h2>${ev.price}</h2>
                    <p className="font-bold">{ev.extras}</p>
                    <p className="font-bold">{ev.upgrade}</p>
                    <hr className="my-6 bg-gray-300" />
                    {ev.offers.map((offer) => (
                      <div className="flex flex-row gap-2 mb-3">
                        <span>•</span> <p>{offer}</p>
                      </div>
                    ))}
                  </TabPricing>
                ))}
              </TabContent>
            )}

            {isActive === 3 && (
              <TabContent>
                <TabPattern src={pattern} />
                <TabContentDescription>
                  <h1>Travel Visa</h1>
                  <h6>(B1/B2 Visa)</h6>
                  <p>What you will benefit from our Travel Visa package.</p>
                  <button
                    onClick={() =>
                      navigate(`${WebPaths.checkEligibility}/ds160`)
                    }
                  >
                    check eligibility
                  </button>
                </TabContentDescription>
                {pricingData.VisitorsVisaPricing.map((ev) => (
                  <TabPricing plan={ev.plan} mxWidth="450">
                    <h4>{ev.package}</h4>
                    <h2>${ev.price}</h2>
                    <p className="font-bold">{ev.extras}</p>
                    <hr className="my-6 bg-gray-300" />
                    {ev.offers.map((offer) => (
                      <div className="flex flex-row gap-2 mb-3">
                        <span>•</span> <p>{offer}</p>
                      </div>
                    ))}
                  </TabPricing>
                ))}
              </TabContent>
            )}
          </TabContentsWrapper>
        </Tabs>
      </Constaint>
    </Wrapper>
  );
};

export default PricingTab;

const Wrapper = styled.div``;

const Tabs = styled.div`
  margin-top: 4rem;
`;

const TabHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
`;

const TabHeader = styled.div<{ isActive: boolean }>`
  flex: 1;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;

  background-color: ${({ isActive }) =>
    isActive ? Colors.Blue00 : Colors.WhiteF3};
  color: ${({ isActive }) => isActive && Colors.White};

  cursor: pointer;
  transition: 300ms all ease;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? Colors.Blue02 : Colors.BlueEA};
  }

  &:nth-child(1) {
    border-top-left-radius: 10px;
  }

  &:nth-child(2) {
    border-left: 0.6px solid ${Colors.Black31};
    border-right: 0.6px solid ${Colors.Black31};
  }

  &:nth-child(3) {
    border-top-right-radius: 10px;
  }

  & > p {
    font-size: 0.72rem;
  }
`;

const TabContentsWrapper = styled.div`
  margin-bottom: 3rem;
`;

const TabContent = styled.div<{ bg?: string }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  flex-direction: column;

  background-color: ${({ bg }) => (bg ? bg : "#f0f7fd")};
  width: 100%;

  padding: 16px;
  margin-bottom: 1rem;
  border-radius: 8px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 48px;
  }
`;

const TabPattern = styled.img`
  position: absolute;
  top: 0;
  left: 5%;

  width: 30%;
  z-index: 0;

  pointer-events: none;
`;

const TabContentDescription = styled.div`
  flex: 0.4;
  z-index: 1;

  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }

  & > h1 {
    font-size: 2.25rem;
    font-weight: bold;
  }

  & > h6 {
    color: ${Colors.Blue00};
    margin: 1.25rem 0;
  }

  & > button {
    text-transform: uppercase;
    color: ${Colors.Blue00};
    border: 1px solid ${Colors.Blue00};
    border-radius: 8px;
    padding: 0.5rem 1.5rem;

    font-size: 0.85rem;
    font-weight: bold;

    transition: 300ms all ease;

    &:hover {
      background-color: ${Colors.Blue00};
      color: ${Colors.White};
    }

    margin-top: 2rem;
  }
`;

const TabPricing = styled.div<{
  plan: "standard" | "premium";
  mxWidth?: string;
}>`
  flex: 0.6;

  background: ${({ plan }) =>
    plan === "standard" ? Colors.Blue00 : Colors.Black31};

  color: ${Colors.White};

  width: 100%;
  border-radius: 8px;
  padding: 2.5rem 1.5rem;

  @media screen and (min-width: 768px) {
    max-width: ${({ mxWidth }) => (mxWidth ? `${mxWidth}px` : "350px")};
  }

  & > h4 {
    text-transform: uppercase;
    font-size: 1rem;
  }

  & > h2 {
    font-size: 2.25rem;
    font-weight: bold;
  }
`;

import * as Yup from "yup";

export const LoginValidation = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  // agreement: Yup.bool().oneOf([true]),
});

export const SignUpValidation = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .test("phoneNumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password.")
    .oneOf([Yup.ref("password")], "Your passwords do not match."),
  agreement: Yup.bool().oneOf([true]),
});

export const ResetPasswordValidation = Yup.string().required("Required");

import React, { useState, useRef, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import { FaCircleXmark, FaPaperPlane } from "react-icons/fa6";
import AnimatedButton from "./AiChatButton";
import { VscSparkleFilled } from "react-icons/vsc";
import { saveChat } from "../../../../api/ai";
import AiDisclaimer from "../../components/AiDisclaimer";
import { userDataStore } from "../../../../store/userData";
import { useSnapshot } from "valtio";
import { CustomMDLink } from "./data";

enum MessageSender {
  user = "user",
  bot = "bot",
}

interface Message {
  // id: number;
  text: string;
  sender: MessageSender;
}

interface Props {
  formName?: string;
}

const ChatSystem: React.FC<Props> = ({ formName }) => {
  const { userData } = useSnapshot(userDataStore);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  // const [chatSession, setChatSession] = useState<Message[]>([]);
  const [sessionId, setSessionId] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const result = await saveChat({
          id: "",
          payload: {
            prompt: "Hello",
          },
        });

        const aiResponse: Message = {
          sender: MessageSender.bot,
          text: result.data.result,
        };
        setSessionId(result.data.id);
        setMessages((prev) => [...prev, aiResponse]);
      } catch (error) {
        const errorMessage: Message = {
          sender: MessageSender.bot,
          text: "We're experiencing a temporary hiccup. Feel free to retry your request. If you still encounter problems, our support team at connect@seamlessvisa.com is here for you.",
        };
        setMessages((prev) => [...prev, errorMessage]);
        console.error("Error generating response:", error);
      } finally {
        setLoading(false);
        setInput("");
      }
    };
    fetch();
  }, []);

  const sendMessage = async (e: React.FormEvent, passedText?: string) => {
    e.preventDefault();
    // if (!userData) return;
    // if (!chatSession) return;
    const tempText = passedText?.trim() || input.trim();
    if (tempText) {
      const newMessage: Message = {
        // id: Date.now(),
        text: tempText.trim(),
        sender: MessageSender.user,
      };
      setMessages([...messages, newMessage]);
      setInput("");

      try {
        const result = await saveChat({
          // id: sessionId,
          // id: userData?.userInfo.userId,
          id: userData ? userData?.userInfo.userId : sessionId,
          payload: {
            prompt: tempText.trim(),
          },
        });
        const aiResponse: Message = {
          // id: Date.now(),
          sender: MessageSender.bot,
          text: result.data.result,
        };
        setMessages((prev) => [...prev, aiResponse]);
      } catch (error) {
        const errorMessage = {
          id: Date.now(),
          sender: MessageSender.bot,
          text: "We're experiencing a temporary hiccup. Feel free to retry your request. If you still encounter problems, our support team at connect@seamlessvisa.com is here for you.",
        };
        setMessages((prev) => [...prev, errorMessage]);
        console.error("Error generating response:", error);
      } finally {
        setLoading(false);
        setInput("");
      }
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="fixed bottom-4 right-4 w-[500px] max-w-[93%] duration-300">
      <div
        className={`text-white  duration-300 p-2 rounded-t-3xl flex items-center gap-x-2 ${
          isOpen
            ? "bg-[#0276E9] justify-between"
            : "w-[max-content] absolute right-0 bottom-0 px-4 rounded-lg text-xl font-bold bg-none cursor-pointer"
        }`}
        onClick={() => !isOpen && setIsOpen(true)}
      >
        {isOpen ? (
          <>
            <AnimatedButton
              icon={<VscSparkleFilled size={32} />}
              text="Ask FiBi!"
              isOpen={isOpen}
            />

            <div
              onClick={() => isOpen && setIsOpen(false)}
              className="p-2 duration-150 rounded-full cursor-pointer hover:text-gray-300"
            >
              <FaCircleXmark size={24} />
            </div>
          </>
        ) : (
          <AnimatedButton
            icon={<VscSparkleFilled size={32} />}
            text="Ask FiBi!"
            isOpen={isOpen}
          />
        )}
      </div>
      {isOpen && (
        <div className="bg-white border border-[#0076e9] rounded-b-xl shadow-lg h-[90%]">
          <div className="px-8 py-4">
            <div className="px-8 text-sm text-center text-primaryColor">
              Hi, I’m FiBi, your virtual immigration assistant.
            </div>
            <p className="text-sm text-center text-black opacity-80">
              I'm here to help you with questions regarding immigration. What
              would you like to ask me?
            </p>
          </div>
          <div className="flex flex-col p-4 overflow-y-auto h-[40vh]">
            {formName && (
              <div className="grid grid-cols-2 gap-4 mb-6">
                {[
                  `What is ${formName} used for?`,
                  `Who is eligible to file ${formName}?`,
                  `What documents are required when filing ${formName}?`,
                  `How much does ${formName} cost on SeamlessVisa?`,
                  `How long does it take to process ${formName}?`,
                  `What happens if my application is denied?`,
                ].map((ev, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center p-1 text-center text-gray-700 transition-all border border-gray-400 rounded-md shadow-sm cursor-pointer hover:border-primaryColor min-h-[100px]"
                    onClick={(e) => {
                      sendMessage(e, ev);
                    }}
                  >
                    {ev}
                  </div>
                ))}
              </div>
            )}
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 flex ${
                  message.sender === "user" ? "justify-end" : ""
                }`}
              >
                <span
                  className={`inline-block p-2 rounded-3xl  ${
                    message.sender === "user"
                      ? "inline-block max-w-[70%] px-4 py-3 rounded-tr-[4px] bg-[#0276E9] text-white"
                      : "bg-white shadow px-4 py-3 border border-gray-300 text-gray-600 rounded-tl-[4px] mb-4 max-w-[85%]"
                  }`}
                >
                  <Markdown
                    options={{
                      overrides: {
                        a: CustomMDLink,
                      },
                    }}
                  >
                    {message.text}
                  </Markdown>
                </span>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={sendMessage} className="p-4 border-t border-gray-200">
            <div className="flex gap-x-2">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-grow px-3 py-2 border-2 border-gray-300 rounded-lg outline-none focus:outline-none focus:ring-0 focus:border-blue-500 placeholder:text-gray-400"
                disabled={loading}
                placeholder="Type a message..."
              />
              <button
                type="submit"
                disabled={loading || input.trim() === ""}
                className={`bg-blue-500 text-white px-4 py-4  rounded-full hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200 disabled:text-gray-50 box-border`}
              >
                <FaPaperPlane />
              </button>
            </div>
          </form>
          <div className="px-6 ">
            <AiDisclaimer />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatSystem;

import React from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import AboutBg from "../../../../images/web/sv-logo-icon.png";
import { motion } from "framer-motion";
import { AboutTextVariant, TitleVariant } from "./variants";
import { WebPaths } from "../../../../routes/data";
import { useNavigate } from "react-router-dom";
import Colors from "../../../../styles/Colors";

const About = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Label>ABOUT US</Label>
          <H1
            variants={TitleVariant}
            whileInView={"animate"}
            initial={"initial"}
            viewport={{ once: true }}
          >
            The Story Behind SeamlessVisa
          </H1>
          <Section>
            <LeftSection>
              <LeftText>About Us</LeftText>
              <LeftPara>
                At SeamlessVisa, we understand that your immigration story is
                not just paperwork. In every immigration journey and the pursuit
                of a better future, we understand that there are emotions
                attached to every decision, every document, and every detail.
              </LeftPara>
              <LeftPara>
                SeamlessVisa takes a personalized approach, not just because
                it's a strategy, but because it's a heartfelt commitment to
                making your application process more than a transaction. We are
                committed to empowering individuals and families worldwide by
                simplifying and streamlining the U.S. visa application process.
                We provide a user-friendly platform with step-by-step guidance,
                that helps individuals navigate immigration challenges with a
                simplified and accessible visa application process. Our
                self-directed online immigration platform covers various
                applications, from citizenship to student, and tourist visas.
              </LeftPara>
              <LeftText>Our Mission</LeftText>
              <LeftPara>
                Empowering individuals and families to achieve their immigration
                goals through efficient, affordable, and top-tier services.
              </LeftPara>
              <LeftBtnWrapper>
                <LearnBtn onClick={() => navigate(WebPaths.About)}>
                  Learn More
                </LearnBtn>
                {/* <ConnectBtn>Connect with Attorney</ConnectBtn> */}
              </LeftBtnWrapper>
            </LeftSection>
            <RightSection
              initial="initial"
              whileHover="animate"
              animate="initial"
            >
              <RightImage src={AboutBg} />
              <OverlayText variants={AboutTextVariant}>
                We value honesty, integrity, and transparency in all aspects of
                our work.{" "}
              </OverlayText>
            </RightSection>
          </Section>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default About;

const Wrapper = styled.div`
  background-color: #f9fcff;
`;

const Container = styled.div`
  padding: 100px 0;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #31393c;
  text-align: center;
`;

const H1 = styled(motion.h1)`
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  text-align: center;
  text-transform: capitalize;
  color: #31393c;
  margin-top: 16px;
  margin-bottom: 70px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column-reverse;

  align-items: center;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    gap: 112px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 50%;
  }
`;

const LeftText = styled.h6`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: #14180d;

  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

const LeftPara = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #31393c;

  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

const LeftBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const LeftBtn = styled.button`
  width: 243px;
  height: 56px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;

  @media screen and (min-width: 768px) {
    margin: 0;
  }

  &:hover {
    background: #022e58;
    transition: 0.3s ease-in-out;
  }
`;

const LearnBtn = styled(LeftBtn)`
  background-color: #0076e9;
  color: #f9fcff;
`;

// const ConnectBtn = styled(LeftBtn)`
//   background-color: #435d76;
//   color: #f9fcff;
// `;

const RightSection = styled(motion.div)`
  position: relative;

  margin-bottom: 2rem;

  box-shadow: 4px 4px 12.4px 10px ${Colors.BlueEA};
  aspect-ratio: 1/1;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: hidden;

  @media only screen and (min-width: 768px) {
    width: 50%;
    margin-bottom: 0;

    &:hover {
      img {
        filter: brightness(70%);
        transition: 0.3s ease-out;
        transform: translateY(-20%) scale(0.9);
      }
    }
  }

  & > img {
    height: 300px;
    width: 300px;
    object-fit: contain;

    padding: 2rem;
    margin: auto;
    transition: 0.5s ease-out;

    pointer-events: none;

    @media only screen and (min-width: 768px) {
      height: 450px;
      width: 450px;

      &:hover {
        img {
          filter: brightness(70%);
          transition: 0.5s ease-out;
          transform: translateY(-20%) scale(0.9);
        }
      }
    }
  }
`;

const OverlayText = styled(motion.div)`
  width: 75%;

  font-weight: 700;
  font-size: 0.85rem;
  line-height: 2.6875rem;
  text-align: center;
  color: #000;

  display: none;

  @media only screen and (min-width: 768px) {
    font-size: 2rem;
    position: absolute;
    bottom: 73px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
`;

const RightImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

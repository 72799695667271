import React, { FC, useState } from "react";
import styled from "styled-components";
import { NavItemsProps } from "./data";
import { useNavigate } from "react-router-dom";
import { userDataStore } from "../../../../store/userData";
import { useSnapshot } from "valtio";
import useOnContinueNav from "../../../../hooks/useOnContinueNav";
import { WebPaths } from "../../../../routes/data";
import useBasedOnFormPaid from "../../../../hooks/useBasedOnFormPaid";

interface Props {
  data: NavItemsProps["children"];
  onClose: () => void;
}

const ExtraNavItem: FC<Props> = ({ data, onClose }) => {
  const navigate = useNavigate();
  const [no, setNo] = useState(0);
  const { userData } = useSnapshot(userDataStore);
  const { onContinueNav } = useOnContinueNav();
  const { getBasedOnFormPaid } = useBasedOnFormPaid();

  return (
    <Container className="child-nav">
      <Wrapper>
        <MainFlexer>
          {data?.map((ev, i) => (
            <MainBox key={i} onMouseEnter={() => setNo(i)} isActive={i === no}>
              <MainBoxText>{ev.name}</MainBoxText>
              <MainBoxSubText>{ev.subText}</MainBoxSubText>
            </MainBox>
          ))}
        </MainFlexer>
        <RightSection>
          <HR />
          <ChildrenFlexer>
            {data &&
              data[no].subChildren.map((ev, i) => (
                <ChildrenBox
                  key={i}
                  onClick={(e) => {
                    e.stopPropagation();
                    const path = ev.url.split("/");
                    const formCategory = userData?.currentFormInfo.formCategory;
                    const hasLastProgress =
                      path.length > 2 ? path.pop() === formCategory : false;

                    // if (!ev.formType) return;
                    if (ev.formType && getBasedOnFormPaid(ev.formType)) {
                      navigate(
                        `${WebPaths.FormCategory}/${ev.formType}?type=${ev.formType}-review`
                      );
                    } else if (hasLastProgress) {
                      onContinueNav();
                    } else {
                      navigate(ev.url);
                    }
                    onClose();
                  }}
                >
                  <PolyGon /> {ev.label}
                </ChildrenBox>
              ))}
          </ChildrenFlexer>
        </RightSection>
      </Wrapper>
    </Container>
  );
};

export default ExtraNavItem;

const Container = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  width: 96%;

  @media only screen and (min-width: 768px) {
    left: -40px;
    top: 100%;

    width: unset;
  }
`;

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 12px;
  margin-top: 10px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const MainFlexer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media only screen and (min-width: 768px) {
    padding-right: 18px;
  }
`;

interface MainBoxProps {
  isActive: boolean;
}

const MainBox = styled.div<MainBoxProps>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 12px;
  width: 100%;

  cursor: pointer;
  border-radius: 8px;
  background-color: ${({ isActive }) => isActive && "#e0e2e3"};

  @media only screen and (min-width: 768px) {
    width: 177px;
  }

  &:hover {
    background-color: #e0e2e3;
    transition: 0.3s ease-in-out;
  }
`;

const MainBoxText = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  color: #000000;
`;

const MainBoxSubText = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  letter-spacing: 0em;
  color: #7e8388;
`;

const RightSection = styled.div`
  background-color: #f9fcff;
  padding: 34px 21px;
  width: 407px;
`;

const HR = styled.div`
  height: 0.5px;
  background-color: #b3bac1;
  width: 90%;
  margin-bottom: 18px;
`;

const ChildrenFlexer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ChildrenBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  color: #6b6d70;
  cursor: pointer;

  &:hover {
    color: #000000;
    transition: 0.2s ease-out;
  }
`;

const PolyGon = styled.div`
  width: 12px;
  height: 12px;
  background: #0076e9;
  transform: rotate(45deg);
`;
